import React from "react";
import { BrowserRouter as Router, Route, withRouter, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import LayAdmin from "../layout/index";
import Anniversary from '../pages/anniversary/index'
import KnowledgeAction from '../pages/knowledgeAction/index'
import zhCN from "antd/es/locale/zh_CN";
import "../static/css/pages/index.css";
function AppRouter() {
  return (
    <Router forceRefresh={true}>
      <ConfigProvider locale={zhCN}>
        <Switch>
          <Route path="/anniversary" component={Anniversary} />
          <Route path="/knowledge" component={KnowledgeAction} />
          <Route path="/" component={LayAdmin} />
        </Switch>
      </ConfigProvider>
    </Router>
  );
}

export default AppRouter;
