import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useFifteen() {
  const [list, setList] = useState([]);

  const getList = async () => {
    let params = {};
    let ret = await reqestApi("/api/index/action_project", "GET", params);
    if (ret.data.code == 0) {
      setList(ret.data.data);
    }
  };

  return {
    list,
    getList,
  };
}

export default createModel(useFifteen);
