export const Env = {
  isTest: window.location.host === "test.jkzgxd.cn",
  isProd: window.location.host === "www.jkzgxd.cn",
};

export const GetApiHost = () => {
  let href = window.location.href;
  if (href.indexOf("localhost") !== -1) {
    // return "https://api.jkzgxd.cn";
    return "";
  }
  if (Env.isTest){
    return "https://api-test.jkzgxd.cn";
  } else {
    return "https://api.jkzgxd.cn";
  }
};

export const isDebug = () => {
  let href = window.location.href;
  if (href.indexOf("localhost") != -1) {
    return 1;
  }
  return 0;
};
