import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../untils/requestHelper";
import {message} from "antd";

function useLocal() {
    const [internetList, setInternetList] = useState(() => {
        return []
    });
    const [searchData, setSearchData] = useState(() => {
        let list = [
            {
                title: "地区",
                name: 0,
                value: 0,
                sublist: [],
            },
            {
                title: "医院",
                name: 1,
                value: 0,
                sublist: [
                    {label: "全部医院", value: 0},
                    {label: "互联网医院", value: 1}
                ]
            },
        ];
        return list;
    });
    const [hopitalDetail, setHospitalDeatil] = useState(() => {
        return {
            hospital_name: '',
            hospital_info: '',
            hospital_addr: "",
            capability: ""
        }
    })
    const [hospitalList, setHospitalList] = useState([]);

    const hospital_rank = {
        1: '其他',
        2: '三甲医院',
        3: '三级医院',
        4: '二级医院'
    }
    const getInternetList = async (params) => {
        let res = await reqestApi("/api/nethosp/list", "GET", params);
        if (res.data.code === 0) {
            res.data.data.map((item) => {
                item.hospital_rank = hospital_rank[item.hospital_rank]
                item.departments = item.departments.join("、")
            })
            setInternetList(res.data.data);
        } else {
            setInternetList([]);
        }
    };

    const getHospitalDetail = async (id) => {
        let res = await reqestApi(`/api/nethosp/detail/${id}`, "GET");
        if (res.data.code == 0) {
            setHospitalDeatil(res.data.data)
        }
    };

    const sendMessage = async (param) => {
        const res = await reqestApi("/api/sendsms", "POST", param);
        if(res.data && res.data.code === 0){
            message.success("短信发送成功");
            return true;
        }else if(res.data && res.data.code === 20012){
            message.error("不要频繁发送");
            return false;
        }else {
            message.error("短信发送失败");
            return false;
        }
    }

    const commit = async (param) => {
        const res = await reqestApi("/api/check_code", "POST", param);
        return !!(res.data && res.data.code === 0);
    }

    const getList = async (param) => {
        const res = await reqestApi("/api/nethosp/commit_history", "POST", param);
        if(res.data && res.data.code === 0){
            const dataList = res.data.data.filter(item => {
                return item.check_status !== 5
            })
            dataList.forEach(item => {
                item.key = item.id
            })
            setHospitalList([...dataList]);
            return  dataList;
        } else {
            message.error("获取列表失败！");
            return false;
        }
    }

    const getInfo = async (id) => {
        const res = await reqestApi(`/api/nethosp/detail/${id}`, "GET");
        if(res.data && res.data.code === 0){
            // debugger
            return res.data.data
        } else {
            message.error("获取记录信息失败")
        }
    }

    return {
        searchData,
        internetList,
        setSearchData,
        setInternetList,
        getInternetList,
        hopitalDetail,
        setHospitalDeatil,
        getHospitalDetail,
        sendMessage,
        commit,
        getList,
        hospitalList,
        getInfo,
    };
}

export default createModel(useLocal);
