import React, {useState, useEffect, useRef} from "react";
import {
    Form,
    Input,
    Select,
    Button,
    Space,
    Upload,
    Breadcrumb,
    message,
    Drawer,
    DatePicker,
    Tag,
    Modal, Radio,
    Checkbox,
    Tooltip,
} from "antd";
import {PlusCircleFilled, PlusCircleOutlined,InfoCircleOutlined} from '@ant-design/icons';

import "./index.css"
import {getProvinces, findCityName} from "../../../../untils/citydata"
import {getToken} from "../../../../untils/tokenHelper"
import {GetUploadUrl} from "../../../../untils/requestHelper"
import modal from "../../../../models/infoStep"

const {Option} = Select
const {TextArea} = Input
const {Search} = Input

function StepForm(props) {
    const infoForm = useRef();
    const peopleForm = useRef();

    const infoData = ["hospital_name", "hospital_rank", "province", "qualification", "core_subject_num", "departments", "hospital_info"];
    const peopleData = ["commit_name", "phone_num", "company_name", "position"];

    const [detail] = useState(props.info)
    const [hospitalNum, setHospitalNum] = useState(detail.hospital_addr.split(',').length < 2 ? 1 : 2);
    const [hospitalData, changeHospitalData] = useState(() => {
        if(hospitalNum === 1){
            return [{id: 1, content: ""}, {id: 2, content: ""}]
        }else{
            const res = detail.hospital_addr.split(',').map((item, index) => {
                return {
                    id: index+1,
                    content: item,
                }
            })
            return res
        }
    })
    const [logoUrl, setLogoUrl] = useState();
    const [logoList, setLogoList] = useState(() => [])
    const [qrList, setQrList] = useState(() => []);
    const [qrUrl, setQrUrl] = useState();
    const [capability, setCapability] = useState(() => {
        if(detail.capability){
            return {
                cape1: detail.capability.split(',')[0],
                cape2: detail.capability.split(',')[1] ? detail.capability.split(',')[1] : "",
                cape3: detail.capability.split(',')[2] ? detail.capability.split(',')[2] : "",
            }
        } else{
            return {
                cape1: "",
                cape2: "",
                cape3: "",
            }
        }
    })
    const [checkRes, setCheckRes] = useState("");
    const [isChecked, setIsChecked] = useState(true);

    const {
       save,
    } = modal()

    const formItemLayout = {
        labelCol: {span: 7},
        wrapperCol: {span: 17},
    };

    const domEvents = {
        commit: async () => {
            const params = getParams();
            if(!params){
                return
            }
            const id = await save(params, detail.id);
            if(id){
                props.setInfo({
                    ...params,
                    rank_text: getHospitalRank(params.hospital_rank),
                    qualification_text: getQualification(params.qualification),
                    province_text: findCityName(params.province),
                    id
                })
                props.setCurrent(1);
            }
        }
    }

    function getHospitalRank(val) {
        switch (val) {
            case 2:
                return "三甲医院";
            case 3:
                return "三级医院";
            case 4:
                return "二甲医院";
            default:
                return "其他医院";
        }
    }

    function getQualification(val) {
        switch (val) {
            case 1:
                return "互联网医院";
            case 2:
                return "互联网诊疗";
            default:
                return "无";
        }
    }

    function getParams() {
        if(!isChecked) {
            message.warn("国家临床重点专科数必须为整数");
            return;
        }
        const resParams = {};
        const infoRes = infoForm.current.getFieldsValue(infoData);
        for (let key in infoRes) {
            if (infoRes[key] === "") {
                message.warn("表单不能留空");
                return;
            } else {
                resParams[key] = infoRes[key];
            }
        }
        const peopleRes = peopleForm.current.getFieldsValue(peopleData);
        for (let key in peopleRes) {
            if (!peopleRes[key]) {
                message.warn("表单不能留空");
                return;
            } else {
                resParams[key] = peopleRes[key];
            }
        }
        if(logoUrl){
            resParams.logo = logoUrl;
        } else {
            message.warn("表单不能留空");
            return;
        }
        if(qrUrl){
            resParams.qrcode_img = qrUrl;
        } else {
            message.warn("表单不能留空");
            return;
        }
        const capabilityList = [];
        if(capability.cape1){
            capabilityList.push(capability.cape1)
        }
        if(capability.cape2){
            capabilityList.push(capability.cape2)
        }
        if(capability.cape3){
            capabilityList.push(capability.cape3)
        }
        if(capabilityList.length){
            resParams.capability = capabilityList.join(",");
        }else{
            message.warn("表单不能留空");
            return;
        }
        if(hospitalNum === 1){
            //单院区
            const address = infoForm.current.getFieldValue("hospital_addr")
            if(address){
                resParams.hospital_addr = address;
            }else{
                message.warn("表单不能留空");
                return;
            }
        }else{
            //多院区
            const address = [];
            hospitalData.forEach(item => {
                if(item.content){
                    address.push(item.content)
                }
            })
            if(address.length){
                resParams.hospital_addr = address.join(",");
            }else{
                message.warn("表单不能留空");
                return;
            }
        }
        return resParams;
    }

    useEffect(() => {
        if(detail.logo) {
            setLogoUrl(detail.logo);
            setLogoList([
                {
                    uid: "-1",
                    name: "image",
                    status: "done",
                    url: detail.logo,
                },
            ]);
        }
        if(detail.qrcode_img){
            setQrUrl(detail.qrcode_img);
            setQrList([
                {
                    uid: "-1",
                    name: "image",
                    status: "done",
                    url: detail.qrcode_img,
                },
            ]);
        }
    }, [])

    return (
        <div className="step-form-container">
            <p className="description" style={{marginBottom: 10}}>*以下信息均为必填信息，请按要求填写，否则可能导致申请失败！</p>
            <p className="description">*填写完成后请下载《申请函》取得医院盖章 授权后，将扫描件上传方能提交。</p>
            <div className="hospital-check">
                <div style={{marginBottom: 16, position: "relative"}}>
                    <div className="title">
                        申请医院信息
                    </div>
                    <Form
                        ref={infoForm}
                        {...formItemLayout}
                        onFinish={""}
                    >
                        <Tooltip placement="bottomLeft" title={<div style={{
                            maxWidth: 180
                        }}>取得互联网医院牌照资质的为互联网医院</div>}>
                            <InfoCircleOutlined className="warn-icon" style={{
                                top: 228,
                                left: 40
                            }}/>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title={<div>
                            <div>示例：</div>
                            <div>1.在线问诊</div>
                            <div>2.预约挂号</div>
                            <div>3.复诊续方·送药到家</div>
                        </div>}>
                            <InfoCircleOutlined className="warn-icon"  style={{
                                top: 403,
                                left: 138
                            }}/>
                        </Tooltip>
                        <Form.Item
                            label="医院名称"
                            name="hospital_name"
                            initialValue={detail.hospital_name}
                            rules={[
                                {
                                    type: "string",
                                },
                            ]}
                        >
                            <Input
                                placeholder="请输入医院名称"
                            />
                        </Form.Item>
                        <Form.Item
                            name="hospital_rank"
                            label="医院等级"
                            initialValue={detail.hospital_rank}
                        >
                            <Select
                                placeholder="请选择医院等级"
                            >
                                <Option value={2}>
                                    三甲医院
                                </Option>
                                <Option value={3}>
                                    三级医院
                                </Option>
                                <Option value={4}>
                                    二甲医院
                                </Option>
                                <Option value={1}>
                                    其他医院
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="province"
                            label="所在地区"
                            initialValue={detail.province}
                        >
                            <Select
                                placeholder="请选择所在地区"
                            >
                                {getProvinces().map((litem) => {
                                    return (
                                        <Option value={litem.value} key={litem.value}>
                                            {litem.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="qualification"
                            label="资质"
                            initialValue={detail.qualification}
                        >
                            <Select
                                placeholder="请选择医院资质"
                            >
                                <Option value={1}>
                                    互联网医院
                                </Option>
                                <Option value={2}>
                                    互联网诊疗
                                </Option>
                                <Option value={0}>
                                    无
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="core_subject_num"
                            label="国家临床重点专科数"
                            initialValue={detail.core_subject_num}
                        >
                            <Input onBlur={(e) => {
                                if(!Number(e.target.value.trim()) && Number(e.target.value.trim()) !== 0){
                                    setIsChecked(false);
                                    message.warn("国家临床重点专科数必须为整数");
                                }else{
                                    setIsChecked(true);
                                }
                            }} placeholder="请输入国家临床重点专科数"/>
                        </Form.Item>
                        <Form.Item
                            name="departments"
                            label="特色科室"
                            initialValue={detail.departments}
                        >
                            <Input placeholder="多个用逗号隔开，超过5个可能会显示不全"/>
                        </Form.Item>
                        <Form.Item
                            label="互联网医院特色标签"
                        >
                            <div className="label-box">
                                <Input maxLength={10} value={capability.cape1} onChange={(e) => {
                                    setCapability({
                                        ...capability,
                                        cape1: e.target.value
                                    })
                                }} className="label-input" placeholder="简介1（10字以内）"/>
                                <Input maxLength={10}  value={capability.cape2} onChange={(e) => {
                                    setCapability({
                                        ...capability,
                                        cape2: e.target.value
                                    })
                                }} className="label-input" placeholder="简介2（10字以内）"/>
                                <Input maxLength={10}   value={capability.cape3} onChange={(e) => {
                                    setCapability({
                                        ...capability,
                                        cape3: e.target.value
                                    })
                                }} className="label-input" placeholder="简介3（10字以内）"/>
                            </div>
                        </Form.Item>
                        <Form.Item
                            name="hospital_info"
                            label="医院简介"
                            initialValue={detail.hospital_info}
                        >
                            <TextArea  rows={4} placeholder="请输入医院简介，1000字以内" maxLength={1000}/>
                        </Form.Item>
                        <Form.Item
                            label="院区数量"
                        >
                            <Radio.Group value={hospitalNum} onChange={(e) => {
                                setHospitalNum(e.target.value)
                            }}>
                                <Radio value={1}>单院区</Radio>
                                <Radio value={2}>多院区</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {hospitalNum === 1 ? <Form.Item
                                name="hospital_addr"
                                label="医院地址"
                                initialValue={detail.hospital_addr.split(",").length > 1 ? "" : detail.hospital_addr}
                            >
                                <Input placeholder="请输入医院地址"/>
                            </Form.Item> :
                            <div>
                                {
                                    hospitalData.map((item, index) => {
                                        return (item ? <Form.Item
                                            name={index}
                                            key={item.id}
                                            label={`院区${hospitalData.filter(item => item !== "").map(item => item.id).indexOf(item.id) + 1}名称`}
                                            initialValue={item.content}
                                        >
                                            <Search value={item.content} onChange={(e) => {
                                                const newList = [...hospitalData];
                                                newList.forEach((newItem, newIndex) => {
                                                    if (newIndex === index) {
                                                        newItem.content = e.target.value;
                                                    }
                                                })
                                                changeHospitalData(newList)
                                            }} enterButton="删除" placeholder="请输入院区名称" onSearch={() => {
                                                const newList = [...hospitalData];
                                                newList.splice(index, 1, "");
                                                if (newList.filter(item => item !== "").length < 2) {
                                                    message.error("多院区院区数量不得少于2")
                                                } else {
                                                    changeHospitalData(newList);
                                                }
                                            }}/>
                                        </Form.Item> : <div></div>)
                                    })
                                }
                                <div className="add-more-btn" onClick={() => {
                                    const newList = [...hospitalData];
                                    newList.push({
                                        id: newList.length + 1
                                    })
                                    changeHospitalData(newList)
                                }}>
                                    <PlusCircleFilled style={{marginRight: 6}}/>
                                    添加新院区
                                </div>
                            </div>
                        }
                        <Form.Item
                            label="医院logo"
                        >
                            <div style={{position: "relative"}}>

                            </div>
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                fileList={logoList}
                                headers={{token: getToken()}}
                                action={GetUploadUrl()}
                                onChange={(e) => {
                                    const { fileList } = e
                                    if (e.file.status === 'done') {
                                        try {
                                            let ret = e.fileList[0].response;
                                            if (ret.code === 0) {
                                                setLogoList(e.fileList)
                                                setLogoUrl(ret.data);
                                            } else {
                                                message.error("图片上传失败");
                                            }
                                        } catch (ex) {
                                            // message.error("图片上传失败")
                                        }
                                    } else {
                                        setLogoList([...fileList]); // 该组件需要依赖外部状态进行触发onChange
                                    }
                                }}
                                onRemove={(e) => {
                                    setLogoUrl("");
                                    setLogoList([])
                                }}
                            >
                                {logoList.length === 0 && <div>
                                    <PlusCircleOutlined/>
                                    <div className="upload-text">上传</div>
                                </div>}
                            </Upload>
                            <span className="upload-warn">
                                <div>图片文件大小不超过1Mb，长宽比为1:1</div>
                                <div>长宽尺寸不低于300px</div>
                            </span>
                        </Form.Item>
                        <Form.Item
                            label="互联网医院二维码"
                        >
                            <div style={{position: "relative"}}>
                                <Upload
                                    name="file"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={qrList}
                                    headers={{token: getToken()}}
                                    action={GetUploadUrl()}
                                    onChange={(e) => {
                                        const { fileList } = e
                                        if (e.file.status === 'done') {
                                            try {
                                                let ret = e.fileList[0].response;
                                                if (ret.code === 0) {
                                                    setQrList(e.fileList)
                                                    setQrUrl(ret.data);
                                                } else {
                                                    message.error("图片上传失败");
                                                }
                                            } catch (ex) {
                                                // message.error("图片上传失败")
                                            }
                                        } else {
                                            setQrList([...fileList]); // 该组件需要依赖外部状态进行触发onChange
                                        }
                                    }}
                                    onRemove={(e) => {
                                        setQrUrl("");
                                        setQrList([])
                                    }}
                                >
                                    {qrList.length === 0 && <div>
                                        <PlusCircleOutlined/>
                                        <div className="upload-text">上传</div>
                                    </div>}
                                </Upload>
                                <span className="upload-warn">
                                    <div>图片文件大小不超过1Mb，长宽比为1:1</div>
                                    <div>长宽尺寸不低于300px</div>
                                </span>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div>
                    <div className="title">申请人信息</div>
                    <Form
                        ref={peopleForm}
                        {...formItemLayout}
                        onFinish={""}
                    >
                        <Form.Item
                            label="真实姓名"
                            name="commit_name"
                            initialValue={detail.commit_name}
                        >
                            <Input
                                placeholder="请输入真实姓名"
                                // disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                            />
                        </Form.Item>
                        <Form.Item
                            label="单位名称"
                            name="company_name"
                            initialValue={detail.company_name}
                        >
                            <Input
                                placeholder="请输入单位名称"
                            />
                        </Form.Item>
                        <Form.Item
                            label="职务"
                            name="position"
                            initialValue={detail.position}
                        >
                            <Input
                                placeholder="请输入职务名称"
                            />
                        </Form.Item>
                        <Form.Item
                            label="手机号"
                            name="phone_num"
                            initialValue={detail.phone_num}
                        >
                            <Input
                                disabled
                                style={{
                                    color: "#DFDFDF"
                                }}
                                placeholder="请输入手机号"
                            />
                        </Form.Item>
                    </Form>
                </div>
                <div className="btn-box">
                    <Button type="primary" onClick={() => {domEvents.commit()}}>下一步</Button>
                </div>
            </div>
        </div>
    )
}

export default StepForm