import React, { useEffect, useState } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Space, Row, Col } from "antd";
import FifteenModel from "../../models/action/fifteen";
import { useCookies } from "react-cookie";
import "../../static/css/pages/actions/fifteen.css";
import "../../static/css/pages/comm.css";
const majorList = [
  {},
  {
    title: "健康知识普及行动",
    icon: require("../../static/images/acb/ac-01.png"),
    uid: "684279",
    actionTxt: "重大行动一：健康知识普及行动",
  },
  {
    title: "合理膳食行动",
    icon: require("../../static/images/acb/ac-02.png"),
    uid: "684280",
    actionTxt: "重大行动二：合理膳食行动",
  },
  {
    title: "全民健身行动",
    icon: require("../../static/images/acb/ac-03.png"),
    uid: "684281",
    actionTxt: "重大行动三：全民健身行动",
  },
  {
    title: "控烟行动",
    icon: require("../../static/images/acb/ac-04.png"),
    uid: "684282",
    actionTxt: "重大行动四：控烟行动",
  },
  {
    title: "心理健康促进行动",
    icon: require("../../static/images/acb/ac-05.png"),
    uid: "684283",
    actionTxt: "重大行动五：心理健康促进行动",
  },
  {
    title: "健康环境促进行动",
    icon: require("../../static/images/acb/ac-06.png"),
    uid: "684284",
    actionTxt: "重大行动六：健康环境促进行动",
  },

  {
    title: "妇幼健康促进行动",
    icon: require("../../static/images/acb/ac-07.png"),
    uid: "684285",
    actionTxt: "重大行动七：妇幼健康促进行动",
  },
  {
    title: "中小学健康促进行动",
    icon: require("../../static/images/acb/ac-08.png"),
    uid: "684286",
    actionTxt: "重大行动八：中小学健康促进行动",
  },
  {
    title: "职业健康保护行动",
    icon: require("../../static/images/acb/ac-09.jpg"),
    uid: "684287",
    actionTxt: "重大行动九：职业健康保护行动",
  },
  {
    title: "老年健康促进行动",
    icon: require("../../static/images/acb/ac-10.jpg"),
    uid: "684288",
    actionTxt: "重大行动十：老年健康促进行动",
  },
  {
    title: "心脑血管疾病防治行动",
    icon: require("../../static/images/acb/ac-11.jpg"),
    uid: "684289",
    actionTxt: "重大行动十一：心脑血管疾病防治行动",
  },
  {
    title: "癌症防治行动",
    icon: require("../../static/images/acb/ac-12.jpg"),
    uid: "684290",
    actionTxt: "重大行动十二：癌症防治行动",
  },
  {
    title: "慢性呼吸系统疾病防治行动",
    icon: require("../../static/images/acb/ac-13.jpg"),
    uid: "684291",
    actionTxt: "重大行动十三：慢性呼吸系统疾病防治行动",
  },
  {
    title: "糖尿病防治行动",
    icon: require("../../static/images/acb/ac-14.jpg"),
    uid: "684292",
    actionTxt: "重大行动十四：糖尿病防治行动",
  },
  {
    title: "传染病及地方病防控行动",
    icon: require("../../static/images/acb/ac-15.jpg"),
    uid: "684293",
    actionTxt: "重大行动十五：传染病及地方病防控行动",
  },
];

const dataIndexs = [0, 3, 6, 9, 12];

function Fifteen(props) {
  const { list, getList } = FifteenModel();
  const [cookies, setCookie] = useCookies();
  useEffect(() => {
    getList();

    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "十五项行动",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });

    console.log("DIZoo2:", window.history);
  }, []);

  return (
    <div className="cmain comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="action-main">
        {dataIndexs.map((nitem, nindex) => {
          return (
            <Row key={nindex + "_row"} justify="space-between">
              {list.slice(nitem, nitem + 3).map((pitem, index) => {
                return (
                  <Col key={index + "_key"} className="action-card">
                    <div className="card-head">
                      <span>{majorList[pitem.category_two].title}</span>
                    </div>
                    <div className="card-bg">
                      <img src={majorList[pitem.category_two].icon} />
                    </div>
                    <div className="card-bottom">
                      <span className="card-bottom-title">行动文件</span>
                      <div
                        className="card-bottom-file"
                        onClick={() => {
                          let url = `/detial/${
                            majorList[pitem.category_two].uid
                          }`;
                          props.history.push(url);
                        }}
                      >
                        <div className="comm-blue-dot"></div>
                        <span>{majorList[pitem.category_two].actionTxt}</span>
                      </div>
                    </div>
                    <div className="action-dis">
                      <div className="action-dis-head">
                        <span className="action-dis-head-title">健康科普</span>
                        <span
                          className="action-dis-head-btn"
                          onClick={() => {
                            let url = `/health/${index + 1}`;
                            props.history.push(url);
                          }}
                        >
                          更多科普 <RightOutlined />
                        </span>
                      </div>
                      {pitem.item.health.map((sitem, sindex) => {
                        return (
                          <div
                            key={sindex + "_skey"}
                            onClick={() => {
                              let url = `/article/${sitem.id}`;
                              props.history.push(url);
                            }}
                            className="card-bottom-file"
                          >
                            <div className="comm-blue-dot"></div>
                            <span>{sitem.title}</span>
                          </div>
                        );
                      })}
                    </div>

                    <div className="action-dis">
                      <div className="action-dis-head">
                        <span className="action-dis-head-title">地方动态</span>
                        <span
                          className="action-dis-head-btn"
                          onClick={() => {
                            let url = `/local/${index}`;
                            props.history.push(url);
                          }}
                        >
                          更多动态 <RightOutlined />
                        </span>
                      </div>

                      {pitem.item.dynamic.map((sitem, sindex) => {
                        return (
                          <div
                            key={sindex + "_skey"}
                            onClick={() => {
                              let url = `/article/${sitem.id}`;
                              props.history.push(url);
                            }}
                            className="card-bottom-file"
                          >
                            <div className="comm-blue-dot"></div>
                            <span>{sitem.title}</span>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    </div>
  );
}

export default Fifteen;
