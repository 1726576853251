import React, {useState, useEffect, useCallback} from "react";
import {LeftOutlined} from "@ant-design/icons";
import {Breadcrumb, Space, Pagination, Result, Button} from "antd";
import Recommend from "../../components/Recommend";
import HotWorks from "../../components/HotWorks";
import HealthModel from "../../models/health";
import VideoList from "./component/vidio";
import {useCookies} from "react-cookie";
import dayjs from "dayjs";
import { TagList } from "../../components/TagList";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/health.css";

const TAG_CONFIG = {
    displayAttr: 'value',
    valueAttr: 'id'
};

function Index(props) {
    const {
        list,
        reclist,
        totalCount,
        bigVidioData,
        hostList,
        vidioData,
        searchData,
        getvedioToken,
        changevidioData,
        getHotList,
        setTotalCount,
        getList,
        getRecommend,
        getVideo,
    } = HealthModel();
    const [cookies, setCookie] = useCookies(["health_page"]);
    const [page, setPage] = useState(() => {
        let num = cookies.health_page;
        if (num) {
            return parseInt(num);
        }
        return 1;
    });
    const [cate, setCate] = useState(0);
    const [pagesize, setPageSize] = useState(() => {
        let num = 10;
        return num;
    });
    const [chooseKey, changeChoose] = useState(() => {
        let key = 0;
        let category_two = props.match.params.category_two;
        if (category_two && category_two > 0) {
            key = category_two;
        }
        // console.log(category_two, key);

        return key;
    });
    const updatePage = (id) => {
        changeChoose(id);
        setPage(1);
        if (id === "视频") {
            let res = getVideo(1, pagesize);
            // changevidioData([]);
            setTotalCount(4);
            setPage(1);
        } else if (id === "健康大咖谈") {
            getvedioToken();
            changevidioData(bigVidioData);
            setTotalCount(3);
            setPage(1);
        } else {
            setCookie("health_page", 1 + "", {maxAge: 600});
            getList(1, pagesize, id);
        }
    };
    useEffect(() => {
        getList(page, 10, chooseKey);
        getRecommend("health_life", 0, page);
        getHotList(0);

        let loc = localStorage.getItem("loc");
        let pre = localStorage.getItem("pre");
        if (loc && loc != window.location.href) {
            pre = loc;
        }
        loc = window.location.href;
        localStorage.setItem("pre", pre);
        localStorage.setItem("loc", loc);

        // window.TEA("jkzgxd_pageview", {
        //     url: window.location.href,
        //     title: "健康科普",
        //     url_path: window.location.pathname,
        //     referrer: pre,
        //     referrer_host: window.location.origin,
        // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const changeTag = useCallback((item) => {
        setCate(item.id);
        updatePage(item.id);
        getHotList(item.id); //内容推荐
        getRecommend("health_life", 0, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page])
    return (
        <div className="health-main comm-main">
            <div style={{margin: 0,width:"100%",padding:"10px 0"}} className="comm-head">
                <Breadcrumb style={{
                    margin: '0 auto',
                    width: "1080px"
                }}>
                    <Breadcrumb.Item href="/encyclopedia">
                        <Space>
                            <LeftOutlined/>
                            返回上一页
                        </Space>
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item>
                        <Space>
                            健康科普
                        </Space>
                    </Breadcrumb.Item> */}
                </Breadcrumb>
                <TagList className="health-tags" data={searchData}
                    options={TAG_CONFIG} 
                    current={cate}
                    onSelect={changeTag}
                />
            </div>
            <div
                className="health-content"
                style={{display: "flex", justifyContent: "space-between", marginTop: 10}}
            >
                <div>
                    <div>
                        {chooseKey === "视频" || chooseKey === "健康大咖谈" ? (
                            <VideoList
                                props={props}
                                vidioData={vidioData}
                                chooseKey={chooseKey}
                            ></VideoList>
                        ) : (
                            list.map((item, index) => {
                                return (
                                    <div
                                        key={"at_" + index}
                                        className="comm-article"
                                        onClick={() => {
                                            let goUrl = "/article/" + item.id;
                                            props.history.push(goUrl);
                                        }}
                                    >
                                        <div
                                            className={
                                                item.article_type == 2
                                                    ? "comm-article-left article-txt"
                                                    : "comm-article-left"
                                            }
                                        >
                                            <div title={item.title} className="comm-article-title">
                                                {item.title}
                                            </div>
                                            <div className="comm-article-content">
                                                {item.abstract}
                                            </div>
                                            {item.key_words && item.key_words.length > 0 && (
                                                <div className="comm-article-tags">
                                                    <Space>
                                                        {item.key_words.map((sitem, sindex) => {
                                                            return <span key={"tag_" + sindex}>{sitem}</span>;
                                                        })}
                                                    </Space>
                                                </div>
                                            )}

                                            <div className="comm-article-footer">
                                                <span>{item.author_info}</span>
                                                <span>
                          {dayjs(item.public_date)
                              .locale("zh-cn")
                              .format("YYYY") +
                          "年" +
                          dayjs(item.public_date)
                              .locale("zh-cn")
                              .format("MM") +
                          "月" +
                          dayjs(item.public_date)
                              .locale("zh-cn")
                              .format("DD") +
                          "日"}
                        </span>
                                            </div>
                                        </div>
                                        {item.article_type == 1 && (
                                            <div className="comm-article-right">
                                                <img
                                                    className="comm-article-right-bg"
                                                    src={item.img_url}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = require("../../static/images/nopng.png");
                                                    }}
                                                />
                                            </div>
                                        )}

                                        {item.article_type == 3 && (
                                            <div className="comm-article-right">
                                                <img
                                                    className="comm-article-right-bg"
                                                    src={item.img_url}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = require("../../static/images/nopng.png");
                                                    }}
                                                />

                                                <img
                                                    style={{
                                                        position: "absolute",
                                                        height: "34px",
                                                        width: "30px",
                                                        zIndex: "100",
                                                        top: 0,
                                                        right: "20px",
                                                    }}
                                                    src={require("../../static/images/pdf.png")}
                                                />
                                            </div>
                                        )}

                                        {item.article_type == 4 && (
                                            <div className="comm-article-right">
                                                <img
                                                    className="comm-article-right-bg"
                                                    src={item.img_url}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = require("../../static/images/nopng.png");
                                                    }}
                                                />

                                                <img
                                                    style={{
                                                        position: "absolute",
                                                        height: "40px",
                                                        width: "40px",
                                                        zIndex: "100",
                                                    }}
                                                    src={require("../../static/images/player.png")}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        )}

                        {chooseKey !== "健康大咖谈" &&
                        chooseKey !== "视频" &&
                        totalCount <= 0 && (
                            <Result
                                title="当前频道尚无内容"
                                extra={
                                    <Button
                                        type="primary"
                                        key="console"
                                        onClick={() => {
                                            props.history.push("/");
                                        }}
                                    >
                                        返回首页
                                    </Button>
                                }
                            />
                        )}
                        {/* article_type   1.图文,2.文本,3.文件,4.视频 */}

                        {/* <div className="comm-article">
            <div className="comm-article-left ">
              <div className="comm-article-title">
                胸闷、气短、常年干咳 警惕间质性肺病
              </div>
              <div className="comm-article-content">
                呼吸对于常人来说是自然不过的事，但对于特发性肺纤维化的患者来说，很多看似简单的行走、上楼等日常活动，都难以完成。特发性肺但对于特发性肺纤维化的化的患者…
              </div>
              <div className="comm-article-tags">
                <Space>
                  <span>科普知识</span>
                  <span>科普知识</span>
                  <span>科普知识</span>
                </Space>
              </div>
              <div className="comm-article-footer">
                <Space>
                  <span>首都医科大学附属北京朝阳医院</span>
                  <span>叶俏</span>
                  <span>主任医师</span>
                </Space>
                <span>2020年01月02日</span>
              </div>
            </div>

            <div className="comm-article-right">
              <img
                className="comm-article-right-bg"
                src={require("../../static/imageTmp/1.jpg")}
              />

              <img
                style={{
                  position: "absolute",
                  height: "40px",
                  width: "40px",
                  zIndex: "100",
                }}
                src={require("../../static/images/player.png")}
              />
            </div>
          </div> */}
                    </div>
                    {
                        <div className="comm-pages">
                            <Pagination
                                current={page}
                                total={totalCount}
                                pageSizeOptions={[10]}
                                onChange={(page, pageSize) => {
                                    if (pagesize != pageSize) {
                                        setPage(1);
                                        setCookie("health_page", 1 + "", {maxAge: 600});
                                        setPageSize(pageSize);
                                    } else {
                                        setPage(page);
                                        //保存记录
                                        setCookie("health_page", page + "", {maxAge: 600});
                                    }
                                    if (chooseKey !== "视频" && chooseKey !== "健康大咖谈") {
                                        getList(page, pageSize, chooseKey);
                                        getRecommend("health_life", 0, page);
                                    } else if (chooseKey === "视频") {
                                        getVideo(page, pageSize);
                                        getRecommend("health_life", 0, page);
                                    }
                                }}
                                defaultPageSize="10"
                            />
                        </div>
                    }
                </div>
                <div className="rec-part12">
                    <Recommend params={reclist} title="相关推荐"/>
                    <div style={{height: "20px"}}/>
                    {chooseKey !== "健康大咖谈" ? (
                        <HotWorks params={hostList}/>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Index;
