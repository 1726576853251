import React, {useEffect, useState,useRef} from "react";
import {
    DoubleRightOutlined,
    RightOutlined,
    LeftOutlined,
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {Carousel, Row, Col, Icon} from "antd";
import {cutStr} from "../../untils/commHelper";
import CommUnit from "../../components/CommUnit";
import HomeMode from "../../models/home";
import {useCookies} from "react-cookie";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/home.css";
import blur0 from "../../static/images/blur0.png"
import blur1 from "../../static/images/blur1.png"
import blur2 from "../../static/images/blur2.png"
import blur3 from "../../static/images/blur3.png"
import blur4 from "../../static/images/blur4.png"
import blur5 from "../../static/images/blur5.png"
import internetBanner from "../../static/images/staticBanner.png"
// import anniversary from '../../static/images/anniversary-entrance.png'
import knowledge from '../../static/images/knowledge-entrance.png'

function Index(props) {
    const blurImgList = [blur0, blur1, blur2, blur3, blur4, blur5];
    const {
        carousels,
        beltInfo,
        majorAction,
        majorList,
        localList,
        onChangeAction,
        onNextAction,
        init,
        activeCarousel,
        setActiveCarousel,
    } = HomeMode();
    const [cookies, setCookie] = useCookies();

    const rotation = useRef();
    const subBannerSwiper = useRef();
    const canvas = useRef()


    useEffect(() => {
        // console.log(require("stackblur-canvas"));
        init();
        let loc = localStorage.getItem("loc");
        let pre = localStorage.getItem("pre");
        if (loc && loc != window.location.href) {
            pre = loc;
        }
        loc = window.location.href;
        localStorage.setItem("pre", pre);
        localStorage.setItem("loc", loc);

        // window.TEA("jkzgxd_pageview", {
        //     url: window.location.href,
        //     title: "健康中国主页",
        //     url_path: window.location.pathname,
        //     referrer: pre,
        //     referrer_host: window.location.origin,
        // });
    }, []);

    useEffect(() => {
        if (!!(Array.isArray(beltInfo) && beltInfo.length)) {
            /*  专网腰封入口展现量 */
            window.collectEvent && window.collectEvent('jkzgxd_belt_show', {});
        }
    }, [beltInfo])

    const actionIndexs = [0, 3, 6, 9, 12];
    return (
        <div className="home-main">
            <Row justify="center">
                <Col style={{width: "778px", marginTop: "24px", height: "360px",position:"relative"}}>
                    <Carousel
                        ref={rotation}
                        dots={false}
                        autoplay={true}
                        effect={"fade"}
                        beforeChange={(from,to)=>{
                            setActiveCarousel(to)
                        }}
                    >
                        {carousels.map((citem, cindex) => {
                            let keyStr = "car_" + cindex;
                            return (
                                <div
                                    key={keyStr}
                                    className="rotation-box"
                                >
                                    <img alt="" className="rotation-box-bg" src={citem.imgUrl} onClick={() => {
                                        if (citem.id) {
                                            let goUrl = "/article/" + citem.id;
                                            props.history.push(goUrl);
                                            return;
                                        }
                                        if (citem.url) {
                                            window.location.href = citem.url;
                                        }
                                    }}/>
                                    {/*<div className="rotation-box-content">*/}
                                    {/*    <div className="rotation-content-bg"  style={{*/}
                                    {/*        background:`url(${blurImgList[cindex]})`,*/}
                                    {/*        backgroundSize:"cover",*/}
                                    {/*        backgroundPosition:"right top",*/}
                                    {/*    }} />*/}
                                    {/*</div>*/}
                                    {/*<div className="rotation-box-content">*/}
                                    {/*    <div className="rotation-box-innercontent">*/}
                                    {/*        <span className="rotation-box-content-num">{`${*/}
                                    {/*            cindex + 1*/}
                                    {/*        }/${carousels.length}`}</span>*/}
                                    {/*        <span className="rotation-box-content-title">*/}
                                    {/*            {citem.title}*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            );
                        })}
                    </Carousel> 
                </Col>
                {/* //新增加一列显示右侧标题栏 */}
                <Col style={{width: "301px", marginTop: "24px", height: "360px",position:"relative"}}>
                <ul className="rotation-content-list">
                        {carousels.map((litem, lindex) => {
                            return(
                                <li className={lindex === activeCarousel ? 'li-active' : ''}
                                    onMouseEnter={()=>{
                                        setActiveCarousel(lindex);
                                        rotation.current.goTo(lindex)
                                    }}
                                    onClick={()=>{
                                        if (litem.id) {
                                            let goUrl = "/article/" + litem.id;
                                            props.history.push(goUrl);
                                            return;
                                        }
                                        if (litem.url) {
                                            window.location.href = litem.url;
                                        }
                                    }}
                                >
                                    {litem.title}
                                </li>
                            )
                        })}
                    </ul>
                </Col>
            </Row>

            { (!!Array.isArray(beltInfo)) && !!(beltInfo?.length) && 
                <Row justify="center">
                    <Col style={{display: "flex", width: "1080px"}}>
                        <div className="sub-banner-swiper-wrap">
                            <Carousel
                                ref={subBannerSwiper}
                                dots={false}
                                autoplay={true}
                                effect={"fade"}
                            >
                                {beltInfo.map((belt) => {
                                    return (
                                        <img alt='' src={belt.imgUrl} onClick={() => {
                                            // 专网腰封入口点击量
                                            window.collectEvent && window.collectEvent('beconEvent', 'jkzgxd_belt_click', {
                                                title: belt.title,
                                            });
                                            window.location.href = belt.url;
                                        }} />
                                    );
                                })}
                            </Carousel>
                            {
                                !!(beltInfo?.length > 1) &&
                                <>
                                    <div className="sub-banner-swiper__prev" onClick={() => {
                                        subBannerSwiper.current.prev();
                                    }}>
                                        <LeftOutlined style={{ fontSize: '24px', color: '#fff' }} />
                                    </div>
                                    <div className="sub-banner-swiper__next"onClick={() => {
                                        subBannerSwiper.current.next();
                                    }}>
                                        <RightOutlined style={{ fontSize: '24px', color: '#fff' }}/>
                                    </div>
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            }
            

            
            <Row justify="center">
                <Col style={{display: "flex", width: "1080px"}}>
                    <div className="major-action">
                        <div className="hom-head">
                            <div className="comm-title">
                                <img
                                    className="comm-title-icon"
                                    src={require("../../static/images/ac-sw.png")}
                                />
                                <span className="comm-title-txt">十五项重大行动</span>
                            </div>
                        </div>
                        <div className="major-action-list">
                            {actionIndexs.map((nitem, nindex) => {
                                return (
                                    <Row key={nindex + "_row"}>
                                        {majorList.slice(nitem, nitem + 3).map((item, index) => {
                                            let keyStr = "mk_" + index;
                                            return (
                                                <Col
                                                    key={keyStr}
                                                    style={{height: nindex > 2 ? "150px" : "130px"}}
                                                    className="major-action-item"
                                                    onClick={() => {
                                                        let goUrl = "/detial/" + item.uid;
                                                        props.history.push(goUrl);
                                                    }}
                                                >
                                                    <img
                                                        className="major-action-item-icon"
                                                        src={item.icon}
                                                    />

                                                    <img
                                                        className="action-arrow"
                                                        src={require("../../static/images/arrow.png")}
                                                    />

                                                    <span className="major-action-item-title">
                            {item.title}
                          </span>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                );
                            })}
                        </div>
                    </div>
                    <div>
                        <div className="life">
                            <div className="hom-head">
                                <div className="comm-title">
                                    <img
                                        className="comm-title-icon"
                                        src={require("../../static/images/icon-sh.png")}
                                    />
                                    <span className="comm-title-txt">健康生活</span>
                                </div>

                                <div className="life-menu-list">
                                    <div
                                        className={
                                            majorAction.key == "healthLife"
                                                ? "life-menu-list-item cur"
                                                : "life-menu-list-item"
                                        }
                                        onClick={() => {
                                            onChangeAction("healthLife", 0);
                                        }}
                                    >
                                        <b>健康促进</b>
                                    </div>
                                    <div
                                        className={
                                            majorAction.key == "keyGroups"
                                                ? "life-menu-list-item cur"
                                                : "life-menu-list-item"
                                        }
                                        onClick={() => {
                                            onChangeAction("keyGroups", 0);
                                        }}
                                    >
                                        <b>重点人群</b>
                                    </div>
                                    <div
                                        className={
                                            majorAction.key == "disease"
                                                ? "life-menu-list-item cur"
                                                : "life-menu-list-item"
                                        }
                                        onClick={() => {
                                            onChangeAction("disease", 0);
                                        }}
                                    >
                                        <b>重大疾病</b>
                                    </div>
                                </div>
                            </div>
                            <div className="life-content">
                                <div
                                    className="arrow-btn btn-left "
                                    onClick={() => {
                                        if (majorAction.pre > 0) {
                                            onNextAction(majorAction.key, majorAction.page - 1);
                                        }
                                    }}
                                >
                                    <LeftOutlined
                                        className={
                                            majorAction.pre > 0 ? "btn-default" : "btn-disabled"
                                        }
                                    />
                                </div>

                                <div
                                    className="arrow-btn btn-right"
                                    onClick={() => {
                                        if (majorAction.next > 0) {
                                            onNextAction(majorAction.key, majorAction.page + 1);
                                        }
                                    }}
                                >
                                    <RightOutlined
                                        className={
                                            majorAction.next > 0 ? "btn-default" : "btn-disabled"
                                        }
                                    />
                                </div>
                                <Row align="bottom" justify="space-between">
                                    {majorAction.value.map((item, index) => {
                                        return (
                                            <Col
                                                key={"maj_" + index}
                                                className="life-content-item"
                                                onClick={() => {
                                                    setCookie("list_page", "1", {
                                                        path: "/",
                                                        maxAge: 300,
                                                    });
                                                    let goUrl = "/list/" + item.actionId;
                                                    props.history.push(goUrl);
                                                }}
                                            >
                                                <img
                                                    className="life-content-item-bg"
                                                    src={item.imgSrc}
                                                />
                                                <div className="life-content-item-title">
                                                    {item.title}
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </div>
                        <div className="local life">
                            <div className="hom-head">
                                <div className="comm-title">
                                    <img
                                        className="comm-title-icon"
                                        src={require("../../static/images/ac-lk.png")}
                                    />
                                    <span className="comm-title-txt">地方动态</span>
                                </div>
                                <Link to="/local">
                                    <div className="comm-more">
                                        更多 <DoubleRightOutlined/>
                                    </div>
                                </Link>
                            </div>
                            <div className="local-list">
                                {localList.map((item, index) => {
                                    return (
                                        <div
                                            key={"local_" + index}
                                            className="local-list-item"
                                            onClick={() => {
                                                let goUrl = "/article/" + item.id;
                                                props.history.push(goUrl);
                                            }}
                                        >
                      <span className="local-list-item-content">
                        {cutStr(item.title, 40)}
                      </span>
                                            <span className="local-list-item-time">
                        {item.public_date}
                      </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div></div>
                </Col>
            </Row>
            <div style={{height: "32px", width: "100%"}}></div>
            <CommUnit/>
        </div>
    );
}

export default Index;
