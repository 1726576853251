import React, { useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Space } from "antd";

import "../../static/css/pages/comm.css";
import "../../static/css/pages/system.css";

function Declare() {
  useEffect(() => {
    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "网站声明",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);
  return (
    <div className="system-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="sys-content">
        <div className="sys-content-title">网站声明</div>
        <div className="sys-content-txt">
          健康中国行动网站，所涉及的任何资料（包括但不限于文字报导、图片、声音、录像、图表、广告、域名、软件、程序、版面设计、专栏目录与名称、内容分类标准）的版权均属健康中国行动推进委员会办公室和资料提供者所有。未经书面许可，任何人不得引用、复制、转载、摘编或以其他任何方式非法使用健康中国行动网页的上述内容。对于有上述行为者，健康中国行动推进委员会办公室将保留追究其法律责任的权利。
        </div>

        <div className="sys-content-txt">
          <b>免责声明</b>
        </div>
        <div className="sys-content-txt">
          1、凡本网注明"来源：健康中国行动网站"或带有健康中国行动LOGO、水印的所有文字、图片和音频视频稿件，版权均属健康中国行动推进委员会办公室所有，任何媒体、网站或单位和个人未经本网书面授权不得转载、链接、转贴或以其他方式复制发表。已经本网书面授权的媒体、网站，在下载使用时必须注明"稿件来源：健康中国行动网站"，否则，将依法追究其法律责任。
        </div>

        <div className="sys-content-txt">
          2、对于健康中国行动推进委员会各成员单位及资料提供者通过健康中国行动网站发布的作品，本网受著作权人委托禁止任何媒体、网站或个人在未经书面授权的情况下转载使用。
        </div>

        <div className="sys-content-txt">
          3、本网未标有"来源：健康中国行动网站"或未带有健康中国行动LOGO、水印的文字、图片、音频视频等稿件多为转载稿，本网转载出于传递更多信息之目的，并不意味着赞同其观点或认可其内容的真实性。如其他媒体、网站或个人从本网下载使用，必须保留本网注明的"稿件来源"，并自负版权等法律责任。如擅自篡改为"稿件来源：健康中国行动网站"，本网将依法追究责任。如本网转载稿涉及版权等问题，请著作权人来电、来函与健康中国行动推进委员会办公室联系。
        </div>

        <div className="sys-content-txt">
          4、擅自使用健康中国行动网站的名义转载不规范来源的信息、版权不明的资讯，盗用健康中国行动网站名义发布信息，本网将依法追究其法律责任。
        </div>

        <div className="sys-content-txt">
          5、本网站如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本网站控制范围外的硬件故障或其它不可抗力而导致暂停服务，对于暂停服务期间造成的一切不便与损失，本网站不负任何责任。
        </div>
        <div className="sys-content-txt">
          {/* <b>健康中国行动推进委员会办公室</b> */}
          <b>健康中国行动网站</b>
        </div>
      </div>
    </div>
  );
}

export default Declare;
