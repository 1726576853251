import React, { useState, useEffect } from "react";

import { useCookies } from "react-cookie";
import HealthModel from "../../../models/health";
import "../../../static/css/pages/health.css";
function VideoList(props) { 

 useEffect(()=>{
 },[])
 return props.chooseKey=== '健康大咖谈' ? <div className="vidio-content">{
// 大咖谈
      props.vidioData.map((item,index)=>{
        return  <div className="video-card" key={index}>
            <div style={{ marginBottom: '10px'}} id={item.id}></div>
            <div className="video-title">{item.title}</div>
            <div className="video-label">{item.doctorInfo}</div>
            <div className="video-label">{item.label}</div>
            <div className="video-time">{item.time}</div>
          </div>
        })
    }
 </div>: <div className="vidio-vidio">
   {/* 视频 */}
        {props.vidioData.map((item,index)=>{
          return <div key={index} className="vidio-vidio-card" style={{width:'370px'}}> 
            <video 
            allowFullScreen={true}
            style={{outline:"none"}} 
            controls="controls" 
            width="370" 
            height="218"
            >
                 {/* controlsList="nodownload" */}
                <source src={item.video_url}/>
            </video>
            <div style={{cursor:'pointer'}} onClick={()=>{
              props.props.history.push(`/article/${item.id}`)
            }}>
              <div className="video-title">{item.title}</div>
              <div className="video-label">{item.author_info}</div>
              <div className="video-time">{item.public_date}</div>
            </div>
           
        </div>
        })}
 </div>

}

export default VideoList;
