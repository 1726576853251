import React, { useState, useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { cutStr } from "../../untils/commHelper";
import {
  Breadcrumb,
  Space,
  Pagination,
  Result,
  Button,
  Tabs,
} from "antd";
import Recommend from "../../components/Recommend";
import LocalModel from "../../models/local";
import { useCookies } from "react-cookie";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/local.css";
import { getProvinces } from "../../untils/citydata";
import './index.scss'

function Index(props) {
  const { TabPane } = Tabs;
  const {
    localList,
    totalCount,
    reclist,
    searchData,
    setSearchData,
    getLocalList,
    getRecommend,
  } = LocalModel();
  const [cookies, setCookie] = useCookies(["local_page"]);
  const [page, setPage] = useState(() => {
    let num = cookies.local_page;
    if (num) {
      return parseInt(num);
    }
    return 1;
  });
  const [activeKey, setActiceKey] = useState(() => {
    let category_two = props.match.params.category_two;
    if (category_two && category_two > 0) {
      return "1";
    }
    return "0";
  });

  const updateSearch = (name, key) => {
    /**
     * name 0地区 1行动
     * key当前选中id
     */
    let searchdata = JSON.parse(JSON.stringify(searchData));
    searchdata[name].value = key;
    setSearchData(searchdata);
    getLocalList(1, pagesize, searchdata[0].value, searchdata[1].value);
  };
  const [pagesize, setPageSize] = useState(() => {
    let num = 20;
    return num;
  });
  useEffect(() => {
    let key = 0;
    let data = JSON.parse(JSON.stringify(searchData));
    let category_two = props.match.params.category_two;
    if (category_two && category_two > 0) {
      key = category_two;
      data[1].value = category_two;
    } else {
      data[0].sublist[0] = [];
      data[0].sublist[0] = { label: "全国", value: 0 };
      data[0].sublist.push(...getProvinces());
    }

    setSearchData(data);

    getLocalList(page, 20, 0, key);
    getRecommend("local_dynamic", 0, page);

    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "地方动态",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);
  return (
    <div className="local-main comm-main">
      <div
        style={{ marginTop: "20px", paddingLeft: "16px", marginBottom: "10px" }}
      >
        <Tabs
          defaultActiveKey={activeKey}
          onChange={(key) => {
            console.log("JHKEY:", key);
            let searchdata = JSON.parse(JSON.stringify(searchData));
            if (key == 0) {
              searchdata[1].value = 0;
            } else if (key == 1) {
              searchdata[0].value = 0;
            }
            setSearchData(searchdata);
            getRecommend("local_dynamic", 0, page);
            getLocalList(1, pagesize, searchdata[0].value, searchdata[1].value);
          }}
          type="card"
          style={{ width: "1080px" }}
        >
          {searchData.map((item, index) => {
            return (
              <TabPane
                style={{ cursor: "pointer" }}
                tab={item.title}
                key={index}
              >
                {item.sublist.map((item0, index0) => {
                  return (
                    <div
                      key={index0}
                      onClick={() => {
                        updateSearch(item.name, item0.value);
                        getRecommend("local_dynamic", 0, page);
                      }}
                      className={`local-serach-second-item ${
                        item.value == item0.value
                          ? "local-serach-light-item"
                          : "local-serach-second-item"
                      }`}
                    >
                      {item0.label}
                    </div>
                  );
                })}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div
        style={{
          width: "1080px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="local-content">
          <div className="local-content-articles">
            {localList.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    let goUrl = "/article/" + item.id;
                    props.history.push(goUrl);
                  }}
                  key={"loc_" + index}
                  className="local-content-articles-item"
                >
                  <Space className="lc-title">
                    <div className="comm-blue-dot"></div>
                    <span>{cutStr(item.title, 80)}</span>
                  </Space>
                  <span style={{ color: "#666666" }}>{item.public_date}</span>
                </div>
              );
            })}
            {totalCount <= 0 && (
              <Result
                title="当前频道尚无内容"
                extra={
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      props.history.push("/");
                    }}
                  >
                    返回首页
                  </Button>
                }
              />
            )}
          </div>
          {totalCount > 0 && (
            <div className="comm-pages">
              <Pagination
                current={page}
                total={totalCount}
                pageSizeOptions={[20]}
                //showSizeChanger={false}
                onChange={(page, pageSize) => {
                  if (pagesize != pageSize) {
                    setPage(1);
                    setCookie("local_page", 1 + "", { maxAge: 600 });
                    setPageSize(pageSize);
                  } else {
                    setPage(page);
                    //保存记录
                    setCookie("local_page", page + "", { maxAge: 600 });
                  }
                  getLocalList(page, pageSize, searchData[0].value, "");
                  getRecommend("local_dynamic", 0, page);
                }}
                defaultPageSize="20"
              />
            </div>
          )}
        </div>

        <div className="rec-partt">
          <Recommend params={reclist} title="相关推荐" />
        </div>
      </div>
    </div>
  );
}

export default Index;
