export default {
    686419: {
        poster: 'https://vodpub1.v.news.cn/original/image/c2e3f5cc197f44598176b816a509115e_videogroup_1280_720_356.jpg',
        url: 'https://vodpub1.v.news.cn/original/20201022/beeac328283a4e64932593856337440f.mp4',
    },
    686420: {
        poster: 'https://vodpub1.v.news.cn/original/image/9f0f46c912664c588f386b40dd58017b_videogroup_1920_1080_356.jpg',
        url: 'https://vodpub1.v.news.cn/original/20201021/f5a1f999f7fa4d3d8a371bd94404bbe5.mp4',
    },
    686421: {
        poster: 'https://vodpub1.v.news.cn/original/image/3d6a074eea774f3ea03eca46db2cde8d_videogroup_1920_1080_711.jpg',
        url: 'https://vodpub1.v.news.cn/original/20201113/3b23770427d94a57847de29d7f36e55a.mp4',
    },
    686422: {
        poster: 'https://vodpub1.v.news.cn/original/image/4c290b08b50241e68ade70fa0e3828cb_videogroup_1067_600_0.jpg',
        url: 'https://vodpub1.v.news.cn/original/20201116/6d907645c55240a48c5e6c15070ed31a.mp4',
    },
    686423: {
        poster: 'https://vodpub1.v.news.cn/original/image/e1b0ea71b34a4adfadee4ca65322a97d_videogroup_1067_600_0.jpg',
        url: 'https://vodpub1.v.news.cn/original/20201201/f319c312b2324d8a97a1691c9be947d8.mp4',
    },
    686424: {
        poster: 'https://vodpub1.v.news.cn/original/image/2e4768d3f4e6464c932b63fbeb05dbf0_videogroup_1067_600_0.jpg',
        url: 'https://vodpub1.v.news.cn/original/20201214/725da94f35e143fc9aaa98c87cd2a8b9.mp4',
    },
    688743: {
        poster: 'https://vodpub1.v.news.cn/original/image/2e4768d3f4e6464c932b63fbeb05dbf0_videogroup_1067_600_0.jpg',
        url: 'https://file.jkzg2030.cn/video/%E5%AD%A6%E4%BC%9A%E4%BD%BF%E7%94%A8AED%EF%BC%8C%E8%AE%A9%E7%94%9F%E5%91%BD%E9%87%8D%E6%96%B0%E5%BE%8B%E5%8A%A8.mp4',
    },
    // '686425': { // 暂无视频
    //     poster: '',
    //     url: '',
    // },
    686426: {
        poster: 'https://vodpub1.v.news.cn/original/image/1c0e78b320dd44eba0c83b0231e4deae_videogroup_1067_600_0.jpg',
        url: 'https://vodpub1.v.news.cn/original/20201218/15c88dc895d744f38fe75d4a660da656.mp4',
    },
    686427: {
        poster: 'https://vodpub1.v.news.cn/original/image/29ef485961ec482aa75e3582219ab3a7_videogroup_1067_600_0.jpg',
        url: 'https://vodpub1.v.news.cn/original/20201231/da9d92df33044fc086ee711ad0de4ca6.mp4',
    },
    686428: {
        poster: 'https://vodpub1.v.news.cn/original/image/6781ae5fcfe04982a9b67c3bf154c78a_videogroup_1067_600_0.jpg',
        url: 'https://vodpub1.v.news.cn/original/20210108/0ada186bfc2e4167b7169d1b19cfd03c.mp4',
    },
    686533: {
        poster: 'http://vpic.video.qq.com/26276775/g3231s2kf3n.png',
        url: 'https://ugcsjy.qq.com/uwMROfz2r5zAoaQXGdGnC2dfJ6nM3DInWQqp2axRinGnB-kO/g3231s2kf3n.p701.1.mp4?sdtfrom=v1103&guid=a07a6e98fa9084d207378738d7cc5230&vkey=D8AD0EED3916EA0B059A179CAADB5280EAE62F0F40C4456E7793E60BD7B211DE5F41D31E54B830059F984CD37BB0612B7837C398F1D82D375649E8D56B4F928F96441B1DABF7501A566A73C3729BFDC66B9827F1E2F6BADAEFEB3FD787A2B3E5EE807D20DE97B040AC48C4BC478C3BF3A3D802D5AD46407C449F907B243E3076',
    },
    686534: {
        poster: 'http://vpic.video.qq.com/-30758045/e3231ek991b.png',
        url: 'https://ugcbsy.qq.com/uwMROfz2r57EIaQXGdGnC2dXDmbYfizQfi9f4dIqtXuMRWSf/gzc_1000035_0b2ejabqcaadeyalab2dynqjmsgdafeagaka.f204110.mp4?vkey=0995CFA3AEC1BDD588C799FF79AE4172D652A53212F74521BA248CB4A639ED71DADBB3E7A98D26F5795AA1AC73DE1AC0BA71251606B7556A3BD13D62EEC82A03221A4DB7D6C509A337AA35138160F47C1DD31A3E6C6B4D08BED9F23CA27B4B75EB608120B13F537D2BC05EE19F73188FA8A7971115FAEF105A8FFCC6E73F2D26',
    },
    686544: {
        poster: 'https://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/58192cbe53154ad397e2781b7c53bf07~tplv-a3h8qa8x9l-png.png',
        url: 'https://mpvideo.qpic.cn/0b78sqaacaaaamalxw2j2vqfbfgdagkaaaia.f10002.mp4?dis_k=67b6881dd2788ae24a1b43d0b7dc001f&dis_t=1615539377',
    },
    686583: {
        poster: 'https://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/b7462d9a73234adcb212f6a94ae6192d~tplv-a3h8qa8x9l-png.png?',
        url: 'https://mpvideo.qpic.cn/0bf2weabkaaalmabss3m4nqfbmodcwyqafia.f10002.mp4?dis_k=0df168d7a56f255ec0e7cd022b73a943&dis_t=1617936707',
    },
};
