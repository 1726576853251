import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../untils/requestHelper";
import {message} from "antd";
import Service from 'xgplayer-service';
import Player from 'xgplayer';
import expertsInfo from "../untils/healthExpertsData";

function useHealth() {
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(1);
    const [reclist, setReList] = useState([]);
    const [hostList, setHotList] = useState([]);
    const [searchData, changeSearch] = useState(() => {
        let list = [
            {value: '推荐', id: 0},
            {value: '健康知识科普', id: 1},
            {value: '合理膳食', id: 2},
            {value: '全民健身', id: 3},
            {value: '控烟', id: 4},
            {value: '心理健康', id: 5},
            {value: '健康环境', id: 6},
            {value: '妇幼健康', id: 7},
            {value: '中小学健康', id: 8},
            {value: '职业健康', id: 9},
            {value: '老年健康', id: 10},
            {value: '心脑血管疾病', id: 11},
            {value: '癌症', id: 12},
            {value: '慢性呼吸系统疾病', id: 13},
            {value: '糖尿病', id: 14},
            {value: '传染病及地方病', id: 15},
            // {value:'健康大咖谈', id: '健康大咖谈'},
            // {value: '视频', id: '视频'}
        ]
        return list;
    });
    const getList = async (page, limit, type) => {
        let params = {};
        params.page = page;
        params.limit = limit;
        if (type !== 0) {
            params.category_two = type;
        }
        let ret = await reqestApi("/api/health_life", "GET", params);
        if (ret.data.code == 0) {
            if(Array.isArray(ret.data.data)){
                const newData = ret.data.data.map((item) => { item.abstract = (item.abstract) || (item.content).replace(/<.*?>/g, ''); return item; });
                setList(newData);
            }
            setTotalCount(ret.data.totalCount);
        }
    };

    const getHotList = async (sub_cate) => {
        let params = {};
        if (sub_cate > 0) {
            params.sub_cate = sub_cate;
        }
        let ret = await reqestApi("/api/hot", "GET", params);
        if (ret.data.code == 0) {
            setHotList(ret.data.data);
        }
    };
    const [vidioData, changevidioData] = useState(() => {
        return [];
    });

    const [bigVidioData, changeBigVidio] = useState(() => {
        return [...expertsInfo]
    })

    const getvedioToken = async () => {
        var token = ''
        var bigVidiodata
        let i = 0
        for (i = 0; i < bigVidioData.length; i++) {
            let ret = await reqestApi(`api/video/token?vid=${bigVidioData[i].urlid}`, "GET")
            if (ret.data.code === 0) {
                token = ret.data.data.token
                bigVidiodata = bigVidioData
                bigVidiodata[i].token = token

                await Service.url(token).then((res) => {
                    new Player({
                        id: bigVidioData[i].id,
                        poster: res.Data.CoverUrl,
                        url: res.Data.PlayInfoList[0].MainPlayUrl,
                        width: '780px',
                        height: '440px'
                    });
                });
            }
        }
        changeBigVidio(bigVidiodata)
    }
    const getVideo = async (page, pagesiize) => {
        let params = {
            page: page,
            limit: pagesiize,
            type: 4
        }
        let ret = await reqestApi("/api/health_life", "GET", params)
        if (ret.data.code == 0) {
            console.log(ret.data.data)
            changevidioData(ret.data.data)
            setTotalCount(ret.data.totalCount);
        }
    }
    const getRecommend = async (cate, sub_cate, page) => {
        let params = {};
        params.cate = cate;
        if (sub_cate > 0) {
            params.sub_cate = sub_cate;
        }

        params.page = page;
        let ret = await reqestApi("/api/recommand", "GET", params);
        if (ret.data.code == 0) {
            setReList(ret.data.data)
        }
    };
    return {
        list,
        reclist,
        searchData,
        totalCount,
        bigVidioData,
        hostList,
        vidioData,
        getvedioToken,
        changevidioData,
        getHotList,
        getVideo,
        getList,
        // getHotList,
        getRecommend,
        setTotalCount,
    };
}

export default createModel(useHealth);
