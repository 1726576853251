import React from "react";
import { RightOutlined } from "@ant-design/icons";
import { cutStr } from "../untils/commHelper";
import "../static/css/components/Recommend.css";
import { Link } from "react-router-dom";
/**推荐组件 */
const Recommend = (props) => {
  const { params, title } = props;
  const numStr = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"];
  //const { list, getRecommend } = RecommendModel()
  // useEffect(() => {
  //   //console.log("paramsZJ:", params)
  //   getRecommend(params.cate, params.sub_cate, params.page)
  // }, [])
  return (
    <>
      {params.length > 0 && (
        <div className="recommend-box">
          <div className="recommend-box-head">{title}</div>
          <div className="recommend-box-list">
            {params.map((item, index) => {
              return (
                <Link
                  style={{ color: "#333333" }}
                  key={"rec_" + index}
                  to={`/article/${item.id}`}
                >
                  <div className="recommend-box-item">
                    <span className="rec-num htnum">{numStr[index]}</span>
                    <span className="rec-content htnum">
                      {cutStr(item.title, 50)}
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>
          {/* <div className="recommend-box-btn">
            查看全部
            <RightOutlined />
          </div> */}
        </div>
      )}
    </>
  );
};

export default Recommend;
