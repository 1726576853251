import React, { useEffect, useState } from "react";
import { Route, Link } from "react-router-dom";
import { Layout, Input, Dropdown, Space, Row, Col } from "antd";
import { isShowSubMenu } from "../untils/pathHelper";
import { SearchOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import { reqestApi } from "../untils/requestHelper";
import Index from "../pages/index";
import Health from "../pages/health/index";
import Home from "../pages/home/index";
import Local from "../pages/local/index";
import Quota from "../pages/quota/index";
import Service from "../pages/service/index";
import News from "../pages/news/index";
import Consult from "../pages/news/consult";
import Health2030 from "../pages/news/health";
import Action from "../pages/news/action";
import Article from "../pages/article/index";
import Detial from "../pages/article/detial";
import ArticleList from "../pages/article/list";
import ArticleResult from "../pages/article/result";
import About from "../pages/system/about";
import Contact from "../pages/system/contact";
import Declare from "../pages/system/declare";
import FifteenActions from "../pages/action/fifteen";
import Error from "../pages/system/error";
import TestPage from "../pages/system/test";
import InternetHospital from "../pages/internethospital";
import InnovationExchange from '../pages/innovation'
import InnovationDetail from "../pages/innovation/detail";
import InfoStep from "../pages/internethospital/detail/infoStep";
import Encyclopedia from "../pages/encyclopedia/index"
import Expert from "../pages/expert/index"
import ExpertDetail from "../pages/expert/detail/detail"
import EntryList from "../pages/encyclopedia/entry/list"
import EntryDetail from "../pages/encyclopedia/entry/detail"
import Update from "../pages/update/index";

import "../static/css/pages/comm.css";
import "../static/css/pages/layout.css";
const { Header, Footer, Sider, Content } = Layout;
function LayAdmin(props) {
  const [searchTxt, setSearchTxt] = useState("");
  const [menus, setMenus] = useState(() => {
    let list = [
      {
        name: "首页   ",
        toUrl: "/",
        num: 0,
        key: "/",
        type: "txt",
      },
      {
        name: "权威信息",
        toUrl: "/news",
        num: 1,
        key: "/news",
        type: "sel",
        submenu: [
          {
            name: "健康中国2030",
            toUrl: "/article/684635",
            num: 10,
            key: "/health2030",
            type: "txt",
          },
          {
            name: "健康中国行动",
            toUrl: "/article/684636",
            num: 11,
            key: "/action",
            type: "txt",
          },
          {
            name: "官方资讯",
            toUrl: "/consult",
            num: 12,
            key: "/consult",
            type: "txt",
          },
        ],
      },
      {
        name: "十五项行动",
        toUrl: "/fifteenActions",
        num: 2,
        key: "/fifteenActions",
        type: "txt",
      },
      {
        name: "创新交流平台",
        toUrl: "/innovation",
        num: 2,
        key: "/innovation",
        type: "txt",
      },
      { name: "主要指标", toUrl: "/quota", num: 2, key: "/quota", type: "txt" },
      { name: "地方动态", toUrl: "/local", num: 3, key: "/local", type: "txt" },
      {
        name: "健康百科",
        toUrl: "/encyclopedia",
        num: 4,
        key: "/encyclopedia",
        type: "txt",
      },
      {
        name: "便民服务",
        toUrl: "/service",
        num: 5,
        key: "/service",
        type: "",
      },
    ];
    return list;
  });
  const [showCommom, setShowCommon] = useState(false);
  const [cookies, setCookies] = useCookies();
  useEffect(() => {
    if(window.location.pathname==='/internethospital' || window.location.pathname==='/infostep' || window.location.pathname==='/update'){
      setShowCommon(true)
    } else {
      setShowCommon(false)
    }
    // let path = props.location.pathname;
    // if (path == "/" || path.length <= 1) {
    //   props.history.push("/");
    // }
  }, []);

  useEffect(() => {
    if(props.location.pathname !== "/innovation" && !/innovationcase/.test(props.location.pathname)){
      localStorage.removeItem("inno_param");
      localStorage.removeItem("inno_page");
    }
    if(props.location.pathname !== "/entry" && !/entrydetail/.test(props.location.pathname)){
      localStorage.removeItem("dep_case");
      localStorage.removeItem("dep_page");
    }
  }, [props.location])

  useEffect(() => {
    try {
      const redirectUpdate = async () => {
        const ret = await reqestApi("/api/settings/switch_status", "GET");
        if (ret?.data?.code === 0 && ret?.data?.data?.status === 2) {
            props.history.push('/update');
        }
      };
      const redirectIndex = async () => {
          const ret = await reqestApi("/api/settings/switch_status", "GET");
          if (ret?.data?.code === 0 && ret?.data?.data?.status === 1) {
              props.history.push('/');
          }
      };
      if (props.location.pathname !== '/update') {
          redirectUpdate();
      } else {
          redirectIndex();
      }
    } catch (error) {}
  }, [props.history, props.location.pathname])

  
  return (
    <div className="project-container">
      <Layout>
        <Row
          hidden={showCommom}
          justify="center"
          style={{
            // width: 1122,
            // margin: '0 auto',
            height: "110px",
            background: "#ffffff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col className="top-box" style={{ width: "1080px" }}>
            <div className="logo-box">
              <img
                className="logo-icon"
                src={require("../static/images/newlogo.png")}
              />
            </div>
            <div className="search-box">
              <Input
                className="search-input"
                onChange={(e) => {
                  setSearchTxt(e.target.value);
                }}
                placeholder="搜索“重大行动/主要指标/权威科普”"
                onPressEnter={() => {
                  props.history.push("/result/" + searchTxt);
                }}
                prefix={
                  <SearchOutlined
                    color="#999999"
                    height="1.5rem"
                    width="1.5rem"
                  />
                }
              />
            </div>
          </Col>
        </Row>
      </Layout>

      <Layout>
        <Row
          hidden={showCommom}
          justify="center"
          style={{
            // width: 1122,
            // margin: '0 auto',
            height: "50px",
            background: "#108CEE",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Col className="menu-navs">
            {menus.map((item, index) => {
              let mode = (
                <Link to={item.toUrl}>
                <div className="menu-navs-item" key={item.key}>           
                    <span
                      className={
                        item.key === props.location.pathname
                          ? "menu-title cur"
                          : "menu-title"
                      }
                    >
                      {item.name}
                    </span>
                </div>
                </Link>
              );
              if (item.type == "sel") {
                mode = (
                  <div className="menu-navs-item" key={item.key}>
                    <Dropdown
                      overlay={
                        <div className="sub-column-list">
                          {item.submenu.map((sitem, sindex) => {
                            return (
                              <Link to={sitem.toUrl} key={"s——" + sindex}>
                                <div className="sub-column-list-item">
                                  {sitem.name}
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      }
                      placement="bottomCenter"
                    >
                      <span
                        className={
                          isShowSubMenu(props.location.pathname)
                            ? "menu-title cur"
                            : "menu-title"
                        }
                      >
                        {item.name}
                      </span>
                    </Dropdown>
                  </div>
                );
              }

              return mode;
            })}
          </Col>
        </Row>
        {isShowSubMenu(props.location.pathname) && (
          <div hidden={showCommom} className="sub-menu">
            <div className="sub-menu-list">
              <Link to="/article/684635">
                <div
                  className={
                    props.location.pathname == "/health2030"
                      ? "sub-menu-item cur"
                      : "sub-menu-item"
                  }
                >
                  健康中国2030
                </div>
              </Link>
              <Link to="/article/684636">
                <div
                  className={
                    props.location.pathname == "/action"
                      ? "sub-menu-item cur"
                      : "sub-menu-item"
                  }
                >
                  健康中国行动
                </div>
              </Link>
              <Link to="/consult">
                <div
                  className={
                    props.location.pathname == "/consult"
                      ? "sub-menu-item cur"
                      : "sub-menu-item"
                  }
                >
                  官方资讯
                </div>
              </Link>
            </div>
          </div>
        )}
        <Content>
          <div style={{ minHeight: "70vh", background: "#ffffff", }} id="goTop">
            <Route path="/" exact component={Home} />
            <Route path="/index" exact component={Index} />
            <Route path="/health" exact component={Health} />
            <Route path="/health/:category_two" exact component={Health} />
            <Route path="/news" exact component={News} />
            <Route path="/consult" exact component={Consult} />
            <Route path="/health2030" exact component={Health2030} />
            <Route path="/action" exact component={Action} />
            <Route path="/local" exact component={Local} />
            <Route path="/local/:category_two" exact component={Local} />
            <Route path="/quota" exact component={Quota} />
            <Route path="/service" exact component={Service} />
            <Route path="/update" exact component={Update} />
            <Route path="/result/:query?" exact component={ArticleResult} />
            <Route path="/list/:cate?" exact component={ArticleList} />
            <Route path="/article/:id?" exact component={Article} />
            <Route path="/about" exact component={About} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/declare" exact component={Declare} />
            <Route path="/error" exact component={Error} />
            <Route path="/detial/:id?" exact component={Detial} />
            <Route path="/fifteenactions" exact component={FifteenActions} />
            <Route path="/internethospital" component={InternetHospital} />
            <Route path="/innovation" component={InnovationExchange} />
            <Route path="/innovationcase/:id" component={InnovationDetail}/>
            <Route path="/preview/:id" component={InnovationDetail}/>
            <Route path="/infostep" component={InfoStep}/>
            <Route path="/encyclopedia" component={Encyclopedia}/>
            <Route path="/expert" component={Expert}/>
            <Route path="/expertdetail/" component={ExpertDetail}/>
            <Route path="/entry" component={EntryList}/>
            <Route path="/entrydetail/:id" component={EntryDetail}/>
          </div>
        </Content>
        <Footer hidden={showCommom} style={{ height: "150px", background: "#333333" }}>
          <div className="footer">
            <div className="organizer">
              主办单位：健康中国行动推进委员会办公室
            </div>
            <div className="do-organizer">承办单位：健康报社</div>
            <div className="copyright">
              <Space>
                @版权所有：健康中国行动推进委员会 |
                <Link to="/about">
                  <span>关于本网</span>
                </Link>
                |
                <Link to="/contact">
                  <span>联系我们</span>
                </Link>
                |
                <Link to="/error">
                  <span>网站纠错</span>
                </Link>
                |
                <Link to="/declare">
                  <span>网站声明</span>
                </Link>
              </Space>
            </div>
          </div>
        </Footer>
      
      </Layout>
    </div>
  );
}

export default LayAdmin;
