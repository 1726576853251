import { GetApiHost } from "../conf/app";

/**字符串按字节数切割 */
export const cutStr = (str, len) => {
  try {
    var result = "";
    var strlen = str.length; // 字符串长度
    var chrlen = str.replace(/[^x00-\xff]/g, "**").length; // 字节长度

    if (chrlen <= len) {
      return str;
    }

    for (var i = 0, j = 0; i < strlen; i++) {
      var chr = str.charAt(i);
      if (/[x00-\xff]/.test(chr)) {
        j++; // ascii码为0-255，一个字符就是一个字节的长度
      } else {
        j += 2; // ascii码为0-255以外，一个字符就是两个字节的长度
      }
      if (j <= len) {
        // 当加上当前字符以后，如果总字节长度小于等于L，则将当前字符真实的+在result后
        result += chr;
      } else {
        // 反之则说明result已经是不拆分字符的情况下最接近L的值了，直接返回
        return result + "...";
      }
    }
  } catch (ex) {
    return "";
  }
};

export const getUUId = function () {
  let UUID = require("uuid");
  return UUID.v1();
};

export const getStr = (str) => {
  if (str) {
    return str;
  }
  return "--";
};

export const decodeContent = (content) => {
  try {
    if (content) {
      return decodeURI(content);
    }
  } catch (ex) {
    return content;
  }
  return content;
};

export const getBrowerV = () => {
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    return true;
  }
};
