import React, { useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Space, Table, Row, Col } from "antd";
import QuotaModel from "../../models/quota";
import { getStr } from "../../untils/commHelper";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/quota.css";
const { Column } = Table;
function Index() {
  const { cate, type, quotaList, numList, getTable } = QuotaModel();
  useEffect(() => {
    getTable(10, 0, 0, 1);
    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "主要指标",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);

  return (
    <div className="quota-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="quota-content">
        <div className="quota-head">
          <div className="quota-head-title">主要指标进展</div>
          <div className="quota-head-content">
            到2022年，覆盖经济社会各相关领域的健康促进政策体系基本建立，全民健康素养水平稳步提高，健康生活方式加快推广，心脑血管疾病、癌症、慢性呼吸系统疾病、糖尿病等重大慢性病发病率上升趋势得到遏制，重点传染病、严重精神障碍、地方病、职业病得到有效防控，致残和死亡风险逐步降低，重点人群健康状况显著改善。
          </div>
        </div>

        <div className="quota-tab">
          <Row className="quota-tab-head">
            <Col
              span={6}
              className={
                cate == 0
                  ? "quota-tab-head-item active"
                  : "quota-tab-head-item "
              }
              onClick={() => {
                getTable(1, 0, 0, type);
              }}
            >
              {`${numList[0].name}（${numList[0].num}）`}
            </Col>
            <Col
              span={6}
              className={
                cate == 1
                  ? "quota-tab-head-item active"
                  : "quota-tab-head-item "
              }
              onClick={() => {
                getTable(1, 0, 1, type);
              }}
            >
              {`${numList[1].name}（${numList[1].num}）`}
            </Col>
            <Col
              span={6}
              className={
                cate == 2
                  ? "quota-tab-head-item active"
                  : "quota-tab-head-item "
              }
              onClick={() => {
                getTable(1, 0, 2, type);
              }}
            >
              {`${numList[2].name}（${numList[2].num}）`}
            </Col>
            <Col
              span={6}
              className={
                cate == 3
                  ? "quota-tab-head-item active"
                  : "quota-tab-head-item "
              }
              onClick={() => {
                getTable(1, 0, 3, type);
              }}
            >
              {`${numList[3].name}（${numList[3].num}）`}
            </Col>
          </Row>

          <div className="quota-tab-tags">
            <div
              className={type == 1 ? "tag-item active" : "tag-item"}
              onClick={() => {
                getTable(1, 0, cate, 1);
              }}
            >
              {`${numList[4].name}（${numList[4].num}）`}
            </div>
            <div
              className={type == 2 ? "tag-item active" : "tag-item"}
              onClick={() => {
                getTable(1, 0, cate, 2);
              }}
            >
              {`${numList[5].name}（${numList[5].num}）`}
            </div>
            <div
              className={type == 3 ? "tag-item active" : "tag-item"}
              onClick={() => {
                getTable(1, 0, cate, 3);
              }}
            >
              {`${numList[6].name}（${numList[6].num}）`}
            </div>
          </div>

          <Table
            bordered={true}
            dataSource={quotaList}
            pagination={false}
            style={{
              margin: "-1px",

              maxWidth: "none",
            }}
          >
            <Column
              align="center"
              title="指标描述"
              dataIndex="obj"
              width="240px"
              render={(obj) => {
                return (
                  <div>
                    <div className="indicator_name">{obj.indicator_name}</div>
                    <div className="territory">{obj.territory}</div>
                  </div>
                );
              }}
            />
            <Column
              align="center"
              title="指标性质"
              dataIndex="indicator_type"
              render={(o) => {
                return <div>{getStr(o)}</div>;
              }}
            />
            <Column
              align="center"
              title="基期水平"
              dataIndex="base_level"
              width="200px"
              render={(o) => {
                return <div>{getStr(o)}</div>;
              }}
            />
            <Column
              align="center"
              title="2022年目标值"
              dataIndex="indicator_2022_value"
              render={(o) => {
                return getStr(o);
              }}
            />
            <Column
              align="center"
              title="2030年目标值"
              dataIndex="indicator_2030_value"
              render={(o) => {
                return getStr(o);
              }}
            />
          </Table>
        </div>
        <div className="quota-dis">
          注：（1）本文件中的有关调查数据，未特别说明的，主要为官方抽样调查统计数据；（2）本主要指标表中，未写明年份的基线水平值，均为2017年数值；（3）#为2020年目标值。
        </div>
      </div>
    </div>
  );
}

export default Index;
