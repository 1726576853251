import React, {useState, useEffect, useRef} from "react";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {Spin} from 'antd';
import Player from "xgplayer";

import "./detail.scss"
import ListModal from "../../../models/entry"
import lotus from "../../../static/images/lotus_logo.png"
import nodeImg from "../../../static/images/node.png"
import entryHeader from "../../../static/images/entry-detail-header.png"

function Detail(props) {
    const headerHeight = 224; // 头部高度

    const [modalVisible, setModalVisible] = useState(false);
    const [fixCatalog, setFixCatalog] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const videoList = useRef([]);
    const outContainer = useRef({});

    const {
        entryInfo,
        getEntryInfo,
        entryLoading,
    } = ListModal()
    const methods = {
        getTagClassName: (tags = [], index) => {
            if (!tags[index]) {
                return ''
            }
            if (!tags[index + 1] || tags[index + 1]?.level === 2){
                return ' bottom-border'
            }
            if (tags[index + 1]?.level === 3) {
                return ' bottom3';
            }
            return ' bottom4'
        },
        getName: (str) => {
            for (let i = 0; i < str.length; i++) {
                if (str[i] === '[' && str.substring(i, i + 6) === '[link]') {
                    let startIndex = 0;
                    for (let j = i + 6; j < str.length; j++) {
                        if (str[j] === '$') {
                            startIndex = j + 1;
                        } else if (str[j] === ']' && startIndex) {
                            const name = str.slice(startIndex, j);
                            const replaceStr = str.slice(i, j + 1);
                            str = str.replace(replaceStr, name);
                            i = i + name.length - 1;
                            break;
                        }
                    }
                }
            }
            return str;
        },
        getRow: (row, index) => {
            if (row.type === "Li1") {
                return  <div className="row-li">
                    <img src={nodeImg} alt=""/>
                    <div>{methods.getName(row.content)}</div>
                </div>
            } else if (row.type === "image") {
                return  <img className="row-img" src={row.content} alt=""/>
            } else if (row.type === "table") {
                return <div>
                    {row.content.split('|').map((item1, index1) => {
                        return <div className="row-table" key={index1}>
                            {item1.split('&').map((item2, index2) => {
                                return <div className={`row-lattice${index1 ? ' content-table' : ''}`} key={index2} style={{
                                    backgroundColor: !index1 ? '#108CEE' : !index2 ? '#F5F7FA' : "white",
                                    borderBottom: index1 + 1 === row.content.split('|').length ? '1px solid #f2f3f2' : 0,
                                }}>
                                    {item2 || '-'}
                                </div>
                            })}
                        </div>
                    })}
                </div>
            } else if(row.type === "video") {
                videoList.current.push({
                    id: index,
                    url: row.content.split(',')[0],
                    poster: row.content.split(',')[1],
                });
                return <div id={index}/>
            } else if (row.type === "text") {
                return <div className="row-text">
                    {methods.getName(row.content)}
                </div>
            } else if (row.type === "Li2") {
                return <div className="row-li2">
                    {methods.getName(row.content)}
                </div>
            } else {
                return
            }
        }
    }

    const domEvents = {
        onScroll: () => {
            const ifFixed = window.pageYOffset >= headerHeight;
            setFixCatalog(ifFixed);
            const elements = document.getElementsByClassName('content');
            for (let i = elements.length - 1; i >= 0; i--) {
                if (window.pageYOffset >= elements[i].offsetTop + headerHeight) {
                    setActiveIndex(i);
                    return;
                }
            }
            setActiveIndex(0);
        },
        changeActiveTag: (index) => {
            const ele = document.getElementsByClassName('content')[index];
            const top = ele.offsetTop;
            window.scrollTo(0, top + headerHeight);
        }
    }

    useEffect(() => {
        getEntryInfo(props.match.params.id);
        window.addEventListener("scroll", domEvents.onScroll);
        return () => {
            window.removeEventListener("scroll", domEvents.onScroll)
        }
    }, [])

    useEffect(() => {
        if(videoList.current.length){
            videoList.current.forEach(item => {
                new Player(item);
            })
        }
    }, [entryInfo])

    return (
        <div className="entry-detail">
            <div className="header-tab">
                <img className="lotus-logo" src={lotus} alt=""/>
            </div>
            <div className="out-info-container">
                <Spin spinning={entryLoading}  >
                    {entryInfo.dise_name && <div className="info-container" ref={outContainer}>
                        <div className="infos">
                            <div className="head-main-info">
                                <div className="title">{entryInfo.dise_name}</div>
                                <div className="department">就诊科室：{entryInfo.department.split(",").join("、")}</div>
                                <img src={entryHeader} className="header-decorate" alt=""/>
                            </div>
                            <div className="other-infos">
                                {
                                    Array.isArray(entryInfo.doctor_list) && entryInfo.doctor_list.length > 0 && entryInfo.doctor_list.map((doctor, index) => {
                                        return (
                                          <div key={index} className="doc-info">
                                              <img src={doctor.avatar} alt=""/>
                                              <div className="doctor-info">
                                                  <div className="basics">
                                                      <span className="name">{doctor.expertName}</span>
                                                      <span>{doctor.expertTitle}</span>
                                                  </div>
                                                  <div className="special">
                                                    {doctor.tab_two && <div className="tab_one"></div>}
                                                    {doctor.tab_one && <div className="tab_two"></div>}
                                                  </div>
                                                  <div className="hospital-info">
                                                      <span>{doctor.hospitalName}</span>
                                                      {doctor.hospitalLevel && <span className="hospital-level"><span className="hospital-level-name">{doctor.hospitalLevel === '三级甲等' ? '三甲' : doctor.hospitalLevel}</span></span>}
                                                  </div>
                                                  {/* <div className={`social${modalVisible ? '' : ' hide-mode'}`}>
                                                      <span>{doctor.socialDuty.split('n').join("")}</span>
                                                  </div>
                                                  {doctor.socialDuty.length > 88 && !modalVisible && <div className="show-more-btn" onClick={() => {
                                                      setModalVisible(true)
                                                  }}>
                                                      <span>展开</span>
                                                      <DownOutlined/>
                                                  </div>}
                                                  {doctor.socialDuty.length > 88 && modalVisible && <div className="show-more-btn close-btn" onClick={() => {
                                                      setModalVisible(false)
                                                  }}>
                                                      <span>收起</span>
                                                      <UpOutlined />
                                                  </div>} */}
                                              </div>
                                          </div>
                                        )
                                    })
                                }
                                {Array.isArray(entryInfo.summary) && <div className="summary">
                                    {
                                        entryInfo.summary.map((item, index) => {
                                            return <div className="summary-item" key={index}>
                                                <img src={nodeImg} alt=""/>
                                                <div className="summary-content">
                                                    <span>{item}。</span>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>}
                                {
                                    Array.isArray(entryInfo.content) && entryInfo.content.length > 0 && entryInfo.content.map((item, index) => {
                                        return (
                                          <div id={index} key={index} className="content">
                                              <div className="name-one">{item.title}</div>
                                              <div className="content-rows">
                                                  {
                                                      Array.isArray(item.rows) && item.rows.length > 0 && item.rows.map((row, index1) => {
                                                          return methods.getRow(row)
                                                      })
                                                  }
                                              </div>
                                              {
                                                  Array.isArray(item.tags) && item.tags.length > 0 && item.tags.map((tag, index1) => {
                                                      return (
                                                        <div key={index1} className={`tag-container${methods.getTagClassName(item.tags, index1)}`}>
                                                            {
                                                                tag.level === 3 ? <div className="subtitle-tag">
                                                                    {tag.title}
                                                                </div> : <div className={`subtitle-name${tag.level === 4 ? ' level4' : ''}`}>
                                                                    {tag.title}
                                                                </div>
                                                            }
                                                            {
                                                                Array.isArray(tag.rows) && tag.rows.length > 0 && tag.rows.map((rowItem, index2) => {
                                                                    return methods.getRow(rowItem, ""+index+index1+index2)
                                                                })
                                                            }
                                                        </div>
                                                      )
                                                  })
                                              }
                                          </div>
                                        )
                                    })
                                }
                                {
                                    Array.isArray(entryInfo.reference) && entryInfo.reference.length > 0 && <div className="reference">
                                        <div className="reference-title">参考文献</div>
                                        {
                                            entryInfo.reference.map((reference, index) => {
                                                return <div className="reference-item" key={index}>
                                                    {reference.content}
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>}
                </Spin>
                {
                    Array.isArray(entryInfo.content) && !!entryInfo.content.length && <div className={`catalog${fixCatalog ? ' fix-catalog' : ''}`}>
                        <div className="catalog-title">目录</div>
                        <div className="catalog-box">
                            {
                                Array.isArray(entryInfo.content) && entryInfo.content.map((item, index) => (
                                  <div className={`item-name${activeIndex === index ? ' active' : ''}`} onClick={() => {domEvents.changeActiveTag(index)}}>{item.title}</div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Detail

