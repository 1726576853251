import React, {useEffect, useRef} from "react";
import {LeftOutlined} from "@ant-design/icons";
import {Breadcrumb, Space, Divider, message} from "antd";
import Player from 'xgplayer';
import articleVieoMap from './data/articleVieoMap'; // 文章对应的视频信息
import ArticleModel from "../../models/article/index";
import {decodeContent} from "../../untils/commHelper";
import dayjs from "dayjs";
import util from "../../untils/util";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/article/index.css";
import "../../static/css/pages/article/quill.snow.css";
import './index.scss';

function Index(props) {
  const videoContainRef = useRef();
  const {article, getArticle} = ArticleModel();
  const {id} = props.match.params || {}

  useEffect(() => {
    getArticle(id);
    document.documentElement.scrollTop = 0;

    let titleStr = "文章详情页";
    if (id == "684635") {
      titleStr = "健康中国2030";
    } else if (id == "684636") {
      titleStr = "健康中国行动";
    }
    if (articleVieoMap[id]) {
      const {poster, url} = articleVieoMap[id];
      new Player({
        el: videoContainRef.current,
        poster,
        url,
        width: '780px',
        height: '440px'
      });
    }
  }, []);

  function downloadFile({store_key, vid, name, url,}) {
    if (!store_key && !vid && !url) {
      message.warning('资源不存在');
      return;
    }
    const {data, key} = util.encrypt(vid, name);
    util.downFile({
      store_key, vid, file_name: name, file_url: url, query: {data, key},
    });
  }

  // 整理新旧附件
  const { enclosures = [], tos_enclosures = [] } = article;
  let showEnclosures = [];
  // 有新用新附件
  if (tos_enclosures && Array.isArray(tos_enclosures)) {
    showEnclosures = [...tos_enclosures];
  } else if (enclosures && Array.isArray(enclosures)) {
    showEnclosures = [...enclosures];
  }

  return (
    <div className="article-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item
            className="gopre"
            onClick={() => {
              window.history.go(-1);
            }}
          >
            <Space>
              <LeftOutlined/>
              返回上一页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{article.title}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="article-content ">
        <div className="title">{article.title}</div>
        {/* <div className="head">
          <div>
            <CalendarOutlined /> {article.public_date}
          </div>
        </div> */}
        <div className="head-info">
          <Space size={32}>
            {article.public_date && (
              <span>
                {dayjs(article.public_date).locale("zh-cn").format("YYYY") +
                "年" +
                dayjs(article.public_date).locale("zh-cn").format("MM") +
                "月" +
                dayjs(article.public_date).locale("zh-cn").format("DD") +
                "日"}
              </span>
            )}
            {article.author_info && (
              <span>
                来源：
                {article.author_info}
              </span>
            )}
          </Space>
        </div>
        <Divider/>
        {
          articleVieoMap[id] && (
            <div className="video-player-wrap">
              <div className="video-player-container" ref={videoContainRef}></div>
            </div>
          )
        }
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: decodeContent(article.content),
          }}
        ></div>
        <div className="fileBox">
          {
            Array.isArray(showEnclosures) && showEnclosures.map((item, index) => (
              <div className="fileLi clearfix" key={index} onClick={() => downloadFile(item)}>
                <div className="fileName fl">{item.name}</div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Index;
