import React, { useState, useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Space, Pagination, Result, Button } from "antd";
import { cutStr } from "../../untils/commHelper";
import ListModel from "../../models/article/list";
import Recommend from "../../components/Recommend";
import { useCookies } from "react-cookie";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/health.css";
function Index(props) {
  const {
    majorList,
    totalCount,
    list,
    reclist,
    search,
    getRecommend,
  } = ListModel();

  const [cookies, setCookie] = useCookies();
  const [page, setPage] = useState(() => {
    let num = cookies.list_page;
    if (num) {
      return parseInt(num);
    }
    return 1;
  });
  const [pagesize, setPageSize] = useState(() => {
    let num = 20;
    return num;
  });
  useEffect(() => {
    let cate = props.match.params.cate;
    search(page, 20, cate);
    getRecommend("health_life", cate, page);

    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: majorList[cate - 1],
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);
  return (
    <div className="health-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {majorList[props.match.params.cate - 1]}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="local-content">
        <div className="local-content-articles">
          {list.map((item, index) => {
            return (
              <div
                onClick={() => {
                  let goUrl = "/article/" + item.id;
                  props.history.push(goUrl);
                }}
                key={"loc_" + index}
                className="local-content-articles-item"
              >
                <Space className="lc-title">
                  <div className="comm-blue-dot"></div>
                  <span>{cutStr(item.title, 80)}</span>
                </Space>
                <span style={{ color: "#666666" }}>{item.public_date}</span>
              </div>
            );
          })}

          {totalCount <= 0 && (
            <Result
              title="当前频道尚无内容"
              extra={
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    props.history.push("/");
                  }}
                >
                  返回首页
                </Button>
              }
            />
          )}
        </div>
        {totalCount > 0 && (
          <div className="comm-pages">
            <Pagination
              current={page}
              total={totalCount}
              pageSizeOptions={[20]}
              onChange={(page, pageSize) => {
                if (pagesize != pageSize) {
                  setPage(1);
                  setCookie("list_page", 1 + "", { maxAge: 600 });
                  setPageSize(pageSize);
                } else {
                  setPage(page);
                  //保存记录
                  setCookie("list_page", page + "", { maxAge: 600 });
                }
                search(page, pageSize, props.match.params.cate);
                getRecommend("health_life", 0, page);
              }}
              defaultPageSize="20"
            />
          </div>
        )}

        <div className="rec-part">
          <Recommend params={reclist} title="相关推荐" />
        </div>
      </div>
    </div>
  );
}

export default Index;
