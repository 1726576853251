import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../untils/requestHelper";
import {message} from "antd";

function useCase() {
    const [content, setContent] = useState();
    const [title, setTitle] = useState();

    const getContent = async (id) => {
        const ret = await reqestApi(`/api/exchangeplat/info/${id}`, "GET");
        if(ret.data && ret.data.code === 0){
            setContent(ret.data.data.content);
            setTitle(ret.data.data.title);
            return true;
        }else{
            message.error("获取信息失败！");
            return false
        }
    }

    const getPreview = async (id, sign) => {
        const params = {
            sign
        }
        const ret = await reqestApi(`/api/exchangeplat/preview/${id}`, "GET", params);
        if(ret.data && ret.data.code === 0){
            setContent(ret.data.data.content);
            setTitle(ret.data.data.title);
            return ret.data.data.exchange_year === "2020"
        }else{
            message.error("获取信息失败！");
            return false
        }
    }

    return{
        content,
        title,
        getContent,
        getPreview
    }
}

export default createModel(useCase)