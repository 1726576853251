import CryptoJS from "crypto-js";

function aseEncrypt(msg, key) {
    key = PaddingLeft(key, 16); //保证key的长度为16byte,进行'0'补位
    key = CryptoJS.enc.Utf8.parse(key);
    var encrypted = CryptoJS.AES.encrypt(msg, key, {
        iv: key,
        mode: CryptoJS.mode.CBC, // CBC算法
        padding: CryptoJS.pad.Pkcs7, //使用pkcs7 进行padding 后端需要注意
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex); //后端必须进行相反操作
}
export const getToken = () => {
    let timestamp = Math.round(new Date().getTime() / 1000);
    let msg = timestamp + ";e22674779c464244";
    return aseEncrypt(msg, "186133958051");
};
function PaddingLeft(key, length) {
    let pkey = key.toString();
    let l = pkey.length;
    if (l < length) {
        pkey = new Array(length - l + 1).join("0") + pkey;
    } else if (l > length) {
        pkey = pkey.slice(length);
    }
    return pkey;
}