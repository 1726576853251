import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { Url } from "./url";

class Util extends Url{
  encrypt(vid = '', name = '') {
    let key = this.randomString(16);
    const data = `vid=${vid}&file_name=${name}`;
    const encryptor = new JSEncrypt();
    const pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDkWpAkhRRHEI4pFb4FiDSq3AH7Hvif2Br5BE7MOsGLrQOI3lIes7c+VdAnA88ZNRBRiK2SWFjSrYgvgjvm/+5w4uhS471Sh8lJYN2fBp35q7l0KmN0mmuVt6DTZllx7QcOiReAetIFZDv7VXbfriFuh4Qdd7KccZuIatkw+pLhAQIDAQAB';
    const aesData = this.encryptAES(data, key);
    encryptor.setPublicKey(pubKey);
    key = encryptor.encrypt(key);
    return {
      data: aesData,
      key,
    };
  }

  // 加密方法
  encryptAES(word, key) {
    key = this.paddingLeft(key, 16); // 保证key的长度为16byte,进行'0'补位
    key = CryptoJS.enc.Utf8.parse(key);
    // 加密结果返回的是CipherParams object类型
    // key 和 iv 使用同一个值
    const encrypted = CryptoJS.AES.encrypt(word, key, {
      iv: key,
      mode: CryptoJS.mode.CBC, // CBC算法
      padding: CryptoJS.pad.Pkcs7, // 使用pkcs7 进行padding 后端需要注意
    });
    // ciphertext是密文,toString()内传编码格式,比如Base64,这里用了16进制
    // 如果密文要放在 url的参数中 建议进行 base64-url-encoding 和 hex encoding, 不建议使用base64 encoding
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex); // 后端必须进行相反操作
  }

  paddingLeft(key, length) {
    let pkey = key.toString();
    const l = pkey.length;
    if (l < length) {
      pkey = new Array(length - l + 1).join('0') + pkey;
    } else if (l > length) {
      pkey = pkey.slice(length);
    }
    return pkey;
  }

  // 随机生成字符串
  randomString(e) {
    let n = '';
    if (typeof e !== 'number') {
      return n;
    }
    const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789'.split('');
    for (let i = 0; i < e; i++) {
      n += t[Math.floor(Math.random() * t.length)];
    }
    return n;
  }

  // 文件下载
  downFile({
             store_key = '', // tos的storeKey
             file_name = '', // 文件名称
             path = '', // 接口路径
             query, // 请求查询参数
             vid = '', // 资源的vid (通过tt-uploader上传的文件有vid)
             file_url = '', // 文件的存储地址
           }) {
    path = path || '/upload/file_download';
    const qString = this.toQueryString({
      ...query,
      store_key,
      file_name,
      vid,
      // token: Token.getToken(),
    });
    let downHref = file_url;
    // 如果有地址，直接下载  由于通过vod和tos的方式上传的附件会同时存在，所以当有地址时（tos）直接下载
    if (downHref) {
      this.downFileByAtag(downHref);
      return;
    }
    if (vid) {
      path = '/upload/file_download_by_vid';
      // this.downVideo(downHref, file_name);
      downHref = `https://api.jkzg2030.cn${path}?${qString}`;
    } else if (store_key) {
      // downHref = `${Conf.baseAPI}${path}?${qString}`;
    }
    this.downFileByAtag(downHref);
  }

  downFileByAtag(href, name) {
    const a = document.createElement('a'); // 创建a标签
    a.setAttribute('download', name || '');// download属性
    a.setAttribute('href', href);// href链接
    a.setAttribute('target', 'blank');// href链接
    a.click();// 自执行点击事件
  }
}

export default new Util()