import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useList() {
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [cate, setCate] = useState();
  const [reclist, setReList] = useState([]);

  const majorList = [
    "健康知识普及行动",
    "合理膳食行动",
    "全民健身行动",
    "控烟行动",
    "心理健康促进行动",
    "健康环境行动",
    "妇幼健康促进行动",
    "中小学健康促进行动",
    "职业健康保护行动",
    "老年健康促进行动",
    "心脑血管疾病防治行动",
    "癌症防治行动",
    "慢性呼吸系统疾病防治行动",
    "糖尿病防治行动",
    "传染病及地方病防控行动",
  ];
  const search = async (page, limit, cateNum) => {
    let params = {};
    params.page = page;
    params.limit = limit;
    params.category_two = cateNum;
    let ret = await reqestApi("/api/health_life", "GET", params);
    if (ret.data.code == 0) {
      console.log("RET:", ret);
      setList(ret.data.data);
      setTotalCount(ret.data.totalCount);
    }
  };
  const getRecommend = async (cate, sub_cate, page) => {
    let params = {};
    params.cate = cate;
    if (sub_cate > 0) {
      params.sub_cate = sub_cate;
    }

    params.page = page;
    let ret = await reqestApi("/api/recommand", "GET", params);
    if (ret.data.code == 0) {
      setReList(ret.data.data);
    }
  };

  return { majorList, totalCount, list, reclist, search, getRecommend };
}

export default createModel(useList);
