import React, {useEffect, useState} from "react";
import {Breadcrumb, Pagination, Space, Spin} from "antd";
import {LeftOutlined} from "@ant-design/icons";

import play from "../../static/images/play.png"
import "./index.scss"
import useVideo from "../../models/expertVideo";
import { TagList } from "../../components/TagList"

function Index(props) {
    const PAGESIZE = 16;

    const {
        listLoading,
        resList,
        cate,
        tabs,
        setCate,
        getVideoToken,
        total,
    } = useVideo()

    const domEvents = {
        toDetail: (id) => {
            props.history.push({
                pathname: "/expertdetail",
                state: {
                    id,
                }
            })
        }
    }

    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(1);
        getVideoToken(1, PAGESIZE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cate]);

    // useEffect(() => {
    //     if(resList.length === expertsInfo.length){
    //         const res = [];
    //         resList.forEach((video, index) => {
    //             const temp = parseInt(index / PAGESIZE);
    //             if (!res[temp]) {
    //                 res.push([]);
    //             }
    //             res[temp].push(video);
    //         })
    //         setList(res)
    //     }
    // }, [resList])

    return (
        <div className="expert-container">
            <Breadcrumb>
                <Breadcrumb.Item href="/encyclopedia">
                    <Space>
                        <LeftOutlined/>
                        返回上一页
                    </Space>
                </Breadcrumb.Item>
            </Breadcrumb>
            <TagList data={tabs} current={cate} onSelect={item => {
                if (!listLoading) setCate(item.cate);
            }} />
            <Spin spinning={!resList.length}>
                <div className="videos-list">
                    {
                        resList.length > 0 && resList.map((video, index) => {
                            return (
                                <div className="video" key={index} onClick={() => {domEvents.toDetail(video.id, video)}}>
                                    <div className="background">
                                        <img className="cover-img" src={video.img_url} alt=""/>
                                        <img className="icon" src={play} alt=""/>
                                    </div>
                                    <div className="title">{video.title}</div>
                                    <div className="info">{video.article_source}</div>
                                    <div className="time">{video.public_date}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </Spin>
            <div>
                <Pagination
                    current={page}
                    total={total}
                    onChange={(page) => {
                        getVideoToken(page, PAGESIZE);
                        setPage(page)
                    }}
                    defaultPageSize={PAGESIZE}
                />
            </div>
        </div>
    )
}

export default Index