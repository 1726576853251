import React, { useEffect, useState, useRef } from "react";
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Input, Button, Table, message, Tooltip } from "antd";

import BannerImg from "../../static/images/interneBannerText.png"
import Banner from '../../static/images/banner.png'
import SjImg from '../../static/images/sj.png'
import DetailIcon1 from '../../static/images/icon1.png'
import Empty from '../../static/images/empty.png'
import "../../static/css/pages/internethopital.css";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/local.css";
import { Modal } from 'antd';
import HospitalModel from "../../models/internethospital";
import { getProvinces } from "../../untils/citydata";
import OfflinePage from "../components/offline";

function Index(props) {
    const columns = [
        {
            title: '医院名称',
            dataIndex: 'hospital_name',
            width: 128
        },
        {
            title: '首次申请时间',
            dataIndex: 'commit_time',
            render: (time) => {
                if (time) {
                    return time.split(" ")[0];
                } else {
                    return ""
                }
            }
        },
        {
            title: '是否已提交',
            dataIndex: 'check_status',
            render: (item) => item !== 9 ? <div>是</div> : <div>否</div>
        },
        {
            title: '状态',
            dataIndex: 'check_status',
            render: (val, record) => {
                switch (val) {
                    case 1:
                        return <div>待审核</div>;
                    case 4:
                        return <div>审核通过</div>;
                    case 3:
                        return <div style={{
                            position: "relative"
                        }}>
                            驳回
                            <Tooltip placement="topRight" title={<div style={{
                                maxWidth: 180
                            }}>{record.fail_reason}</div>}>
                                <InfoCircleOutlined className="warn-icon" style={{
                                    position: "relative",
                                    top: -4,
                                    right: -5
                                }} />
                            </Tooltip>
                        </div>
                    case 6:
                        return <div>下线</div>
                    default:
                        return <div>--</div>
                }
            }
        },
    ]

    const {
        searchData,
        setSearchData,
        internetList,
        setInternetList,
        getInternetList,
        hopitalDetail,
        setHospitalDeatil,
        getHospitalDetail,
        sendMessage,
        commit,
        getList,
        hospitalList,
        getInfo
    } = HospitalModel();

    const [visible, changevisible] = useState(false)
    const [chooseModal, setChooseModal] = useState(false);
    const [phoneModal, setPhoneModal] = useState(false);
    const [listModal, setListModal] = useState(false);
    const [sendCount, setSendCount] = useState(0); //发送短信倒计时

    const isFirst = useRef(true);
    const formRef = useRef();
    const currentSelected = useRef(0) //当前选中的key
    const timer = useRef();

    const domEvents = {
        sendMessage: async () => {
            const phone_num = formRef.current.getFieldValue("phoneNum");
            if (!phone_num) {
                message.error("请填写手机号！")
            } else {
                if (await sendMessage({ phone_num })) {
                    setSendCount(60)
                    timer.current = setInterval(() => {
                        setSendCount(count => {
                            if (count - 1 === 0) {
                                clearInterval(timer.current);
                            }
                            return count - 1;
                        })
                    }, 1000)
                }
            }
        },
        commitForm: async () => {
            const phone_num = formRef.current.getFieldValue("phoneNum");
            const vcode = formRef.current.getFieldValue("vcode");
            if (!phone_num || !vcode) {
                message.error("手机号和验证码不能为空！");
            } else {
                if (await commit({ phone_num, vcode })) {
                    sessionStorage.setItem("phone_num", phone_num);
                    if (isFirst.current) {
                        setPhoneModal(false);
                        props.history.push("/infostep");
                    } else {
                        const listNum = await getList({ phone_num });
                        if (listNum !== false) {
                            const validList = listNum.filter(item => {
                                return item.check_status === 9 || item.check_status === 3 || item.check_status === 6
                            })
                            if (validList.length === 1) {
                                const info = await getInfo(validList[0].id);
                                if (info) {
                                    props.history.push({
                                        pathname: "/infostep",
                                        state: {
                                            info: JSON.stringify(info),
                                        }
                                    })
                                }
                            } else {
                                setListModal(true)
                            }
                        }
                    }
                } else {
                    message.error("提交失败")
                }
            }
        }
    }

    useEffect(() => {
        // let data = JSON.parse(JSON.stringify(searchData));
        // data[0].sublist[0] = [];
        // data[0].sublist[0] = { label: "全国", value: 0 };
        // data[0].sublist.push(...getProvinces());
        // setSearchData(data);
        // getInternetList()
        // window.TEA("jkzgxd_pageview", {
        //     source: 'pc',
        //     action: 'pageview',
        // });
    }, []);
    const handleCancel = () => {
        changevisible(false)
    }
    const updateSearch = (name, key) => {
        /**
         * name 0地区 1行动
         * key当前选中id
         */
        let searchdata = JSON.parse(JSON.stringify(searchData));
        searchdata[name].value = key;
        setSearchData(searchdata);
        let params = {
            province: searchdata[0].value,
            qualification: searchdata[1].value
        }
        getInternetList(params)
    };
  
    return <OfflinePage />
  
    return (
        <div className="hospital-card">
            <Modal
                title={hopitalDetail.hospital_name}
                visible={visible}
                onCancel={handleCancel}
                footer={false}
                width={764}
            >
                <div className="model-card">
                    <div className="hopital-detail-title">医院简介</div>
                    <div className="hopital-text">{hopitalDetail.hospital_info}</div>
                    <div className="hopital-detail-title">院区/地址</div>
                    {
                        hopitalDetail.hospital_addr && hopitalDetail.hospital_addr.split(",").map((item, index) => {
                            return <div className="hopital-text" key={index}>{item}</div>
                        })
                    }
                    <div className="hopital-detail-title">一键扫码，立即就医</div>
                    <div className="model-bottom">
                        <div className="ewm"><img className="ewm" src={hopitalDetail.qrcode_img} alt="二维码" /></div>
                        <div className="hospital-tag">
                            {
                                hopitalDetail.capability.split(",").map((item, index) => {
                                    return <div key={index} style={{ display: 'flex' }}>
                                        <div><img style={{ marginRight: '10px' }} src={DetailIcon1} alt="" /></div>
                                        <div> {item}</div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                title="请选择"
                visible={chooseModal}
                onCancel={() => {
                    setChooseModal(false)
                }}
                footer={false}
                width={360}
                className="small-modal choose-modal"
            >
                <div className="choose-container" onClick={() => {
                    isFirst.current = true;
                    setChooseModal(false);
                    const phone_num = sessionStorage.getItem("phone_num");
                    if (phone_num) {
                        props.history.push("/infostep");
                    } else {
                        setPhoneModal(true)
                    }
                }}>
                    首次申请入驻
                    <RightOutlined />
                </div>
                <div className="choose-container" onClick={async () => {
                    isFirst.current = false;
                    setChooseModal(false);
                    const phone_num = sessionStorage.getItem("phone_num");
                    if (phone_num) {
                        const res = await getList({ phone_num });
                        // console.log(res);
                        if (res !== false) {
                            const validList = res.filter(item => {
                                return item.check_status === 9 || item.check_status === 3 || item.check_status === 6
                            })
                            if (validList.length === 1) {
                                const info = await getInfo(validList[0].id);
                                if (info) {
                                    props.history.push({
                                        pathname: "/infostep",
                                        state: {
                                            info: JSON.stringify(info),
                                        }
                                    })
                                }
                            } else {
                                setListModal(true)
                            }
                        }
                    } else {
                        setPhoneModal(true)
                    }
                }}>
                    已填写医院信息
                    <RightOutlined />
                </div>
            </Modal>
            <Modal
                title="身份验证"
                visible={phoneModal}
                onCancel={() => {
                    if (timer.current) {
                        setSendCount(0);
                        clearInterval(timer.current)
                    }
                    setPhoneModal(false)
                }}
                footer={false}
                width={385}
                className="small-modal phone-modal"
            >
                <Form
                    name="basic"
                    ref={formRef}
                    onFinish={() => { }}
                >
                    <Form.Item
                        label="手机号"
                        name="phoneNum"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="输入验证码"
                        name="vcode"
                    >
                        <Input />
                    </Form.Item>
                </Form>
                {sendCount === 0 ? <div className="send-btn" onClick={() => {
                    domEvents.sendMessage()
                }}>发送验证码</div> : <div className="send-btn count">
                    重新发送（{sendCount}s）
                </div>}
                <div className="btn-container">
                    <Button type="primary" onClick={() => {
                        domEvents.commitForm();
                    }}>确定</Button>
                    <Button onClick={() => {
                        setPhoneModal(false)
                    }}>取消</Button>
                </div>
            </Modal>
            <Modal
                title="请选择您本次申请入驻的医院"
                visible={listModal}
                onCancel={() => {
                    setListModal(false)
                }}
                footer={false}
                width={640}
                className="small-modal list-modal"
            >
                <Table
                    rowSelection={{
                        type: 'radio',
                        onChange: (selectedRowKeys) => {
                            currentSelected.current = selectedRowKeys;
                        },
                        getCheckboxProps: (record) => ({
                            disabled: record.check_status !== 9 && record.check_status !== 3 && record.check_status !== 6, // Column configuration not to be checked
                        }),
                    }}
                    columns={columns}
                    dataSource={hospitalList}
                    pagination={false}
                />
                <div className="check-in-btn" onClick={() => {
                    props.history.push("/infostep")
                }}>
                    新医院入驻
                    <RightOutlined />
                </div>
                <div className="btn-container">
                    <Button type="primary" onClick={async () => {
                        if (!currentSelected.current) {
                            message.warn("请选择一个医院！");
                            return
                        }
                        setListModal(false);
                        const info = await getInfo(currentSelected.current);
                        if (info) {
                            props.history.push({
                                pathname: "/infostep",
                                state: {
                                    info: JSON.stringify(info),
                                }
                            })
                        }
                    }}>确定</Button>
                    <Button onClick={() => {
                        setListModal(false)
                    }}>取消</Button>
                </div>
            </Modal>
            <div className="internet-hospital-box">
                <img src={Banner} className="internethopital-banner" alt="" />
                <div className="top-bar">
                    <img className="banner-img" src={BannerImg} alt="" />
                    <div className="settle-btn" onClick={() => {
                        setChooseModal(true)
                    }}>入驻报名<RightOutlined /></div>
                </div>
                <div style={{ width: '1060px', margin: '0 auto' }}>
                    <div style={{ marginBottom: '20px' }}>
                        {searchData.map((item, index) => {
                            return <div className="search-data">
                                <div style={{ marginRight: '10px', width: '40px' }}> {item.title}:</div>
                                <div style={{ width: '1005px', cursor: 'pointer' }}>
                                    {item.sublist.map((item0, index0) => {
                                        return (
                                            <div
                                                key={index0}
                                                onClick={() => {
                                                    updateSearch(item.name, item0.value);
                                                    // getRecommend("local_dynamic", 0, page);
                                                }}
                                                className={`local-serach-second-item ${item.value == item0.value
                                                        ? "local-serach-light-item"
                                                        : "local-serach-second-item"
                                                    }`}
                                            >
                                                {item0.label}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                        })}
                        <div>
                        </div>
                    </div>
                    <div className="list-box">
                        {internetList.length === 0 ?
                            <div style={{ textAlign: 'center' }}>
                                <img style={{ height: '200px' }} src={Empty} alt="" />
                                <div style={{ color: '#666' }}>暂无相关信息</div>
                            </div> :
                            <div className="hospital-list">{
                                internetList.map((item, index) => {
                                    return <div key={index} className="hospital-item" onClick={() => {
                                        // window.TEA("jkzgxd_pageview", {
                                        //     source: 'pc',
                                        //     action: 'hopital_click',
                                        //     hospital_name: item.hospital_name
                                        // });
                                        getHospitalDetail(item.id)
                                        changevisible(true)
                                    }}>
                                        <div className="hospital-item-card">
                                            {/* item.logo */}
                                            <div className="hospital-img"><img className="hospital-img" src={item.logo}
                                                alt="logo" /></div>
                                            <div>
                                                <div className="hospital-title">{item.hospital_name}</div>
                                                <div>
                                                    <span className="hospital-span"><img className="hospital-span-sj"
                                                        src={SjImg}
                                                        alt="" />{item.hospital_rank}</span>
                                                    <span
                                                        className="hospital-span">国家临床重点专科{item.core_subject_num}个</span>
                                                </div>
                                                <div className="hospital-content">特色科室：{item.departments}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }</div>
                        }
                    </div>
                </div>
            </div>
            <div className="footer">欢迎加入平台！&nbsp;&nbsp; 反馈邮箱13810002617@139.com &nbsp;&nbsp;&nbsp;&nbsp;技术提供方：小荷健康</div>
        </div>
    );
}

export default Index;
