import React from "react";
import { LeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { Breadcrumb, Space } from "antd";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/action.css";

function Action(props) {
  return (
    <div className="action-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="action-content">
        <div
          className="file-card"
          onClick={() => {
            let goUrl = "/article/684636";
            props.history.push(goUrl);
          }}
        >
          <img
            className="file-card-head"
            src={require("../../static/images/file-02.png")}
          />
          <div className="file-card-content">
            <div className="title">国务院关于实施健康中国行动的意见</div>
            <div className="content">
              国务院关于实施健康中国行动的意见 <br />
              国发〔2019〕13号 <br />
              各省、自治区、直辖市人民政府，国务院各部委、各直属机构:
              <br />
              人民健康是民族昌盛和国家富强的重要标志，预防是最经济最有效的健康策略。党中央、国务院发布《“健康中国2030”规划纲要》，提出了健康中国建设的目标和任务。党的十九大作出实施健康中国战略的重大决策部署，强调坚持预防为主，倡导健康文明生活方式，预防控制重大疾病。
            </div>
          </div>
          <div className="file-card-footer">
            <span>查看更多</span>
            <DoubleRightOutlined />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Action;
