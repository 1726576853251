import {createModel} from "hox";
import {reqestApi} from "../untils/requestHelper";
import Service from "xgplayer-service";

function useVideo() {
  const getDetail = async (id) => {
    const videoRes = await reqestApi(`api/video/token?vid=${id}`, "GET");
    if (videoRes.data.code !== 0) {
      return;
    }
    const videoInfo = await Service.url(videoRes.data.data.token, '//vod.volcengineapi.com');
    return videoInfo && Array.isArray(videoInfo.PlayInfoList) && videoInfo.PlayInfoList[0] && videoInfo.PlayInfoList[0].MainPlayUrl
  }

  return {
    getDetail,
  }
}

export default createModel(useVideo)