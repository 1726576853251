import "mutation-observer";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import vemars from '@apm-insight-web/rangers-site-sdk';
import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./router/index";
import "./polyfill";
import "antd/dist/antd.css";
// import Tea from "byted-tea-sdk";
import { Env } from "./conf/app";

// window.TEA = Tea;
// Tea.init({
//   app_id: 4382,
//   channel: "cn",
//   log: true,
// });
// Tea.config({
//   _staging_flag: isDebug(), // 是否发到测试库 1、是；0否
//   disable_auto_pv: true, // 关闭默认事件
// });
// Tea.start();

// 火山云性能监控
try {
  Env.isProd && vemars('config', {
      app_id: '291265',
  });
  window.vemars = vemars;
  console.log('火山云性能监控开启');
} catch (error) {
  console.error('火山云性能监控错误：', error);
}


ReactDOM.render(
  <div>
    <AppRouter />
  </div>,
  document.getElementById("root")
);
