import React, { useState } from "react"
import { createModel } from "hox"
import { reqestApi } from "../untils/requestHelper"
import { message } from "antd"

function useHome() {
  /**轮播图 */
  const [carousels, setCarousel] = useState([])
  /**专网腰封 */
  const [beltInfo, setBeltInfo] = useState({})
  /**重大行动分类 */
  const [majorAction, setMajorAction] = useState({
    key: "",
    value: [],
    page: 0,
    next: 1,
    pre: 0,
  })
  const [activeCarousel, setActiveCarousel] = useState(0);
  /**地方动态 */
  const [localList, setLocalList] = useState([])
  const majorList = [
    {
      title: "健康知识普及行动",
      icon: require("../static/images/ac-01.png"),
      uid: "684279",
    },
    {
      title: "合理膳食行动",
      icon: require("../static/images/ac-02.png"),
      uid: "684280",
    },
    {
      title: "全民健身行动",
      icon: require("../static/images/ac-03.png"),
      uid: "684281",
    },
    {
      title: "控烟行动",
      icon: require("../static/images/ac-06.png"),
      uid: "684282",
    },
    {
      title: "心理健康促进行动",
      icon: require("../static/images/ac-04.png"),
      uid: "684283",
    },
    {
      title: "健康环境促进行动",
      icon: require("../static/images/ac-05.png"),
      uid: "684284",
    },

    {
      title: "妇幼健康促进行动",
      icon: require("../static/images/ac-07.png"),
      uid: "684285",
    },
    {
      title: "中小学健康促进行动",
      icon: require("../static/images/ac-08.png"),
      uid: "684286",
    },
    {
      title: "职业健康保护行动",
      icon: require("../static/images/ac-09.png"),
      uid: "684287",
    },
    {
      title: "老年健康促进行动",
      icon: require("../static/images/ac-10.png"),
      uid: "684288",
    },
    {
      title: "心脑血管疾病防治行动",
      icon: require("../static/images/ac-11.png"),
      uid: "684289",
    },
    {
      title: "癌症防治行动",
      icon: require("../static/images/ac-12.png"),
      uid: "684290",
    },
    {
      title: "慢性呼吸系统疾病防治行动",
      icon: require("../static/images/ac-13.png"),
      uid: "684291",
    },
    {
      title: "糖尿病防治行动",
      icon: require("../static/images/ac-14.png"),
      uid: "684292",
    },
    {
      title: "传染病及地方病防控行动",
      icon: require("../static/images/ac-15.png"),
      uid: "684293",
    },
  ]

  const actionsData = {
    healthLife: [
      [
        {
          title: "健康知识普及",
          imgSrc: require("../static/images/action/jk-01.jpg"),
          actionId: "1",
        },
        {
          title: "合理膳食",
          imgSrc: require("../static/images/action/jk-02.jpg"),
          actionId: "2",
        },
        {
          title: "全民健身",
          imgSrc: require("../static/images/action/jk-03.jpg"),
          actionId: "3",
        },
        {
          title: "控烟",
          imgSrc: require("../static/images/action/jk-04.jpg"),
          actionId: "4",
        },
      ],
      [
        {
          title: "心理健康促进",
          imgSrc: require("../static/images/action/jk-05.jpg"),

          actionId: "5",
        },
        {
          title: "健康环境促进",
          imgSrc: require("../static/images/action/jk-06.jpg"),
          actionId: "6",
        },
      ],
    ],
    keyGroups: [
      [
        {
          title: "妇幼健康促进",
          imgSrc: require("../static/images/action/rq-01.jpg"),
          actionId: "7",
        },
        {
          title: "中小学健康促进",
          imgSrc: require("../static/images/action/rq-02.jpg"),
          actionId: "8",
        },
        {
          title: "职业健康保护",
          imgSrc: require("../static/images/action/rq-03.jpg"),
          actionId: "9",
        },
        {
          title: "老年健康促进",
          imgSrc: require("../static/images/action/rq-04.jpg"),
          actionId: "10",
        },
      ],
      [],
    ],
    disease: [
      [
        {
          title: "心脑血管疾病防治",
          imgSrc: require("../static/images/action/jb-01.jpg"),
          actionId: "11",
        },
        {
          title: "癌症防治",
          imgSrc: require("../static/images/action/jb-02.jpg"),
          actionId: "12",
        },
        {
          title: "慢性呼吸系统疾病防治",
          imgSrc: require("../static/images/action/jb-03.jpg"),
          actionId: "13",
        },
        {
          title: "糖尿病防治",
          imgSrc: require("../static/images/action/jb-04.jpg"),
          actionId: "14",
        },
      ],
      [
        {
          title: "传染病及地方病防控",
          imgSrc: require("../static/images/action/jb-05.jpg"),
          actionId: "15",
        },
      ],
    ],
  }
  const init = async () => {
    /**轮播图 */
    let ret = await reqestApi("/api/index/carousel", "GET",{ channel:'pc'})
    if (ret.data.code == 0) {
      setCarousel(ret.data.data)
    }
    /**健康生活 */
    let tmpMode = {
      key: "healthLife",
      value: actionsData.healthLife[0],
      page: 0,
      next: 1,
      pre: 0,
    }
    setMajorAction(tmpMode)
    /**专网腰封 */
    const resGirdle = await reqestApi(`/api/index/girdle`, "GET", { scene: 4 });
    // console.log('getGirdle', resGirdle);
    if(resGirdle.data && resGirdle.data.code === 0){
        setBeltInfo(resGirdle.data.data);
    } 
    /**地方动态 */
    let retLocal = await reqestApi("/api/index/localdynamic", "GET", {})
    if (retLocal.data.code == 0) {
      setLocalList(retLocal.data.data)
    }
  }

  const onChangeAction = async (type, page) => {
    let arr = actionsData[type][page]
    let tmpMode = { key: type, value: arr, page: page }
    if (page == 1) {
      tmpMode.next = 0
      tmpMode.pre = actionsData[type][page - 1].length
    } else if (page == 0) {
      tmpMode.next = actionsData[type][page + 1].length
      tmpMode.pre = 0
    } else {
      tmpMode.next = 0
      tmpMode.pre = 0
    }
    setMajorAction(tmpMode)
  }
  const onNextAction = async (type, page) => {
    let arr = actionsData[type][page]
    let tmpMode = { key: type, value: arr, page: page }
    if (page == 1) {
      tmpMode.next = 0
      tmpMode.pre = actionsData[type][page - 1].length
    } else if (page == 0) {
      tmpMode.next = actionsData[type][page + 1].length
      tmpMode.pre = 0
    } else {
      tmpMode.next = 0
      tmpMode.pre = 0
    }
    setMajorAction(tmpMode)
  }
  return {
    carousels,
    beltInfo,
    majorAction,
    majorList,
    localList,
    activeCarousel,
    setActiveCarousel,
    onChangeAction,
    onNextAction,
    init,
  }
}

export default createModel(useHome)
