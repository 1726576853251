import React, { useEffect } from "react";
import { Spin } from "antd";
import { RightOutlined } from '@ant-design/icons';

import bannerImg from "../../static/images/encyclopedia_banner.png"
import "../../static/css/pages/encyclopedia.scss";
import { departments } from "./data";
import modal from "../../models/encyclopedia";
import play from "../../static/images/play.png"
import { TagList } from "../../components/TagList"
import useVideo from "../../models/expertVideo"

function Index(props) {
    const {
        articleList,
        getArticleList,
    } = modal()
    const {
        listLoading,
        cate,
        tabs,
        resList,
        setCate,
        getVideoToken,
    } = useVideo()

    const domEvents = {
        toHealth: () => {
            props.history.push("/health");
        },
        toArticle: (id) => {
            props.history.push(`/article/${id}`)
        },
        toExpertList: () => {
            props.history.push('/expert')
        },
        toExpertDetail: (id) => {
            props.history.push({
                pathname: "/expertdetail",
                state: {
                    id,
                }
            })
        },
        toDepartment: (id) => {
            props.history.push({
                pathname: "/entry",
                state: {
                    id
                }
            })
        },
        toDisease: (id) => {
            // props.history.push(`/entrydetail/${id}`)
            window.open(`/entrydetail/${id}`, '_blank');
        }
    }

    useEffect(() => {
        getArticleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getVideoToken(1, 8);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cate])

    return (
        <div className="encyclopedia">
            <div className="banner" style={{
                backgroundImage: `url(${bannerImg})`
            }}>
                <div className="container">
                    <p className="title">健康百科</p>
                    <p className="subtitle">权威生动·实用便捷</p>
                    <p className="subtitle">让每个人成为自身健康的第一责任人</p>
                    <div className="tag">
                        健康中国行动·健康百科传播项目
                    </div>
                </div>
            </div>
            <div className="department">
                <div className="container">
                    {
                        departments.map((item, index1) => {
                            return (
                                <div key={index1} className="room" onClick={() => {domEvents.toDepartment(item.id)}}>
                                    <img src={item.logo} alt=""/>
                                    <div className="box">
                                        <div className="title">{item.title}</div>
                                        <div className="content">
                                            {
                                                item.row.map((disease, index2) => {
                                                    return (
                                                        <span onClick={(e) => {e.stopPropagation();domEvents.toDisease(disease.id)}} key={index2}>{disease.label}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="main-content">
                <div className="content-box">
                    <div className="header">
                        <p className="title">
                            <span>健康视频</span>
                            {/*<span className="tag">国家健康科普专家系列直播活动</span>*/}
                        </p>
                        <span className="more" onClick={() => {
                            domEvents.toExpertList()
                        }}>
                        查看更多
                        <RightOutlined />
                    </span>
                    </div>
                    <TagList data={tabs} current={cate} onSelect={(item) => {
                        if (!listLoading) setCate(item.cate);
                    }} />
                    {/*<Spin spinning={resList.length < 8}>*/}
                        <div className="video-container">
                            {
                                resList.map((info, index) => {
                                    return  <div key={index} className="video" onClick={() => {domEvents.toExpertDetail(info.id)}}>
                                            <div className="background">
                                                <img className="cover-img" src={info.img_url} alt=""/>
                                                <img className="icon" src={play} alt=""/>
                                            </div>
                                            <p className="title">{info.title}</p>
                                            <p className="info">{info.article_source}</p>
                                            <p className="time">{info.public_date}</p>
                                        </div>

                                })
                            }
                        </div>
                    {/*</Spin>*/}
                </div>
                <div className="content-box">
                    <div className="header">
                        <p className="title">
                            <span>健康科普</span>
                        </p>
                        <span className="more" onClick={() => {
                            domEvents.toHealth()
                        }}>
                            查看更多
                            <RightOutlined />
                        </span>
                    </div>
                    <Spin spinning={articleList.length === 0}>
                        <div className="article-container">
                            {
                                articleList.map((article, index) => {
                                    return index < 4 &&
                                        <div key={index} className="article" onClick={() => {
                                            domEvents.toArticle(article.id)
                                        }}>
                                            {article.img_url && <img src={article.img_url} alt=""/>}
                                            <div className="article-info">
                                                <div className="title">{article.title}</div>
                                                <div className="abstract">{article.abstract}</div>
                                                <div className="author">{article.author_info}</div>
                                            </div>
                                        </div>
                                })
                            }
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default Index;