import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../untils/requestHelper";
import Service from "xgplayer-service";

import expertsInfo from "../untils/healthExpertsData";

function useEncyclopedia() {
    const [videoList] = useState(() => {
        return [...expertsInfo]
    })
    const [resList, setResList] = useState([]);
    const [articleList, setArticleList] = useState([])

    const getVideoToken = async () => {
        let i = resList.length;
        for ( ; i < videoList.length; i++) {
            let ret = await reqestApi(`api/video/token?vid=${videoList[i].urlid}`, "GET")
            if (ret.data.code === 0) {
                await Service.url(ret.data.data.token).then((res) => {
                    setResList(list => [...list, res.Data])
                });
            }
        }
    }

    const getArticleList = async () => {
        const params = {
            page: 1,
            limit: 10
        };
        let ret = await reqestApi("/api/health_life", "GET", params);
        if (ret.data.code === 0) {
            if(Array.isArray(ret.data.data)){
                const newData=ret.data.data.map((item)=>{item.abstract=(item.abstract)||(item.content).replace(/<.*?>/g,'');return item})
                setArticleList(newData);
            }
        }
    }

    return {
        articleList,
        videoList,
        resList,
        getVideoToken,
        getArticleList
    }
}

export default createModel(useEncyclopedia)