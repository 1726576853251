import React, { useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Space } from "antd";

import "../../static/css/pages/comm.css";
import "../../static/css/pages/system.css";

function Contact() {
  useEffect(() => {
    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "联系我们",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);
  return (
    <div className="system-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="sys-content">
        <div className="sys-content-title">联系我们</div>
        <div>
          <div className="sys-content-txt">
            <b>1.健康中国行动网站联系电话</b>
          </div>
          <div className="sys-content-other">国内：(010)64622087</div>
          <div className="sys-content-other"> 国际：(8610)64622087</div>
        </div>

        <div>
          <div className="sys-content-txt">
            <b>2.健康中国行动网站传真电话</b>
          </div>
          <div className="sys-content-other">国内：(010)64621673 </div>
          <div className="sys-content-other"> 国际：(8610)64621673</div>
        </div>
        <div className="sys-content-other">
          *注：健康中国行动网站中所链接的各省市地方相关内容请与各地方机构联系。
        </div>
      </div>
    </div>
  );
}

export default Contact;
