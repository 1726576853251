import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { useCookies } from "react-cookie";
import { message } from "antd";

function useIndex() {
  const articleList = {
    k684279: {
      title: "重大行动一：健康知识普及行动",
      uid: "684279",
      pre: "",
      next: "k684280",
    },
    k684280: {
      title: "重大行动二：合理膳食行动",
      uid: "684280",
      pre: "k684279",
      next: "k684281",
    },
    k684281: {
      title: "重大行动三：全民健身行动",
      uid: "684281",
      pre: "k684280",
      next: "k684282",
    },
    k684282: {
      title: "重大行动四：控烟行动",
      uid: "684282",
      pre: "k684281",
      next: "k684283",
    },
    k684283: {
      title: "重大行动五：心理健康促进行动",
      uid: "684283",
      pre: "k684282",
      next: "k684284",
    },
    k684284: {
      title: "重大行动六：健康环境促进行动",
      uid: "684284",
      pre: "k684283",
      next: "k684285",
    },
    k684285: {
      title: "重大行动七：妇幼健康促进行动",
      uid: "684285",

      pre: "k684284",
      next: "k684286",
    },
    k684286: {
      title: "重大行动八：中小学健康促进行动",
      uid: "684286",
      pre: "k684285",
      next: "k684287",
    },
    k684287: {
      title: "重大行动九：职业健康保护行动",
      uid: "684287",
      pre: "k684286",
      next: "k684288",
    },
    k684288: {
      title: "重大行动十：老年健康促进行动",
      uid: "684288",
      pre: "k684287",
      next: "k684289",
    },
    k684289: {
      title: "重大行动十一：心脑血管疾病防治行动",
      uid: "684289",
      pre: "k684288",
      next: "k684290",
    },
    k684290: {
      title: "重大行动十二：癌症防治行动",
      uid: "684290",
      pre: "k684289",
      next: "k684291",
    },
    k684291: {
      title: "重大行动十三：慢性呼吸系统疾病防治行动",
      uid: "684291",

      pre: "k684290",
      next: "k684292",
    },
    k684292: {
      title: "重大行动十四：糖尿病防治行动",
      uid: "684292",
      pre: "k684291",
      next: "k684293",
    },
    k684293: {
      title: "重大行动十五：传染病及地方病防控行动",
      uid: "684293",
      pre: "k684292",
      next: "",
    },
  };

  const [article, setArticle] = useState({ title: "" });
  const [cookies, setCookies] = useCookies();
  const [recList, setReclist] = useState([]);
  const [localList, setLocalList] = useState([]);
  const getArticle = async (id) => {
    let url = "/api/article";
    let ret = await reqestApi(url, "GET", {
      id: id
    });
    if (ret.data.code == 0) {
      if (ret.data.data.anchor_name) {
        ret.data.data.anchor_name = ret.data.data.anchor_name.split("x");
        // 正则匹配添加锚链接
        let content = ret.data.data.content;
        ret.data.data.anchor_name.map((item, index) => {
          let reg = new RegExp(`<strong>${item}：</strong>`, "g");
          const replaceValue = `<strong id='art-${
            item.charCodeAt(0) + (item != "Z" && item != "z" ? 1 : -25)
          }'>${item}：</strong>`;
          content = content.replace(reg, replaceValue);
        });
        ret.data.data.content = content;
      }

      setArticle(ret.data.data);

      let loc = localStorage.getItem("loc");
      let pre = localStorage.getItem("pre");
      if (loc && loc != window.location.href) {
        pre = loc;
      }
      loc = window.location.href;
      localStorage.setItem("pre", pre);
      localStorage.setItem("loc", loc);

      // window.TEA("jkzgxd_pageview", {
      //   url: window.location.href,
      //   title: ret.data.data.title,
      //   url_path: window.location.pathname,
      //   referrer: pre,
      //   referrer_host: window.location.origin,
      // });
    }
  };

  const getRecommend = async (cate, sub_cate, page) => {
    let params = {};
    params.cate = cate;
    if (sub_cate > 0) {
      params.sub_cate = sub_cate;
    }

    params.page = page;
    let ret = await reqestApi("/api/recommand", "GET", params);
    if (ret.data.code == 0) {
      setReclist(ret.data.data);
      console.log("000");
    }
  };

  const getLocalList = async (num) => {
    let ret = await reqestApi("/api/localdynamic", "GET", {});
    if (ret.data.code == 0) {
      let arr = ret.data.data;
      if (arr && arr.length > num) {
        arr = arr.slice(num);
      }
      setLocalList(arr);
    }
  };

  return {
    article,
    articleList,
    recList,
    localList,
    getLocalList,
    getArticle,
    getRecommend,
  };
}

export default createModel(useIndex);
