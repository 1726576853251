import React, {useState, useEffect, useRef} from "react";
import {Breadcrumb, Space, Spin} from "antd";

import "./detail.scss"
import Player from "xgplayer";
import {LeftOutlined} from "@ant-design/icons";
import useVideo from "../../../models/expertVideo";

function Detail(props) {
    const {
        getSingleVideo,
    } = useVideo()

    const [id] = useState(props.location.state.id);
    const [loading, setLoading] = useState(false);
    const [playerInfo, setPlayerInfo] = useState({});
    const [nextList, setNextList] = useState(() => []);

    const player = useRef();

    const domEvents = {
        toDetail: (id) => {
            props.history.push({
                pathname: "/expertdetail",
                state: {
                    id,
                    info: props.location.state.info
                }
            })
        },
        toBack: () => {
            props.history.goBack(-1)
        }
    }

    useEffect(() => {
        const { location } = props;
        const { state } = location || {};
        const { id } = state || {};
        setLoading(true);
        getSingleVideo(id).then((info) => {
            const { detail = {}, list = [] } = info;
            setPlayerInfo(detail);
            player.current = new Player({
                id: id,
                poster: detail.img_url,
                url: detail.url,
                width: '773px',
                height: '440px',
                autoplay: true
            });
            if (Array.isArray(list) && list[0]) {
                setNextList(list);
                player.current && player.current.on("ended", () => {
                    const nextID = list[0] && list[0].id || '';
                    domEvents.toDetail(nextID);
                });
            }
        }).finally((e) => {
            setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <>
        <Breadcrumb className="bread-container">
            <Breadcrumb.Item onClick={() => {domEvents.toBack()}}>
                <Space className="go-back">
                    <LeftOutlined/>
                    返回上一页
                </Space>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Space>
                    {playerInfo.title}
                </Space>
            </Breadcrumb.Item>
        </Breadcrumb>
        <div className="expert-detail">
            <Spin spinning={loading}>
                <div className="next-players">
                    <div className="title">相关推荐</div>
                    {
                        nextList.map((item, index) => {
                            return (
                                <div key={index} className="next-play-container" onClick={() => {domEvents.toDetail(item.id)}}>
                                    <div className="next-background">
                                        <img src={item.img_url} alt=""/>
                                    </div>
                                    <div className="info-container">
                                        <div className="play-title">{item.title}</div>
                                        <div className="time">{item.public_date}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="player-container">
                    <div id={id} className="player"/>
                    <div className="title">
                        <span>{playerInfo.title}</span>
                    </div>
                    <div className="sub-info">来源：{playerInfo.doctorInfo && playerInfo.doctorInfo.split(' ').join("-")}</div>
                    <div className="sub-info">{playerInfo.time}</div>
                </div>
            </Spin>
        </div>
    </>)
}

export default Detail