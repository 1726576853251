import axios from 'axios';
import { Conf } from '../conf/app';

export const initAxiosInterceptorsConfig = () => {
  let requestUrl = '';

  // const getUtm = () => {
  //   const { searchParams } = new URL(window.location.href);
  //   let utm = {};
  //   for (let key of searchParams.keys()) {
  //       if (key.indexOf('utm_') === 0) {
  //       utm[key] = searchParams.get(key);
  //       }
  //   }
  //   return utm;
  // };

  const defaultTags = {
    refer: window.location.href,
    pathname: window.location.pathname,
    ua: navigator && navigator.userAgent,
  // client: isLSGAppMain ? 'lsgapp' : 'community',
  // utm_source: getUtm().utm_source || '',
  // utm_medium: getUtm().utm_medium || '',
  // webid: Cookies.get('MONITOR_WEB_ID') || ''
  };

  const sendLog = (name = '', params = {}, url = '') => {
    window.vemars('emit', 'custom', {
      event_name: name,
      metrics: {
        time: Date.now() - params?.request_time,
      },
      tags: {
        ...defaultTags,
        ...params,
      },
    });
  };
  const sendLogId = (logid = '', level = 'info') => {
    window.vemars('emit', 'log', {
      value: logid,
      level,
      tags: {
        logid,
      },
    });
  };

  const getUrlParams = (url = '') => {
    let result = {
      pathname: '',
      query: '',
    };
    try {
      const urlObj = new URL(url);
      result = {
        pathname: urlObj.pathname || '',
        query: urlObj.search || '',
      };
    } catch (error) {
      console.log(error);
    }
    return result;
  };

  axios.interceptors.request.use(
    (config) => {
      requestUrl = config.url || '';
      const newConfig = {
        ...config,
        axStart: Date.now(),
        axStartHigh: 0,
      };
      try {
        newConfig.axStartHigh = window.performance.now();
        if (!window.axStore) {
          window.axStore = {};
        }
      } catch (e) {
        console.error(e);
        // do nothing
      }
      return newConfig;
    },
    (error) => {
      try {
        let urlParams = {
          pathname: '',
          query: '',
        };
        if (error?.request?.config?.url) {
          urlParams = getUrlParams(error?.request?.config?.url);
        }
        sendLog(
          'custom_request_error',
          {
            request_stage: 'request',
            request_path: urlParams?.pathname,
            request_query: urlParams?.query,
            request_url: error?.request?.config?.url,
            message: JSON.stringify(error),
            method: error?.request?.config?.method,
            request_time: `${error?.response?.config?.axStart}`,
            response_status: `${error?.response?.status}`,
          },
          error?.request?.config?.url || ''
        );
      } catch (custom_request_error) {
        console.error('custom_request_error', custom_request_error);
      }
      return Promise.reject(error);
    }
  );

  // axios拦截器
  axios.interceptors.response.use((response) => {
    try {
      const { headers, data, request } = response;
      const logid = headers?.['x-tt-logid'] || '';
      // 上报logid
      // 接口code上报
      sendLog(
        'custom_request_code',
        {
          request_stage: 'response',
          request_url: request?.responseURL,
          request_path: request?._url,
          method: request?.method,
          request_time: `${request?._start}`,
          response_status: `${request?.status}`,
          response_code: `${data?.code}`,
          logid,
        },
        request?.responseURL || ''
      );
      sendLogId(logid);
    } catch (error) {
      console.error('custom_request_code', error);
    }
    return response;
  }, (error) => {
    try {
      let urlParams = {
        pathname: '',
        query: '',
      };
      if (requestUrl) {
        urlParams = getUrlParams(requestUrl);
      }
      // 接口请求返回报错上报
      sendLog(
        'custom_request_error',
        {
          request_type:
              error === undefined || error?.code === 'ECONNABORTED'
                ? 'timeout'
                : 'error',
          request_stage: 'response',
          request_path: urlParams?.pathname,
          request_query: urlParams?.query,
          request_url: error?.response?.responseURL,
          method: error?.response?._method,
          request_time: `${error?.response?.config?.axStart}`,
          response_status: `${error?.response?.status}`,
          logid: error?.response?.headers?.['x-tt-logid'] || '',
          message: error?.response?.data?.message || error?.message,
        },
        error?.response?.responseURL || ''
      );
    } catch (custom_request_error) {
      console.error('custom_request_error', custom_request_error);
    }
    return Promise.reject(error);
  });
};

export default initAxiosInterceptorsConfig;
