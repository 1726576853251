const governmentOrgans = {
  unfold: [
    {
      name: "国家发展和改革委员会",
      url: "http://www.ndrc.gov.cn/",
      type: "normal",
    },
    {
      name: "教育部",
      url: "http://www.moe.gov.cn/",
      type: "normal",
    },
    {
      name: "科学技术部",
      url: "http://www.most.gov.cn/",
      type: "normal",
    },
    {
      name: "工业和信息化部",
      url: "http://www.miit.gov.cn/",
      type: "normal",
    },
    {
      name: "国家民族事务委员会",
      url: "http://www.seac.gov.cn/",
      type: "normal",
    },
    {
      name: "公安部",
      url: "http://www.mps.gov.cn/",
      type: "normal",
    },
    { name: "民政部", url: "http://www.mca.gov.cn/", type: "normal" },
    { name: "展开全部 >>", url: "https://www.china.com", type: "btn-fold" },
  ],
  fold: [
    {
      name: "国家发展和改革委员会",
      url: "http://www.ndrc.gov.cn/",
      type: "normal",
    },
    {
      name: "教育部",
      url: "http://www.moe.gov.cn/",
      type: "normal",
    },
    {
      name: "科学技术部",
      url: "http://www.most.gov.cn/",
      type: "normal",
    },
    {
      name: "工业和信息化部",
      url: "http://www.miit.gov.cn/",
      type: "normal",
    },
    {
      name: "国家民族事务委员会",
      url: "http://www.seac.gov.cn/",
      type: "normal",
    },
    {
      name: "公安部",
      url: "http://www.mps.gov.cn/",
      type: "normal",
    },
    { name: "民政部", url: "http://www.mca.gov.cn/", type: "normal" },
    { name: "司法部", url: "http://www.moj.gov.cn/", type: "normal" },
    {
      name: "财政部",
      url: "http://www.mof.gov.cn/index.htm",
      type: "normal",
    },
    {
      name: "人力资源和社会保障部",
      url: "http://www.mohrss.gov.cn/",
      type: "normal",
    },
    {
      name: "自然资源部",
      url: "http://www.mnr.gov.cn/",
      type: "normal",
    },
    {
      name: "生态环境部",
      url: "http://www.mee.gov.cn/",
      type: "normal",
    },
    {
      name: "住房和城乡建设部",
      url: "http://www.mohurd.gov.cn/",
      type: "normal",
    },
    {
      name: "交通运输部",
      url: "http://www.mot.gov.cn/",
      type: "normal",
    },
    {
      name: "水利部",
      url: "http://www.mwr.gov.cn/",
      type: "normal",
    },

    {
      name: "农业农村部",
      url: "http://www.moa.gov.cn/",
      type: "normal",
    },
    {
      name: "文化和旅游部",
      url: "http://www.mct.gov.cn/",
      type: "normal",
    },

    {
      name: "国家卫生健康委员会",
      url: "http://www.nhc.gov.cn/",
      type: "normal",
    },
    {
      name: "退役军人事务部",
      url: "http://www.mva.gov.cn/",
      type: "normal",
    },
    {
      name: "应急管理部",
      url: "http://www.mem.gov.cn/",
      type: "normal",
    },
    {
      name: "人民银行",
      url: "http://www.pbc.gov.cn/",
      type: "normal",
    },
    {
      name: "国务院国有资产监督管理委员会",
      url: "http://www.sasac.gov.cn/",
      type: "normal",
    },
    {
      name: "海关总署",
      url: "http://www.customs.gov.cn/",
      type: "normal",
    },
    {
      name: "国家税务总局",
      url: "http://www.chinatax.gov.cn/",
      type: "normal",
    },
    {
      name: "国家市场监督管理总局",
      url: "http://www.samr.gov.cn/",
      type: "normal",
    },
    {
      name: "国家广播电视总局",
      url: "http://www.nrta.gov.cn/",
      type: "normal",
    },
    {
      name: "国家体育总局",
      url: "http://www.sport.gov.cn/",
      type: "normal",
    },
    {
      name: "国家统计局",
      url: "http://www.stats.gov.cn/",
      type: "normal",
    },
    {
      name: "国家医疗保障局",
      url: "http://www.nhsa.gov.cn/",
      type: "normal",
    },
    {
      name: "国家新闻出版署（国家版权局）",
      url: "http://www.nppa.gov.cn/",
      type: "normal",
    },
    {
      name: "国家互联网信息办公室",
      url: "http://www.cac.gov.cn/",
      type: "normal",
    },
    {
      name: "中央广播电视总台",
      url: "http://www.cnr.cn/",
      type: "normal",
    },
    {
      name: "中国气象局",
      url: "http://www.cma.gov.cn/",
      type: "normal",
    },
    {
      name: "中国银行保险监督管理委员会",
      url: "http://www.cbirc.gov.cn/",
      type: "normal",
    },
    {
      name: "中国证券监督管理委员会",
      url: "http://www.csrc.gov.cn/pub/newsite/",
      type: "normal",
    },
    {
      name: "国家粮食和物资储备局",
      url: "http://www.lswz.gov.cn/",
      type: "normal",
    },
    {
      name: "国家烟草专卖局",
      url: "http://www.tobacco.gov.cn/html/",
      type: "normal",
    },
    {
      name: "国家林业和草原局",
      url: "http://www.forestry.gov.cn/",
      type: "normal",
    },
    {
      name: "国家铁路局",
      url: "http://www.nra.gov.cn/",
      type: "normal",
    },
    {
      name: "中国民用航空局",
      url: "http://www.caac.gov.cn/index.html",
      type: "normal",
    },
    {
      name: "国家中医药管理局",
      url: "http://www.satcm.gov.cn/",
      type: "normal",
    },
    {
      name: "国家药品监督管理局",
      url: "https://www.nmpa.gov.cn/",
      type: "normal",
    },
    {
      name: "中央宣传部",
      url: "http://www.wenming.cn/",
      type: "normal",
    },
    {
      name: "中国科学技术协会",
      url: "http://www.cast.org.cn/",
      type: "normal",
    },

    {
      name: "中华全国总工会",
      url: "http://www.acftu.org/",
      type: "normal",
    },

    {
      name: "中华全国妇女联合会",
      url: "http://www.women.org.cn/",
      type: "normal",
    },

    {
      name: "国务院扶贫开发领导小组办公室",
      url: "http://www.cpad.gov.cn/",
      type: "normal",
    },

    {
      name: "中央精神文明建设指导委员会办公室",
      url: "http://www.wenming.cn/",
      type: "normal",
    },

    {
      name: "中国残疾人联合会",
      url: "http://www.cdpf.org.cn/",
      type: "normal",
    },
    {
      name: "中央政法委员会",
      url: "http://www.ce.cn/ztpd/xwzt/zyzsjgrwk/zfw/index.shtml",
      type: "normal",
    },

    {
      name: "中国共产主义青年团中央委员会",
      url: "http://www.ccyl.org.cn/",
      type: "normal",
    },

    {
      name: "中央组织部",
      url: "http://news.12371.cn/dzybmbdj/zzb/",
      type: "normal",
    },

    {
      name: "中国红十字会",
      url: "https://www.redcross.org.cn/",
      type: "normal",
    },

    {
      name: "中华全国工商业联合会",
      url: "http://www.acfic.org.cn/",
      type: "normal",
    },

    // {
    //   name: "各省级人民政府",
    //   url: "",
    //   type: "disabled",
    // },
    { name: "<< 部分折叠", url: "https://www.china.com", type: "btn-unfold" },
  ],
};

export const getGovernmentOrgans = (key) => {
  return governmentOrgans[key];
};
