import React from "react";
import "../static/css/components/Recommend.css";
import { cutStr } from "../untils/commHelper";
import { Link } from "react-router-dom";

const HotWorks = (props) => {
  const { params } = props;
  const numStr = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"];
  const getClass = (num) => {
    let str = "rec-num-normal";
    if (num === 0) {
      str += " num-one";
    } else if (num === 1) {
      str += " num-two";
    } else if (num === 2) {
      str += " num-three";
    }
    return str;
  };
  const getIE = () => {
    var userAgent = navigator.userAgent;
    let fIEVersion = parseFloat(RegExp["$1"]);
    if (fIEVersion == 10 || fIEVersion == 9) {
      return 20;
    }
    return 50;
  };
  return (
    <>
      {params.length > 0 && (
        <div className="recommend-box">
          <div className="recommend-box-head">最热科普</div>
          <div className="recommend-box-list">
            {params.map((item, index) => {
              return (
                <Link
                  key={item.id + "_hot"}
                  to={{ pathname: `/article/${item.id}` }}
                >
                  <div className="recommend-box-item">
                    <div className={getClass(index)}>{numStr[index]}</div>
                    <span className="rec-content">
                      {cutStr(item.title, 50)}
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default HotWorks;
