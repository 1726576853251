import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../untils/requestHelper";
import { message } from "antd";

function useLocal() {
  const [localList, setLocalList] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [reclist, setReList] = useState([]);
  const [searchData, setSearchData] = useState(() => {
    let list = [
      {
        title: "地区",
        name: 0,
        value: 0,
        sublist: [],
      },
      {
        title: "行动",
        name: 1,
        value: 0,
        sublist: [
          { label: "推荐", value: 0 },
          { label: "健康知识普及", value: 1 },
          { label: "合理膳食", value: 2 },
          { label: "全民健身", value: 3 },
          { label: "控烟", value: 4 },
          { label: "心理健康", value: 5 },
          { label: "健康环境", value: 6 },
          { label: "妇幼健康", value: 7 },
          { label: "中小学健康", value: 8 },
          { label: "职业健康", value: 9 },
          { label: "老年健康", value: 10 },
          { label: "心脑血管疾病", value: 11 },
          { label: "癌症", value: 12 },
          { label: "慢性呼吸系统疾病", value: 13 },
          { label: "糖尿病", value: 14 },
          { label: "传染病及地方病", value: 15 },
        ],
      },
    ];
    return list;
  });
  const getRecommend = async (cate, sub_cate, page) => {
    let params = {};
    params.cate = cate;
    if (sub_cate > 0) {
      params.sub_cate = sub_cate;
    }

    params.page = page;
    let ret = await reqestApi("/api/recommand", "GET", params);
    if (ret.data.code == 0) {
      setReList(ret.data.data);
    }
  };
  const getLocalList = async (page, limit, province_id, category_two) => {
    /**
     * 1.name 0地区 1行动
     * 2.key当前选中id
     * 3.province_id省id
     * 4.category_two 行动id
     */
    let params = {};
    params.page = page;
    params.limit = limit;
    if (category_two !== 0) {
      params.category_two = category_two;
    }
    if (province_id !== 0) {
      params.province_id = province_id;
    }
    let ret = await reqestApi("/api/localdynamic", "GET", params);
    if (ret.data.code == 0) {
      setLocalList(ret.data.data);
      setTotalCount(ret.data.totalCount);
    }
  };

  return {
    localList,
    searchData,
    setSearchData,
    totalCount,
    reclist,
    getLocalList,
    getRecommend,
  };
}

export default createModel(useLocal);
