import React from "react";
import "./index.scss"

function Update(props) {

    return (
        <div className="update__warp">
            <div className="update__warp__container">
                <div className="update__warp__container-img"></div>
                <div className="update__warp__container-text">系统升级维护中，稍后开放...</div>
            </div>
        </div>
    )
}

export default Update