import React, { useState, useEffect } from "react"

function Index() {
  return (
    <div>
      <h2>权威信息</h2>
    </div>
  )
}

export default Index
