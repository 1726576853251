import React, { useState, useEffect, useRef } from "react";
import { RightOutlined } from '@ant-design/icons';
import Player from "xgplayer";

import useVideo from '../../models/anniversary';
import { govInfo, localNews, videoList } from './data';
import './index.scss'

function Index(props) {
  const [currVideo, setCurrVideo] = useState({});
  const [currItem, setCurrItem] = useState({});
  const [showVideoTitle, setShowVideoTitle] = useState(false);

  const { getDetail } = useVideo();

  const player = useRef();

  const domEvents = {
    toArticle: (id) => {
      props.history.push(`/article/${id}`);
    },
    toDetail: (url) => {
      props.history.push(url);
    },
    handleTitleChange: (status) => {
      setShowVideoTitle(status);
    },
    getVideo: (item, autoplay = true) => {
      if (currVideo && player.current) {
        currVideo.lastPlayTime = player.current.currentTime;
        player.current.destroy();
      }
      if (item.url) {
        setTimeout(() => {
          methods.getPlayer(item, item.url, autoplay);
        }, 0)
        return;
      }
      getDetail(item.vid).then((videoUrl) => {
        methods.getPlayer(item, videoUrl, autoplay);
      })
    },
    handleMouseIn: (item) => {
      setCurrItem(item);
    },
    handleMouseOut: () => {
      setCurrItem({});
    }
  }

  const methods = {
    getPlayer: (item, videoUrl, autoplay) => {
      player.current = new Player({
        id: 'xp',
        poster: item.cover,
        url: videoUrl,
        width: 866,
        height: '100%',
        autoplay,
        lastPlayTime: item.lastPlayTime || 0,
      }).on('pause', () => {
        item.lastPlayTime = player.current.currentTime;
      }).on('ended', () => {
        item.lastPlayTime = 0;
      });
      setCurrVideo(item);
    }
  }

  useEffect(() => {
    domEvents.getVideo(videoList[0], false);
  }, [])

  return (
    <div className="anniversary-container">
      <div className="gov-info-box">
        <div className="title">官方资讯</div>
        <div className="gov-info">
          <div className="img-box">
            <div className="main-img" onClick={() => { domEvents.toArticle('685730') }}>
              <div className="shadow-down">孙春兰在健康中国行动启动仪式上强调 推进健康中国建设 增进人民健康福祉</div>
            </div>
            <div className="sub-img">
              <div className="top" onClick={() => { domEvents.toArticle('686429') }}>
                <div className="shadow-down">关于开展倡导文明健康绿色环保生活方式活动的意见</div>
              </div>
              <div className="bottom" onClick={() => { domEvents.toArticle('686368') }}>
                <div className="shadow-down">健康形象大使设立和健康达人评选活动启动仪式在京举行</div>
              </div>
            </div>
          </div>
          <div className="info-box">
            {
              Array.isArray(govInfo) && govInfo.map((item) => (
                <div key={item.id} className="list-item" onClick={() => { domEvents.toArticle(item.id) }} onMouseEnter={() => { domEvents.handleMouseIn(item) }} onMouseLeave={() => { domEvents.handleMouseOut() }}>
                  <div className="decorate" style={{ backgroundColor: currItem === item ? '#329cff' : '#e2e4e6' }}/>
                  <span>{item.name || ''}</span>
                </div>
              ))
            }
            <div className="show-more" onClick={() => { domEvents.toDetail('/consult') }} >更多 <RightOutlined /></div>
          </div>
        </div>
      </div>
      <div className="video-container">
        <div className="video-wrap">
          <div className="title">视频播报</div>
          <div className="video-box">
            <div className="video-list">
              {
                Array.isArray(videoList) && videoList.map((item) => (
                  <div key={item.vid} className={`list-item${item === currVideo ? ' selected-item' : ''}`} onClick={() => { domEvents.getVideo(item) }}>
                    <img className="cover" src={item.cover} alt=""/>
                    <div className="info-box">
                      <div className="video-title">{item.title || ''}</div>
                      <div className="video-source">{item.source || ''}</div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div id="xp" className="main-video-box" onMouseEnter={() => { domEvents.handleTitleChange(true); }} onMouseLeave={() => { domEvents.handleTitleChange(false); }}>
              {showVideoTitle && <div className="video-shadow-title">{currVideo.title || ''}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="local-news">
        <div className="title">地方要闻</div>
        <div className="info-box">
          {
            Array.isArray(localNews) && localNews.map((item, index) => (
              <div key={item.url} className="local-info">
                <div className="show-more" onClick={() => { domEvents.toDetail(item.url) }}>更多 <RightOutlined /></div>
                <div className="sub-title">
                  {/*<span className="index">{`#0${index + 1}`}</span>*/}
                  {item.title || ''}
                </div>
                {
                  Array.isArray(item.list) && item.list.map((subItem) => (
                    <div key={subItem.url} className="list-item" onClick={() => { domEvents.toDetail(subItem.url) }} onMouseEnter={() => { domEvents.handleMouseIn(subItem) }} onMouseLeave={() => { domEvents.handleMouseOut() }}>
                      <div className="decorate" style={{ backgroundColor: currItem === subItem ? '#329cff' : '#e2e4e6' }}/>
                      <span>{subItem.name || ''}</span>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Index;