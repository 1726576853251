import React from "react";
import { Popover } from 'antd';

import './index.scss'

import contentImg from './img/content.png';
import titleImg from './img/title.png';
import contentTitleImg from './img/content-title.png';
import qrImg from './img/qrcode.png';

function Index(props) {

  return (
    <div className="knowledge-container">
      <img className="knowledge-container-title" src={titleImg} alt="" />
      {/* <img className="knowledge-container-content" src={contentImg} alt="" /> */}
      <div className="knowledge-container-warp">
        <div className="knowledge-container-content">
          <div className="knowledge-container-content-title">
            <img src={contentTitleImg} alt="" />
          </div>
          <div className="knowledge-container-content-text">
            <p>
              《 健康中国行动（2019-2030年）》指出，普及健康知识，提高全民健康素养水平，是提高全民健康水平最根本最经济最有效的措施之一。科学普及健康知识，提升健康素养，有助于提高居民自我健康管理能力和健康水平。按照“共建共享、全民健康”的战略主题，紧紧围绕实施健康中国行动的15个专项行动内容，健康中国行动推进委员会办公室发起并主办“健康中国行动知行大赛”。大赛除了医疗卫生机构专场，还设有家庭专场，以彰显家庭作为社会基本细胞在推进健康中国战略落地见效中的重要阵地作用。
            </p>
            <p>
              举办“健康中国行动知行大赛”是向全民普及健康知识、推进健康中国行动深入实施的重要举措，将促进全民将健康知识内化于心、外化于行，巩固医疗卫生机构和家庭这两大健康知识普及的主阵地，唱响全面推进健康中国建设、深入实施健康中国行动的主旋律。
            </p>
          </div>

          <div className="knowledge-container-content-qr">
            <Popover content={<img style={{ width: '200px' }} src={qrImg} alt="" />}>
              <img src={qrImg} alt="" />
            </Popover>
          </div>
          <p className="qr-text">
            点击查看大图<br />
            微信扫描上方二维码，即可开始答题
          </p>
        </div>
        <div className="knowledge-container-bottom">
          健康中国行动推进委员会办公室
        </div>
      </div>
    </div>
  )
}

export default Index;