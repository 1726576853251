import React from 'react'
import offline from '../../../static/images/offline.png'
import './index.scss'

// 已下线页面
const OfflinePage = () => {
  return (
    <div className="offline__container">
      <img className="offline__icon" src={offline} alt="已下线页面" />
      <div className="offline__text">您访问的页面已下线，感谢关注！</div>
    </div>
  )
}

export default OfflinePage
