import React, { useEffect, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { browserHistory } from "react-router";
import { Breadcrumb, Space, Anchor } from "antd";
import ArticleModel from "../../models/article/index";
import Recommend from "../../components/Recommend";
import { decodeContent } from "../../untils/commHelper";
import { Link as GoLink } from "react-router-dom";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/article/detial.css";
import "../../static/css/pages/article/quill.snow.css";
const { Link } = Anchor;
function Detial(props) {
  const {
    article,
    articleList,
    recList,
    getArticle,
    getRecommend,
    localList,
    getLocalList,
  } = ArticleModel();
  const [targetOffset, setTargetOffset] = useState(undefined);
  const getPageKey = (type) => {
    let id = props.match.params.id;
    let thisPage = articleList["k" + id];
    if (type == "pre") {
      return thisPage.pre;
    } else {
      return thisPage.next;
    }
  };

  const majorList = [
    { 684279: 1 },
    { 684280: 2 },
    { 684281: 3 },
    { 684282: 4 },
    { 684283: 5 },
    { 684284: 6 },
    { 684285: 7 },
    { 684286: 8 },
    { 684287: 9 },
    { 684288: 10 },
    { 684289: 11 },
    { 684290: 12 },
    { 684291: 13 },
    { 684292: 14 },
    { 684293: 15 },
  ];
  useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
    let id = props.match.params.id;
    getArticle(id);
    getRecommend("health_life", majorList[id], 1);
    getLocalList(5);
  }, []);
  return (
    <div className="article-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item
            className="gopre"
            onClick={() => {
              //window.history.go(-1);
              console.log("历史：", window.history);
              props.history.push("/");
            }}
          >
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{article.title}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div
        style={{
          width: "1080px",
          display: "flex",

          position: "relative",
        }}
      >
        <div className="anchor-box">
          <Anchor targetOffset={targetOffset}>
            {article.anchor_name &&
              article.anchor_name.map((aitem, aindex) => {
                return (
                  <Link
                    key={"anc_" + aindex}
                    href={`#art-${
                      aitem.charCodeAt(0) +
                      (aitem != "Z" && aitem != "z" ? 1 : -25)
                    }`}
                    title={aitem}
                  />
                );
              })}
          </Anchor>
        </div>
        <div className="article-content  new-article-content" style={{ display: 'flex',
    flexDirection: 'column'}}>
          <div className="title">{article.title}</div>
          <div
            className="content specialcss"
            dangerouslySetInnerHTML={{
              __html: decodeContent(article.content),
            }}
          ></div>

          <div className="foot">
            {getPageKey("pre") && (
              <GoLink to={`/detial/${articleList[getPageKey("pre")].uid}`}>
                上一篇：{articleList[getPageKey("pre")].title}
              </GoLink>
            )}
            {getPageKey("next") && (
              <GoLink to={`/detial/${articleList[getPageKey("next")].uid}`}>
                下一篇： {articleList[getPageKey("next")].title}
              </GoLink>
            )}
          </div>
        </div>

        <div
          style={{
            paddingTop: "90px",
            marginLeft: "16px",
            position: "absolute",
            right: 0,
          }}
        >
          <Recommend params={recList} title="相关推荐" />
          <div style={{ height: "20px" }} />
          <Recommend params={localList} title="地方动态" />
        </div>
      </div>
    </div>
  );
}

export default Detial;
