import React, { useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Space } from "antd";

import "../../static/css/pages/comm.css";
import "../../static/css/pages/system.css";

function Error() {
  useEffect(() => {
    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "网站纠错",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);

  return (
    <div className="system-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="sys-content">
        <div className="sys-content-title">网站纠错</div>
        <div>
          <div className="sys-content-txt">
            <b>
              感谢您对健康中国行动网站的关注，如发现内容有误，可通过以下渠道反馈：
            </b>
          </div>
          <div className="sys-content-other">电话：010-64622087</div>
          <div className="sys-content-other">邮箱：qmtzx@jkb.com.cn</div>
        </div>
      </div>
    </div>
  );
}

export default Error;
