import React, { useState, useEffect } from "react"
import { LeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { Breadcrumb, Space, Pagination } from "antd"
import Recommend from "../../components/Recommend"

function Test() {
  return (
    <div>
      <video
        src="http://www.nhc.gov.cn/sps/s7886t/202006/b91ea7eb73ad4636810bd16278709495/files/9d43c909513a41329304579cfcc2c757.mp4"
        controls="controls"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
  )
}

export default Test
