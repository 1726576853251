import React, {useState, useCallback, useEffect} from "react";
import {createModel} from "hox";
import {reqestApi} from "../untils/requestHelper";
import Service from "xgplayer-service";

function useVideo() {
    const [total, setTotal] = useState(0);
    const [resList, setResList] = useState(() => {
        return [];
    });
    const [tabs, setTabs] = useState([]);
    // 当前标签类型
    const [cate, setCate] = useState('');
    const [listLoading, setListLoading] = useState(false);

    const getVideoToken = useCallback(async (page, pageSize = 10) => {
        setListLoading(true);
        setResList([]);
        // /api/video/list
        const expertsList = await reqestApi(`/api/video/list?page=${page}&limit=${pageSize}&cate=${cate}`, 'GET');
        const { data } = expertsList || {};
        setTotal(data.totalCount);
        // 去掉加载海报逻辑，后端补上线上海报图
        // if (data && Array.isArray(data.data)) {
        //     for (let i = 0; i < data.data.length; i++) {
        //         const res = await reqestApi(`api/video/token?vid=${data.data[i].video_id}`, "GET");
        //         if (res.data.code === 0) {
        //             const videoRes = await Service.url(res.data.data.token, '//vod.volcengineapi.com');
        //             // 如果没有设置封面图，取视频默认返回的帧图。
        //             let CoverUrl = data.data[i].img_url || '';
        //             if (!data.data[i].img_url
        //                 || data.data[i].img_url === 'https://image1.jkzgxd.cn/~tplv-noop.image'
        //                 || data.data[i].img_url === 'http://image1.jkzgxd.cn/~tplv-noop.jpeg'
        //                 || data.data[i].img_url === 'https://image1.jkzgxd.cn/~tplv-noop.jpeg') {
        //                 CoverUrl = videoRes.PosterUrl;
        //             }
        //             const info = {
        //                 id: data.data[i].id,
        //                 title: data.data[i].title || '',
        //                 CoverUrl: CoverUrl || '',
        //                 doctorInfo: data.data[i].article_source || '',
        //                 time: data.data[i].public_date || '',
        //             }
        //             setResList(list => {
        //                 return [...list, info];
        //             })                    
        //         }
        //     }
        // }
        setResList(list => {
            return [...list, ...(data.data || [])];
        })
        setListLoading(false);
    }, [cate])

    const getSingleVideo = async (id) => {
        // /api/video/detail/{id}
        const res = await reqestApi(`/api/video/detail/${id}`, 'GET');
        if (res.data.code === 0) {
            const { data } = res.data;
            const { detail = {}, list = [] } = data;
            const { video_id } = detail || {};
            const resList = Array.isArray(list) ? list.filter((item) => item.video_id) : [];
            const videoRes = await reqestApi(`api/video/token?vid=${video_id}`, "GET");
            if (!video_id) {
                return data;
            }
            if (videoRes.data.code === 0) {
                const videoInfo = await Service.url(videoRes.data.data.token, '//vod.volcengineapi.com');
                const videoDetail =  {
                    title: detail.title || '',
                    doctorInfo: detail.article_source || '',
                    time: detail.public_date || '',
                    img_url: detail.img_url || '',
                    url: videoInfo && Array.isArray(videoInfo.PlayInfoList) && videoInfo.PlayInfoList[0] && videoInfo.PlayInfoList[0].MainPlayUrl,
                };
                if (Array.isArray((resList))) {
                    for (let i = 0; i < resList.length; i++) {
                        const res = await reqestApi(`api/video/token?vid=${resList[i].video_id}`, "GET");
                        if (res.data.code === 0) {
                            const videoRes = await Service.url(res.data.data.token, '//vod.volcengineapi.com');
                            resList[i].img_url = videoRes.PosterUrl;
                        }
                    }
                }
                return {
                    detail: videoDetail,
                    list: resList,
                }
            }
            return data;
        }
    }

    useEffect(() => {
        const loadTabs = async () => {
            const data = await reqestApi('/api/video/tab')
            setTabs(data?.data?.data || []);
        };
        loadTabs();
    }, []);

    return {
        listLoading,
        resList,
        total,
        cate,
        setCate,
        tabs,
        getVideoToken,
        getSingleVideo,
    }
}


export default createModel(useVideo)