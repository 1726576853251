import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useResult() {
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(-1);
  const search = async (query, page = 1) => {
    let params = {};
    params.query = query;
    params.pn = page;
    // let ret = await reqestApi("/api/search", "GET", params);
    let ret = await reqestApi("/api/search_new", "GET", params);
    if (ret.data.code === 0) {
      console.log("DT:", ret.data.data.data);
      setPage(page);
      setList(ret.data.data.data);
      setTotalCount(ret.data.data.num_count);
    }
  };
  return {
    page,
    list,
    totalCount,
    search,
  };
}

export default createModel(useResult);
