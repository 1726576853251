import React, {useEffect, useState, useRef} from "react";
import {useCookies} from "react-cookie";
import "../../static/css/pages/innovation.css";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/local.css";
import Empty from '../../static/images/empty.png'
import {LeftOutlined} from "@ant-design/icons";
import {Pagination, Breadcrumb, Space, Spin} from 'antd';
import HospitalModel from "../../models/innovation";
import {getProvinces} from "../../untils/citydata";

function Index(props) {
    const {
        totalCount,
        searchData,
        setSearchData,
        innovationList,
        setinnovationList,
        getInnovationList,
        page,
        loading
    } = HospitalModel();
    const localParams = JSON.parse(localStorage.getItem("inno_param"));
    const params = useRef({
        province_id: searchData[0].value,
        exchange_cate: searchData[1].value,
        exchange_year: searchData[2].value,
    })
    useEffect(() => {
        if(localParams) {
            searchData[0].value = localParams.province_id;
            searchData[1].value = localParams.exchange_cate;
            searchData[2].value = localParams.exchange_year;
            params.current.province_id = localParams.province_id;
            params.current.exchange_cate = localParams.exchange_cate;
            params.current.exchange_year = localParams.exchange_year;
            localStorage.removeItem("inno_param");
        }
        let currPage = 1;
        if(localStorage.getItem("inno_page")){
            currPage = parseInt(localStorage.getItem("inno_page"))
            localStorage.removeItem("inno_page")
        }
        getInnovationList(params.current, currPage)
    }, []);

    function search(index, name, key) {
        searchData[index].value = key;
        switch (name) {
            case "LOCATION": {
                params.current.province_id = key
                break;
            }
            case "CATE": {
                params.current.exchange_cate = key
                break;
            }
            case "YEAR": {
                params.current.exchange_year = key
                break;
            }
            default :
                break;
        }
        getInnovationList(params.current, 1);
    }

    return (
        <div style={{width: '1080px', margin: '0 auto', padding: '24px 0'}}>
            {/* 搜索 */}
            <div style={{marginBottom: '20px'}}>
                {searchData.map((item, index) => {
                    // !! 特殊处理：由于该频道信息长期未更新，所以隐藏年份筛选, 仅从样式上做最小改动，待后续更新再放开
                    return <div className="search-data" key={index} style={item.name === 'YEAR' ? {display: 'none'} : {}}>
                        <div style={{marginRight: '10px', width: '60px'}}> {item.title}:</div>
                        <div style={{width: '1005px', cursor: 'pointer'}}>
                            {item.sublist.map((item0, index0) => {
                                return (
                                    <div
                                        key={index0}
                                        onClick={() => {
                                            search(index, item.name, item0.value);
                                        }}
                                        className={`local-serach-second-item ${
                                            item.value == item0.value
                                                ? "local-serach-light-item"
                                                : "local-serach-second-item"
                                        }`}
                                    >
                                        {item0.label}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                })}
                <div>
                </div>
            </div>
            <div className="comm-head">
                <Breadcrumb style={{marginBottom: '20px'}}>
                    <Breadcrumb.Item href="/">
                        <Space>
                            <LeftOutlined/>
                            返回首页
                        </Space>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item></Breadcrumb.Item>
                </Breadcrumb>
            </div>
            {/* 列表 */}
            {/* 空页面 */}
            {innovationList.length === 0 ? <div style={{textAlign: 'center'}}>
                    <img style={{width: '200px', height: '200px'}} src={Empty} alt=""/>
                    <div style={{color: '#666'}}>暂无数据</div>
                </div> :
                <Spin spinning={loading}>
                    <div>
                        <div className="innovation-list">{
                            innovationList.map((item, index) => {
                                return <div key={index + 'a'} className="innovation-card" onClick={() => {
                                    let goUrl = "/innovationcase/" + item.id;
                                    console.log(params.current)
                                    localStorage.setItem("inno_param", JSON.stringify(params.current));
                                    localStorage.setItem("inno_page", page)
                                    props.history.push(goUrl);
                                }}>
                                    <div hidden={item.img_url ? false : true}>
                                        <img className="innovation-img" src={item.img_url} alt=""/>
                                    </div>
                                    <div style={{overflow: "hidden"}}>
                                        <div className="innovation-title">{item.title}</div>
                                        <div className="innovation-content">{item.abstract}</div>
                                    </div>

                                </div>
                            })
                        }</div>
                        {totalCount > 0 && (
                            <div>
                                <Pagination
                                    current={page}
                                    total={totalCount}
                                    onChange={(e) => {
                                        getInnovationList(params.current, e)
                                    }}
                                    pageSize={10}
                                />
                            </div>
                        )}
                    </div>
                </Spin>
            }
        </div>
    );
}

export default Index;
