import React, {useEffect} from "react";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {Breadcrumb, Space, Row, Col} from "antd";
import ServiceModel from "../../models/service";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/service.css";

import {websiteList} from "./data";

function Index(props) {
    const {info, infoKey, getInfos, searchList} = ServiceModel();
    useEffect(() => {
        let loc = localStorage.getItem("loc");
        let pre = localStorage.getItem("pre");
        if (loc && loc != window.location.href) {
            pre = loc;
        }
        loc = window.location.href;
        localStorage.setItem("pre", pre);
        localStorage.setItem("loc", loc);

        // window.TEA("jkzgxd_pageview", {
        //     url: window.location.href,
        //     title: "便民服务",
        //     url_path: window.location.pathname,
        //     referrer: pre,
        //     referrer_host: window.location.origin,
        // });
    }, []);
    return (
        <div className="service-main comm-main">
            <div className="comm-head">
                <Breadcrumb>
                    <Breadcrumb.Item href="/">
                        <Space>
                            <LeftOutlined/>
                            返回首页
                        </Space>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item></Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="service-content">
                <div className="service-content-gov">
                    <div className="title">名单查询</div>
                    <Row className="content">
                        {searchList.map((item, index) => {
                            return (
                                <Col
                                    key={"sear_" + index}
                                    className="content-item"
                                    onClick={() => {
                                        window.location.href = item.url;
                                    }}
                                >
                                    <span>{item.title}</span>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
                <div className="service-content-gov">
                    <div className="title">信息查询</div>
                    <div className="info-content">
                        <Row className="info-content-head">
                            <Col
                                className={
                                    infoKey == "hospital_organ" ? "title-item cur" : "title-item"
                                }
                                onClick={() => {
                                    getInfos("hospital_organ");
                                }}
                            >
                                医疗机构
                            </Col>
                            <Col
                                className={
                                    infoKey == "medical_staff" ? "title-item cur" : "title-item"
                                }
                                onClick={() => {
                                    getInfos("medical_staff");
                                }}
                            >
                                医卫人员
                            </Col>
                            <Col
                                className={
                                    infoKey == "medical" ? "title-item cur" : "title-item"
                                }
                                onClick={() => {
                                    getInfos("medical");
                                }}
                            >
                                药物
                            </Col>
                            <Col
                                className={infoKey == "food" ? "title-item cur" : "title-item"}
                                onClick={() => {
                                    getInfos("food");
                                }}
                            >
                                食卫标准
                            </Col>
                            <Col
                                className={infoKey == "other" ? "title-item cur" : "title-item"}
                                onClick={() => {
                                    getInfos("other");
                                }}
                            >
                                其他
                            </Col>
                        </Row>
                        <div className="info-content-body">
                            {info.map((item, index) => {
                                return (
                                    <a
                                        style={{color: "#333333"}}
                                        key={"info_" + index}
                                        href={item.url}
                                    >
                                        <div className="info-item">
                                            {item.title} <RightOutlined/>
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
