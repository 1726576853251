/* eslint-disable import/prefer-default-export */
import React,{ memo } from 'react';
import './index.scss';

function TagList1({ data, current, onSelect, options = {}, className }) {
    const { displayAttr = 'name', valueAttr = 'cate' } = options;
    return (
        <div className={`encyclopedia-tags ${className || ''}`}>
            {data?.map((item) => (
                <div
                    className={`${'encyclopedia-tag-item'} ${item[valueAttr] === current ? 'encyclopedia-tag-item-select' : ''}`}
                    key={item[displayAttr]}
                    onClick={() => {
                        onSelect && onSelect(item);
                    }}
                >
                    {item[displayAttr]}
                </div>
            ))}
        </div>
    );
};

export const TagList = memo(TagList1);