export const govInfo = [
  {
    id: '684636',
    name: '国务院关于实施健康中国行动的意见',
  },
  {
    id: '685827',
    name: '健康中国行动推进委员会办公室关于印发推进实施健康中国行动2020年工作计划的通知',
  },
  {
    id: '686593',
    name: '健康中国行动推进委员会办公室关于印发健康中国行动2021年工作要点的通知',
  },
  {
    id: '686594',
    name: '全国爱卫会关于印发全国爱国卫生运动2021年工作要点的通知',
  },
  {
    id: '686572',
    name: '“大考”来了！关乎14亿人的健康',
  },
  {
    id: '686573',
    name: '考核实施方案印发！',
  },
  {
    id: '686553',
    name: '健康中国行动推进委员会办公室关于推介健康中国行动推进典型经验案例的通知',
  },
]

export const localNews = [
  {
    title: '创新案例',
    url: '/innovation',
    list: [
      {
        id: '65',
        name: '健康养老助力全民健康，上海市打造社区老年人“体医融合”中心',
        url: '/innovationcase/65'
      },
      {
        id: '24',
        name: '浙江省发扬爱国卫生运动群防群控优势 全力助推新冠肺炎疫情防控阻击战',
        url: '/innovationcase/24'
      },
      {
        id: '60',
        name: '健康山东，立法先行——全面促进学生体质健康',
        url: '/innovationcase/60'
      },
      {
        id: '40',
        name: '广西多载体、全方面推进合理膳食行动',
        url: '/innovationcase/40'
      },
      {
        id: '62',
        name: '职业健康保护行动之深圳实践案例',
        url: '/innovationcase/62',
      },
      {
        id: '64',
        name: '吉林省长春市积极推进老年健康促进行动',
        url: '/innovationcase/64',
      },
    ]
  },
  {
    title: '地方动态',
    url: '/local',
    list: [
      {
        id: '686823',
        name: '明年北京市将建成30家“健康企业”',
        url: '/article/686823'
      },
      {
        id: '687024',
        name: '2021年河北保定市将重点做好七方面卫生健康工作',
        url: '/article/687024'
      },
      {
        id: '686959',
        name: '山西十举措促中小学生健康成长',
        url: '/article/686959'
      },
      {
        id: '686964',
        name: '上海推动爱国卫生迈向健康共治',
        url: '/article/686964'
      },
      {
        id: '686941',
        name: '2020年，河南城乡居民健康素养水平提升至26.76%',
        url: '/article/686941',
      },
      {
        id: '687070',
        name: '“人人享有高质量的健康服务” 湖南积极推动健康促进行动提质增效',
        url: '/article/687070',
      },
    ]
  }
]

export const videoList = [
  {
    title: '健康中国行动宣传片',
    vid: 'v02b81g10000c3jrgjbc77ue3adhp3pg',
    source: '健康中国行动推进委员会办公室、中央广播电视总台',
    cover: 'https://image1.jkzgxd.cn/tos-cn-v-c81536/27681ea020e44b7f8b4b2cb0491a0633_1625799562~tplv-noop.image'
  },
  {
    title: '我们要赢的，是自己',
    vid: 'v02b81g10000c3jtohjc77u1ia4dkjkg',
    source: '中央广播电视总台、央视网',
    cover: 'https://image1.jkzgxd.cn/tos-cn-v-c81536/b513f31103fe431ebcfd9ac07febbca4~tplv-noop.image',
  },
  {
    title: '为中国人的健康“体检”',
    vid: 'v02b81g10000c3jr3h3c77u1ibbcjbd0',
    source: '中央广播电视总台',
    cover: 'https://image1.jkzgxd.cn/tos-cn-v-c81536/3da664d7e45649f2b2cbd1eaa84da869~tplv-noop.image',
  },
  {
    title: '【健康中国行动——各地行】持续创新健康服务体系 助力健康北京',
    url: 'https://vodpub1.v.news.cn/original/20201021/f5a1f999f7fa4d3d8a371bd94404bbe5.mp4',
    source: '新华网',
    cover: 'https://vodpub1.v.news.cn/original/image/9f0f46c912664c588f386b40dd58017b_videogroup_1920_1080_356.jpg',
  },
  {
    title: '健康浙江行动各地行',
    vid: 'v02b81g10000c3jv33rc77ue4lo9ht0g',
    source: '浙江省委省政府健康浙江建设领导小组办公室',
    cover: 'https://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/6e26bb9cb0f6423183e1e2310e8fedf8~tplv-a3h8qa8x9l-png.png?'
  },
  {
    title: '清新世界',
    vid: 'v02b81g10000c3ju9bbc77u16f8rip00',
    source: '河北省卫生健康委',
    cover: 'https://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/de19b125492c407ea4430b1b3dc83cce~tplv-a3h8qa8x9l-png.png?'
  },
  {
    title: '职业健康say hi hi',
    vid: 'v02b81g10000c3k16rjc77u743qqtihg',
    source: '深圳市卫生健康委员会',
    cover: 'https://image1.jkzgxd.cn/tos-cn-v-c81536/3263ac6c7d634dc9b2ac793c3ecd12d0_1625823378~tplv-noop.image'
  },
]