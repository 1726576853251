import React, { useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Space, Pagination, Result, Button } from "antd";

import ResultModel from "../../models/article/result";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/article/result.css";

function ResultPage(props) {
  const query = props.match.params.query;
  const { list, totalCount, page, search } = ResultModel();

  useEffect(() => {
    search(query);

    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "搜索结果",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);

  return (
    <div className="result-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          {/* 隐藏总条数 */}
          {/* {totalCount > -1 && (
            <Breadcrumb.Item> 搜索结果{totalCount}条</Breadcrumb.Item>
          )} */}
        </Breadcrumb>
      </div>
      <div className="result-content">
        <div className="result-list">
          {list.map((item, index) => {
            return (
              <div key={"res_" + index} className="result-list-item">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                ></div>
                <div className="link">
                  {/* !! 特殊处理知行大赛的结果跳转 */}
                  <span
                    onClick={() => {
                      // 视频类型跳转
                      if (item.content_type === 'video') {
                        props.history.push({
                          pathname: "/expertdetail",
                          state: {
                            id: item.uid,
                          }
                        })
                        return;
                      }
                      window.location.href = item.url;
                    }}
                  >{item.url}</span>
                </div>
                <div
                  className="content"
                  onClick={() => {
                    // 视频类型跳转
                    if (item.content_type === 'video') {
                      props.history.push({
                        pathname: "/expertdetail",
                        state: {
                          id: item.uid,
                        }
                      })
                      return;
                    }
                    window.location.href = item.url;
                  }}
                  dangerouslySetInnerHTML={{ __html: item.summary }}
                ></div>
                {/* <div className="tags">
                  <Space size="large">
                    <a>
                      行动目标 <RightOutlined />
                    </a>
                    <a>
                      行动目标 <RightOutlined />
                    </a>
                    <a>
                      行动目标 <RightOutlined />
                    </a>
                    <a>
                      行动目标 <RightOutlined />
                    </a>
                  </Space>
                </div> */}
                {item.cate == "china_depart" && (
                  <div className="tags">
                    <Space direction="vertical">
                      <div>
                        <Space size="large">
                          <div className="tag-head">牵头重大行动</div>
                          {item.headLinkUrlList &&
                            item.headLinkUrlList.map((hitem, hindex) => {
                              return <div key={"he_" + hindex}>{hitem}</div>;
                            })}
                        </Space>
                      </div>
                      <div>
                        <Space size="large">
                          <div className="tag-head">协同重大行动</div>
                          {item.synergisticLinkUrlList &&
                            item.synergisticLinkUrlList.map((hitem, hindex) => {
                              return <div key={"zd_" + hindex}>{hitem}</div>;
                            })}
                        </Space>
                      </div>
                    </Space>
                  </div>
                )}

                <div
                  className="result-footer"
                  dangerouslySetInnerHTML={{ __html: item.body }}
                ></div>
                <div className="art-line"></div>
              </div>
            );
          })}
          {totalCount == 0 && (
            <Result
              title="当前频道尚无内容"
              extra={
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    props.history.push("/");
                  }}
                >
                  返回首页
                </Button>
              }
            />
          )}
        </div>
        {totalCount > 0 && (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="article-pages"
          >
            <Pagination
              current={page}
              total={totalCount}
              pageSizeOptions={[20]}
              pageSize={20}
              onChange={(e) => {
                search(query, e);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ResultPage;
