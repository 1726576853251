import React, {useEffect, useState} from "react";

import caseModel from "../../models/case"
import {decodeContent} from "../../untils/commHelper";
import {Breadcrumb, Space} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import "../../static/css/pages/article/index.css";

function InnovationDetail(props) {
    const {
        getContent,
        content,
        title,
        getPreview
    } = caseModel()

    useEffect(() => {
        if(/preview/.test(props.location.pathname)){
            let timestamp=window.location.href.split("sign=")[1];
            const articleID=props.match.params.id;
            getPreview(articleID, timestamp).then(res => {
                if(res){
                    const spanList = document.getElementsByClassName("content")[0].getElementsByTagName("span");
                    for(let span of spanList){
                        span.style.fontSize = "16px"
                    }
                }
            });
        }else{
            getContent(props.match.params.id).then(res => {
                if(res){
                    const spanList = document.getElementsByClassName("content")[0].getElementsByTagName("span");
                    for(let span of spanList){
                        span.style.fontSize = "16px"
                    }
                }
            });
        }
    }, [])

    return (
        <div className="article-main comm-main">
            <div className="comm-head">
                <Breadcrumb>
                    <Breadcrumb.Item
                        className="gopre"
                        onClick={() => {
                            window.history.go(-1);
                        }}
                    >
                        <Space>
                            <LeftOutlined/>
                            返回上一页
                        </Space>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="article-content ">
                <div className="title">{title}</div>
                <div
                    className="content content"
                    dangerouslySetInnerHTML={{
                        __html: decodeContent(content),
                    }}
                ></div>
            </div>
        </div>
    );
}

export default InnovationDetail