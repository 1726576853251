import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi, requestSpecial} from "../untils/requestHelper";

function useEntry() {
    const [tagList, setTagList] = useState([]);
    const [entryList, setEntryList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [entryInfo, setEntryInfo] = useState({});
    const [entryLoading, setEntryLoading] = useState(false)

    const getTagList = async () => {
        const res = await reqestApi("/api/lotus/diseinfo/department", "GET");
        const resList = [{
            tag: "全部",
            id: 0
        }];
        const {data} = res.data
        if (res.data.code === 0) {
            for (let key in data) {
                resList.push({
                    id: parseInt(key),
                    tag: data[key]
                })
            }
        }
        setTagList([...resList]);
    }

    const getEntryList = async (params = {}) => {
        setLoading(true);
        const res = await reqestApi("/api/lotus/diseinfo/list", "GET", params);
        setLoading(false)
        if (res.data.code === 0) {
            res.data.data.forEach(item => {
                if (item.dise_info && item.dise_info.summary) {
                    const {summary} = item.dise_info;
                    let abstract = "";
                    summary.forEach(sum => {
                        abstract += sum;
                        abstract += "。"
                    })
                    item.abstract = abstract;
                }
            })
            setEntryList([...res.data.data])
            setTotalCount(res.data.totalCount)
        }
    }

    const getEntryInfo = async (id) => {
        setEntryLoading(true)
        const res = await reqestApi(`/api/lotus/diseinfo/info/${id}`, "GET");
        setEntryLoading(false)
        if(res.data.code === 0){
            setEntryInfo({
                ...res.data.data.dise_info,
                department: res.data.data.department,
                dise_name: res.data.data.dise_name,
            })
        }
    }

    return {
        entryLoading,
        loading,
        totalCount,
        entryList,
        tagList,
        entryInfo,
        getTagList,
        getEntryList,
        getEntryInfo
    }
}

export default createModel(useEntry)