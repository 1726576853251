import React from "react";
import { LeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { Breadcrumb, Space } from "antd";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/action.css";

function Health(props) {
  return (
    <div className="action-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="action-content">
        <div
          className="file-card"
          onClick={() => {
            let goUrl = "/article/684635";
            props.history.push(goUrl);
          }}
        >
          <img
            className="file-card-head"
            src={require("../../static/images/file-01.png")}
          />
          <div className="file-card-content">
            <div className="title">《“健康中国2030”规划纲要》</div>
            <div className="content">
              新华社北京10月25日电
              近日，中共中央、国务院印发了《“健康中国2030”规划纲要》，并发出通知，要求各地区各部门结合实际认真贯彻落实
            </div>
          </div>
          <div className="file-card-footer">
            <span>查看更多</span>
            <DoubleRightOutlined />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Health;
