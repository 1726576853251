import React, { useState } from "react"
import { createModel } from "hox"
import { reqestApi } from "../untils/requestHelper"
import { message } from "antd"

function useService() {
  const searchList = [
    {
      title: "器官移植机构",
      url: "http://www.nhc.gov.cn/wjw/qgyzjg/list.shtml",
    },
    {
      title: "辅助生殖机构",
      url:
        "http://www.nhc.gov.cn/wjw/fzszjg/202004/bddb71b3de8543f292ba5bbd81c6e750.shtml",
    },
    {
      title: "爱婴医院名单",
      url:
        "http://www.nhc.gov.cn/fys/s7906/201511/e5650712dbcd449e9d2e01129a698b9c.shtml",
    },
    {
      title: "信用信息",
      url: "https://credit.jdzx.net.cn/portal/",
    },
    {
      title: "卫生标准",
      url: "http://www.nhc.gov.cn/wjw/wsbzxx/wsbz.shtml",
    },
    {
      title: "基本药物目录",
      url: "http://www.nhc.gov.cn/wjw/jbywml/list.shtml",
    },
    {
      title: "国家卫生城镇",
      url:
        "http://www.nhc.gov.cn/wjw/gjwscz/201903/a2851723c6f64589a09d70d04fc4837b.shtml",
    },
    {
      title: "医院执业登记",
      url: "http://zgcx.nhc.gov.cn:9090/unit/index",
    },
    {
      title: "产前诊断技术医疗机构",
      url: "http://www.nhc.gov.cn/wjw/cqzdyljg/list.shtml",
    },
    {
      title: "卫生健康标准网",
      url: "http://wsbz.nhc.gov.cn/wsbzw/",
    },
    {
      title: "主管报刊和电子期刊",
      url:
        "http://www.nhc.gov.cn/wjw/zgbkqk/201612/93a6cd0ea6944c87867ce78821443ea3.shtml",
    },
  ]

  const infoList = {
    hospital_organ: [
      {
        title: "医院执业登记",
        url: "http://zgcx.nhc.gov.cn:9090/unit/index",
      },
      {
        title: "器官移植机构",
        url: "http://www.nhc.gov.cn/wjw/qgyzjg/list.shtml",
      },
      {
        title: "辅助生殖机构",
        url:
          "http://www.nhc.gov.cn/wjw/fzszjg/202004/bddb71b3de8543f292ba5bbd81c6e750.shtml",
      },
      {
        title: "爱婴医院名单",
        url:
          "http://www.nhc.gov.cn/fys/s7906/201511/e5650712dbcd449e9d2e01129a698b9c.shtml",
      },
      {
        title: "产前诊断技术医疗机构",
        url: "http://www.nhc.gov.cn/wjw/cqzdyljg/list.shtml",
      },
      {
        title: "委业务主管社会组织名录",
        url: "http://www.nhc.gov.cn/wjw/wywzgshzzml/list.shtml",
      },
    ],
    medical_staff: [
      { title: "执业医师", url: "http://zgcx.nhc.gov.cn:9090/doctor" },
      { title: "执业护士", url: "http://zgcx.nhc.gov.cn:9090/nurse" },
    ],
    medical: [
      {
        title: "基本药物目录",
        url: "http://www.nhc.gov.cn/wjw/jbywml/list.shtml",
      },
      { title: "药管平台", url: "http://cdsip.nhc.gov.cn/" },
    ],
    food: [
      { title: "卫生标准", url: "http://www.nhc.gov.cn/wjw/wsbzxx/wsbz.shtml" },
      {
        title: "食品安全国家标准",
        url: "http://www.nhc.gov.cn/sps/spaqgjbz/spaq.shtml",
      },
    ],
    other: [
      { title: "信用信息", url: "https://credit.jdzx.net.cn/portal/" },
      {
        title: "国家卫生城镇",
        url: "http://www.nhc.gov.cn/wjw/gjwscz/list.shtml",
      },
    ],
  }
  const [infoKey, setInfoKey] = useState("hospital_organ")
  const [info, setInfo] = useState(() => {
    let arr = [
      {
        title: "医院执业登记",
        url: "http://zgcx.nhc.gov.cn:9090/unit/index",
      },
      {
        title: "器官移植机构",
        url: "http://www.nhc.gov.cn/wjw/qgyzjg/list.shtml",
      },
      {
        title: "辅助生殖机构",
        url:
          "http://www.nhc.gov.cn/wjw/fzszjg/202004/bddb71b3de8543f292ba5bbd81c6e750.shtml",
      },
      {
        title: "爱婴医院名单",
        url:
          "http://www.nhc.gov.cn/fys/s7906/201511/e5650712dbcd449e9d2e01129a698b9c.shtml",
      },
      {
        title: "产前诊断技术医疗机构",
        url: "http://www.nhc.gov.cn/wjw/cqzdyljg/list.shtml",
      },
      {
        title: "委业务主管社会组织名录",
        url: "http://www.nhc.gov.cn/wjw/wywzgshzzml/list.shtml",
      },
    ]
    return arr
  })

  const getInfos = async (key) => {
    setInfoKey(key)
    setInfo(infoList[key])
  }
  return {
    info,
    infoKey,
    getInfos,
    searchList,
  }
}

export default createModel(useService)
