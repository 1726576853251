import React, { useState, useEffect } from "react";
import "../static/css/components/CommUnit.css";
import "../static/css/pages/comm.css";

import { getGovernmentOrgans } from "./Common/CommonData";
/**协同单位组件 */
const CommUnit = (props) => {
  const { params } = props;
  const [governments, setGovernments] = useState([]);
  useEffect(() => {
    let tmparr = getGovernmentOrgans("fold");
    setGovernments(tmparr);
  }, []);
  return (
    <div className="unit">
      <div className="unit-box">
        <div className="unit-head">
          <div className="comm-title">
            <span
              style={{
                height: "20px",
                width: "4px",
                background: "#108CEE",
                borderRadius: "4px",
              }}
            ></span>
            <span className="comm-title-txt">健康中国行动相关部门</span>
            <span className="comm-title-smtxt">（排名不分先后）</span>
          </div>
        </div>
        <div className="unit-list">
          {governments.map((item, index) => {
            let keyStr = "nm_" + index;
            return (
              <div
                key={keyStr}
                className={
                  item.type == "disabled" ? "unit-item disabled" : "unit-item"
                }
                onClick={() => {
                  if (item.type == "normal") {
                    window.location.href = item.url;
                  } else if (item.type == "btn-fold") {
                    let tmpList = getGovernmentOrgans("fold");
                    setGovernments(tmpList);
                  } else if (item.type == "btn-unfold") {
                    let tmpList = getGovernmentOrgans("unfold");
                    setGovernments(tmpList);
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CommUnit;
