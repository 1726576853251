import React, {useEffect, useRef, useState} from "react";
import {PlusCircleOutlined, PlusOutlined, CheckCircleFilled, CloseCircleFilled, DownloadOutlined} from '@ant-design/icons';
import {Steps, Button, message, Upload} from 'antd';
import html2canvas from "html2canvas"

import "./index.css"
import StepForm from "./component/stepForm";
import fileImg from "../../../static/images/file_download.png"
import {getToken} from "../../../untils/tokenHelper";
import uploadImg from "../../../static/images/uploadImg.png"
import {GetUploadUrl} from "../../../untils/requestHelper";
import modal from "../../../models/infoStep"

const { Step } = Steps;

function InfoStep(props) {
    const steps = [
        {
            title: '录入医院信息',
        },
        {
            title: '下载《申请函》模板',
        },
        {
            title: '上传《申请函》授权扫描件',
        },
    ];

    const [current, setCurrent] = useState(0);
    const [info, setInfo] = useState(() => {
        return props.location.state ? JSON.parse(props.location.state.info) : {
            id: 0,
            hospital_name: "",
            hospital_rank: "",
            province: "",
            qualification: "",
            core_subject_num: "",
            departments: "",
            capability: "",
            hospital_info: "",
            hospital_addr: "",
            logo: "",
            qrcode_img: "",
            commit_name: "",
            company_name: "",
            position: "",
            phone_num: sessionStorage.getItem("phone_num")
        }
    })
    const [date] = useState(() => {
        const date = new Date();
        let nowMonth = date.getMonth() + 1;
        let strDate = date.getDate();
        const separator = "-";
        if (nowMonth >= 1 && nowMonth <= 9) {
            nowMonth = "0" + nowMonth;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        return  date.getFullYear() + separator + nowMonth + separator + strDate;
    })
    const [certUrl, setCertUrl] = useState("");
    const [certList, setCertList] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [removeVisible, setRemoveVisible] = useState(false);
    const [resVisible, setResVisible] = useState(false);
    const [count, setCount] = useState(3);

    const timer = useRef()

    const {
        commit
    } = modal()

    function convertBase64UrlToBlob(base64) {
        var parts = base64.split(';base64,');
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; i++) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    }

    function myBrowser() {
        const userAgent = navigator.userAgent; //获得浏览器的userAgent字符串
        if (userAgent.indexOf("OPR") > -1) {
            return "Opera";
        }; //推断是不是Opera浏览器 OPR/43.0.2442.991
        if (userAgent.indexOf("Firefox") > -1) {
            return "FF";
        } //推断是不是Firefox浏览器  Firefox/51.0
        if (userAgent.indexOf("Trident") > -1) {
            return "IE";
        } //推断是不是IE浏览器  Trident/7.0; rv:11.0
        if (userAgent.indexOf("Edge") > -1) {
            return "Edge";
        } //推断是不是Edge浏览器  Edge/14.14393
        if (userAgent.indexOf("Chrome") > -1) {
            return "Chrome";
        } // Chrome/56.0.2924.87
        if (userAgent.indexOf("Safari") > -1) {
            return "Safari";
        } //推断是不是Safari浏览器 AppleWebKit/534.57.2 Version/5.1.7 Safari/534.57.2
    }

    function takeScreenshot() {
        const node = document.getElementById('modal')
        console.log(node.offsetHeight, node.offsetWidth)
        html2canvas(node, {
            useCORS: true,
            height: node.offsetHeight,
            width: node.offsetWidth,
            scrollY: 0,
            scrollX: 0
        }).then(async (canvas) => {
            const src = canvas.toDataURL();
            const blob = convertBase64UrlToBlob(src);
            // 下载
            if (myBrowser() == 'IE') {
                window.navigator.msSaveBlob(blob, `${info.hospital_name}入驻申请11函`);
            } else {
                const a = document.createElement('a');
                a.download = `${info.hospital_name}入驻申请函`;
                a.href = URL.createObjectURL(blob);
                a.style.display = 'none'
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        })
    }

    return (
        <div className="info-step-container">
            <Steps current={current} labelPlacement={"vertical"}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div className="content">
                {current === 0 && <StepForm info={info} setInfo={(info) => {setInfo(info)}} setCurrent={(step) => {setCurrent(step)}}/>}
                {current === 1 && <div className="download-container">
                    <div className="circle" onClick={() => {
                        takeScreenshot()
                    }}>
                        <img className="small-img" src={fileImg} alt=""/>
                        <div className="click-download"><DownloadOutlined />点此下载</div>
                    </div>
                    <p className="copy-text">下载医院入驻《申请函》模版</p>
                    <div className="button-container">
                        <Button className="pre-step-btn" onClick={() => {
                            setCurrent(0)
                        }}>上一步</Button>
                        <Button className="next-step-btn" onClick={() => {
                            setCurrent(2);
                        }}>下一步</Button>
                    </div>
                    <div id="modal" className="modal-container">
                        <div className="content">
                            <div className="title">申请函</div>
                            <div className="row">健康中国行动专题网站:</div>
                            <div className="row">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 兹证明我院下述信息真实有效，现申请加入健康中国行动互联网医</div>
                            <div className="row">院平台项目，请予以批准!</div>
                            <div className="row">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;申请医院信息如下:</div>
                            <div className="table-container">
                                <table border={1}>
                                    <tr>
                                        <td>医院名称</td>
                                        <td>{info.hospital_name}</td>
                                    </tr>
                                    <tr>
                                        <td>医院等级</td>
                                        <td>{info.rank_text}</td>
                                    </tr>
                                    <tr>
                                        <td>所在地区</td>
                                        <td>{info.province_text}</td>
                                    </tr>
                                    <tr>
                                        <td>资质</td>
                                        <td>{info.qualification_text}</td>
                                    </tr>
                                    <tr>
                                        <td>申请人姓名</td>
                                        <td>{info.commit_name}</td>
                                    </tr>
                                    <tr>
                                        <td>申请人单位名称</td>
                                        <td>{info.company_name}</td>
                                    </tr>
                                    <tr>
                                        <td>申请人职务</td>
                                        <td>{info.position}</td>
                                    </tr>
                                    <tr>
                                        <td>申请人手机号</td>
                                        <td>{info.phone_num}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="row-down">申请单位：{info.hospital_name}</div>
                            <div className="row-down">申请日期：{date}</div>
                            <div className="row-down stamp">盖章确认：</div>
                        </div>
                    </div>
                </div>}
                {current === 2 && <div className="download-container">
                    <div 
                        className="circle" 
                        style={{
                            borderColor: certUrl ? "rgb(237, 245, 253)" : (uploadLoading ? "#108CEE" : "#FBFBFC")
                        }} 
                        onMouseEnter={() => {
                            if(certList.length > 0) {
                                setRemoveVisible(true);
                            }
                        }} 
                        onMouseLeave={() => {
                            if(removeVisible){
                                setRemoveVisible(false)
                            }
                        }}
                    >
                        {!certUrl ? <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            fileList={certList}
                            headers={{token: getToken()}}
                            action={GetUploadUrl()}
                            onChange={(e) => {
                                if (e.file?.status === 'uploading') {
                                    setUploadLoading(true);
                                    setCertList([...e.fileList])
                                    return;
                                }
                                if (e.file?.status === 'done') {
                                    try {
                                        setUploadLoading(true);
                                        let ret = e.fileList[0].response;
                                        if (ret.code === 0) {
                                            setUploadLoading(false)
                                            setCertList(e.fileList)
                                            setCertUrl(ret.data);
                                        } else {
                                            setUploadLoading(false)
                                            message.error("图片上传失败");
                                        }
                                    } catch (ex) {
                                        message.error("图片上传失败")
                                    }
                                    setUploadLoading(false);
                                }
                            }}
                            onRemove={(e) => {
                                setCertUrl("");
                                setCertList([])
                            }}
                        >
                            {!uploadLoading && <div>
                                <img className="upload-img" src={uploadImg} alt=""/>
                                <div className="upload-text">点此上传</div>
                            </div>}
                        </Upload> : <img src={fileImg} alt=""/>}
                        {
                            certList.length > 0 &&
                            !uploadLoading &&
                            <div className="upload-hover" style={{
                                display: removeVisible ? "block" : "none"
                            }} onClick={() => {
                                setCertList([]);
                                setCertUrl("")
                            }}>
                                <div className="remove"><CloseCircleFilled />删除</div>
                            </div>
                        }
                    </div>
                    {uploadLoading && <div>
                        <div className="upload-text">文件上传中...</div>
                    </div>}
                    {certUrl && !uploadLoading && <div className="upload-success">
                        <CheckCircleFilled />上传成功！
                    </div>}
                    <div className="copy-text">
                        上传互联网医院入驻《申请函》授权扫描件 扫描件格式jpg/png，文件不大于2M
                    </div>
                    <div className="button-container">
                        <Button className="pre-step-btn" onClick={() => {
                            setCurrent(1)
                        }}>上一步</Button>
                        <Button className="next-step-btn" onClick={async () => {
                            if(!certList.length){
                                message.error("请上传《申请函》！");
                                return;
                            }
                            if(await commit(info.id, {certificate_img: certUrl})){
                                setResVisible(true);
                                timer.current = setInterval(() => {
                                    setCount(count => {
                                        if(count === 1){
                                            clearInterval(timer.current);
                                            props.history.push("/internethospital")
                                        }
                                        return count - 1
                                    })
                                }, 1000)
                            }
                        }}>提交</Button>
                    </div>
                    <div className="result-container" style={{
                        display: resVisible ? "block" : "none"
                    }}>
                        <div className="res-content"><CheckCircleFilled />提交成功!（{count}s）</div>
                    </div>
                </div>}
            </div>
            <div className="footer">欢迎加入平台！&nbsp;&nbsp; 反馈邮箱13810002617@139.com &nbsp;&nbsp;&nbsp;&nbsp;技术提供方：小荷健康</div>
        </div>
    )
}

export default InfoStep