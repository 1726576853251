import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../untils/requestHelper";
import {message} from "antd";
import {getProvinces} from "../untils/citydata";

function useLocal() {
    const [innovationList, setinnovationList] = useState(() => {
        return []
    });
    const [page, setPage] = useState(1)
    const [totalCount, settotal] = useState(0);
    const [loading, setLoading] = useState(false)
    const [searchData, setSearchData] = useState(() => {
        return [
            {
                title: "地\xa0\xa0\xa0\xa0\xa0\xa0\xa0区",
                name: "LOCATION",
                value: 0,
                sublist: [{label: "全国", value: 0},...getProvinces()],
            },
            {
                title: "案例分类",
                name: "CATE",
                value: 0,
                sublist: [
                    {label: "全部", value: 0},
                    {label: "十五项行动", value: 2},
                    {label: "综合", value: 3}
                ]
            },
            {
                title: "年\xa0\xa0\xa0\xa0\xa0\xa0\xa0度",
                name: "YEAR",
                value: 0,
                sublist: [
                    {label: "全部", value: 0},
                    {label: new Date().getFullYear(), value: new Date().getFullYear()},
                    {label: new Date().getFullYear() - 1, value: new Date().getFullYear() - 1},
                ]
            }
        ];
    });
    // const [hopitalDetail, setHospitalDeatil] = useState(() => {
    //     return {
    //         hospital_name: '',
    //         hospital_info: '',
    //         hospital_addr: [],
    //         capability: []
    //     }
    // })
    // const hospital_rank = {
    //     1: '其他',
    //     2: '三甲医院',
    //     3: '三级医院',
    //     4: '二级医院'
    // }
    const getInnovationList = async (params, page) => {
        console.log('params', params)
        params.page = page;
        setLoading(true);
        let res = await reqestApi("/api/exchangeplat/list", "GET", params);
        setLoading(false)
        if (res.data && res.data.code === 0) {
            settotal(res.data.totalCount);
            setPage(page);
            setinnovationList(res.data.data);
        } else {
            setinnovationList([]);
        }
    };

    return {
        searchData,
        setSearchData,
        innovationList,
        setinnovationList,
        getInnovationList,
        totalCount,
        page,
        loading
    };
}

export default createModel(useLocal);
