import React, { useEffect } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Space } from "antd";

import "../../static/css/pages/comm.css";
import "../../static/css/pages/system.css";

function About() {
  useEffect(() => {
    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "关于本网",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);
  return (
    <div className="system-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="sys-content">
        <div className="sys-content-title">关于本网</div>
        <div className="sys-content-txt">
          为贯彻落实《国务院关于实施健康中国行动的意见（国发〔2019〕13号）》和《健康中国行动（2019—2030年）》相关文件要求，设立健康中国行动网站。该网站由健康中国行动推进委员会办公室主办，健康报社承办。
        </div>
        <div className="sys-content-txt">
          健康中国行动网站以其权威性、开放性、互动性、可及性等优势，可满足政府、社会、公众等不同维度需求，成为实施健康中国行动的重要抓手和载体。以该平台为纽带，借助互联网思维和互联网技术，强化机构合作，整合各方资源，创新服务模式，面向全人群提供个性化的健康资讯与健康管理服务，激发公众热爱健康、追求健康的热情，践行自主自律健康行为。同时，通过云计算、大数据、物联网等先进技术手段，推动健康服务供给侧结构性改革，优化健康服务与保障，调动全社会的积极性和创造性，形成政府积极主导、社会广泛参与、个人自主自律的高参与度、高活跃度的良性互动局面。
        </div>
        {/* <div className="sys-content-txt">
          <b>网站主体分为七个部分：</b>
        </div>
        <div>
          <div className="sys-content-txt">
            <b>1.政务公开</b>
          </div>
          <div className="sys-content-txt">
            内容涵盖领导活动信息及简历、重大决策部署、政策文件及解读、机构职责、政府信息公开、通知公告、政策解读、人事任免等信息。
          </div>
        </div>

        <div>
          <div className="sys-content-txt">
            <b>2.地方亮点 </b>
          </div>
          <div className="sys-content-txt">
            对在健康中国行动上的优秀案例和创新举措进行展示，提升相关部门的积极性和工作效率，向社会和公众晒出成绩单和实践亮点，接受社会监督。
          </div>
        </div>

        <div>
          <div className="sys-content-txt">
            <b>3.疾病查询（参照NHS Choices网站中的Health A~Z）</b>
          </div>
          <div className="sys-content-txt">
            通过症状、疾病名称、药品、治疗等关键词进行查询，可以搜索到相关疾病的详细信息。
          </div>
        </div>

        <div>
          <div className="sys-content-txt">
            <b>4.健康生活</b>
          </div>
          <div className="sys-content-txt">
            健康促进和健康教育的内容。针对公众所关心的健康话题和科普内容，专网将在自身内容库不断完善的基础上，与各相关机构积极协同、逐步完善健康领域的权威知识库供公众使用，提升群众健康福祉，体现党和政府的民生责任。
          </div>
        </div>

        <div>
          <div className="sys-content-txt">
            <b> 5.健康新闻</b>
          </div>
          <div className="sys-content-txt">
            这部分信息主要报道有关健康问题的研究进展、文献等，将专业文章“翻译”为公众能够看得懂的科普文章。理想的健康新闻报道文章将不仅仅告诉公众结论，而且对文献的研究者、研究方法、结果等信息也要做出说明，避免公众断章取义或以偏概全。
          </div>
        </div>
        <div>
          <div className="sys-content-txt">
            <b>6.身边的服务</b>
          </div>
          <div className="sys-content-txt">
            通过地址检索及LBS定位，为公众提供符合标准的医疗卫生机构相关信息，以及健康相关的健康管理机构、健身场地、养老场所等信息。其中，提供服务的医疗机构主要包括：医院、社区卫生服务中心、乡镇卫生院、诊所等基层医疗卫生机构和疾病预防控制中心、卫生监督所等专业公共卫生机构，搜索到的每个机构理想情况下都有具体的服务信息，也可以标注在地图上。
          </div>
        </div>
        <div>
          <div className="sys-content-txt">
            <b>7.互动建议</b>
          </div>
          <div className="sys-content-txt">
            主要面向社会征集问题及专网改进建议，对收到的问题线索和意见建议进行汇总整理，必要时反馈其他相关部门。
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default About;
