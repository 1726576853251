import React, { useState } from "react"
import { createModel } from "hox"
import { reqestApi } from "../untils/requestHelper"
import { message } from "antd"

function useQuota() {
  const [quotaList, setQuotaList] = useState([])
  const [numList, setNumList] = useState(() => {
    let tmArr = [
      { name: "全部指标", num: 0, searchType: "all" },
      { name: "结果性指标", num: 0, cate: 1 },
      { name: "个人和社会倡导性指标", num: 0, cate: 2 },
      { name: "政府工作指标", num: 0, cate: 3 },

      { name: "预期性", num: 0, type: 1 },
      { name: "倡导性", num: 0, type: 2 },
      { name: "约束性", num: 0, type: 3 },
    ]
    return tmArr
  })

  const [cate, setCate] = useState(1)
  const [type, setType] = useState(1)
  const getTable = async (page, limit, cateNum, typeNum) => {
    let params = {}
    //params.page = page
    // params.limit = limit
    if (cateNum > 0) {
      params.cate = cateNum
    }

    params.type = typeNum
    setCate(cateNum)
    setType(typeNum)
    let ret = await reqestApi("/api/indicator", "GET", params)
    if (ret.data.code == 0) {
      let list = ret.data.data
      let tmpArr = list.map((item) => {
        let newTmp = {}
        newTmp.key = item.id
        newTmp.obj = {
          indicator_name: item.indicator_name,
          territory: item.territory,
        }
        newTmp.indicator_category = item.indicator_category
        newTmp.indicator_type = item.indicator_type
        newTmp.indicator_2022_value = item.indicator_2022_value
        newTmp.indicator_2030_value = item.indicator_2030_value
        newTmp.base_level = item.base_level
        return newTmp
      })
      setQuotaList(tmpArr)

      setNumList(ret.data.numList)
    }
    console.log("FH:", ret.data.data)
  }

  return {
    cate,
    type,
    quotaList,
    numList,
    getTable,
  }
}

export default createModel(useQuota)
