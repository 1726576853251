import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../untils/requestHelper";
import {message} from "antd";

function useStep() {
    const save = async (params, id) => {
        const res = await reqestApi(`/api/nethosp/store${id ? "/"+id : ""}`, "POST", params);
        if(res.data && res.data.code === 0){
            message.success("保存成功");
            return res.data.data.id;
        }else{
            if(res.data && res.data.code === 400003){
                message.error("该医院已申请入驻，不能重复申请！")
            } else {
                message.error("提交失败");
            }
            return false;
        }
    }

    const commit = async (id, param) => {
        const res = await reqestApi(`/api/nethosp/commit_cert/${id}`, "POST", param);
        if(res.data && res.data.code === 0){
            // message.success("提交成功");
            return true
        } else {
            message.error("提交失败");
            return false;
        }
    }

    return {
        save,
        commit
    }
}

export default createModel(useStep)