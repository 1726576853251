import React, { useState } from "react"
import { createModel } from "hox"
import { reqestApi } from "../../untils/requestHelper"
import { message } from "antd"
import {useCookies} from "react-cookie";

function useConsult() {
  /**轮播图 */
  const [cookies, setCookie] = useCookies(["consult_page"]);
  const [carousels, setCarousel] = useState([])
  const [carouMode, setCarouMode] = useState({ title: "", id: "" })
  const [abstract, setAbstract] = useState('')
  const [govList, setGovList] = useState([])
  const [carouselsIndex, setCarouselsIndex] = useState(1)//页面展示数字
  const [carouselsText, setCarouselsText] = useState('')//页面展示数字
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(() => {
    let num = cookies.consult_page;
    if (num) {
      return parseInt(num);
    }
    return 1;
  });
  const [pageSize, setPageSize] = useState(() => {
    let size = cookies.consult_pageSize;
    if (size) {
      return parseInt(size);
    }
    return 6;
  });

  const init = async (page) => {
    /**轮播图 */
    let ret = await reqestApi("/api/official_information/carousel", "GET", {channel:'pc'})
    if (ret.data.code == 0) {
      const abstractContent=(ret.data.data[0].abstract)||(ret.data.data[0].content).replace(/<.*?>/g,'')
      setCarousel(ret.data.data)
      setCarouselsText(ret.data.data[0].title)
      setCarouMode(ret.data.data[0])
      setAbstract(abstractContent)
    }
    getGovList(page, pageSize);
  }

  const getGovList = async (page, limit) => {
    let params = {}
    params.page = page
    params.limit = limit
    let govRet = await reqestApi(
      "/api/official_information/list",
      "GET",
      params
    )
    if (govRet.data.code == 0) {
      setPageSize(limit);
      if(Array.isArray(govRet.data.data)){
        const newData = govRet.data.data.map((item) => { item.abstract = (item.abstract) || (item.content).replace(/<.*?>/g, ''); return item; });
        setGovList(newData)
      }
      
      setTotalCount(govRet.data.totalCount)
    }
  }

  const onChangeCarousels = async (index) => {
    const abstractContent=(carousels[index].abstract)||(carousels[index].content).replace(/<.*?>/g,'')
    let mode = carousels[index]
    let text = carousels[index].title
    setCarouselsText(text)
    setCarouselsIndex(index+1)
    setCarouMode(mode)
    setAbstract(abstractContent)
  }

  return {
    carouselsText,
    carouselsIndex,
    page,
    setPage,
    cookies,
    setCookie,
    pageSize,
    carousels,
    carouMode,
    govList,
    totalCount,
    getGovList,
    init,
    onChangeCarousels,
    abstract, // 摘要
  }
}

export default createModel(useConsult)
