import { Env, GetApiHost } from "../conf/app";
import axios from "axios";
import qs from "qs";
import initAxiosInterceptorsConfig from "./interceptors";
import { message } from "antd";
initAxiosInterceptorsConfig()

export const reqestApi = async (url, method, params) => {
  let httpDefault = {
    method: method,
    baseURL: GetApiHost(),
    url: url,
    params: method === "GET" || method === "DELETE" ? params : null,
    data: method === "POST" || method === "PUT" ? qs.stringify(params) : null,
    timeout: 60000,
    withCredentials: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let ret = await axios(httpDefault);

  return ret;
};

export const GetUploadUrl = () => {
  if (Env.isTest) {
    // return "http://manage.healthchina.byted.org/pub/uploadimage";
    return "https://healthchina-doctor-mis-api.xiaohe-jiankang.com/pub/uploadimage"; //"http://10.227.77.173:6789/pub/uploadimage"; //
  }
  return "https://healthchina-doctor-mis-api.xiaohe-jiankang.com/pub/uploadimage"; //"http://10.227.77.173:6789/pub/uploadimage"; //
};
export const requestSpecial = async (url, method, params) => {
  let httpDefault = {
    method: method,
    baseURL: "https://m.xiaohe-jiankang.com",
    url: url,
    params: method === "GET" || method === "DELETE" ? params : null,
    data: method === "POST" || method === "PUT" ? qs.stringify(params) : null,
    timeout: 60000,
    withCredentials: true,
    headers: {
      "content-type": "application/json; charset=utf-8"
    },
  };
  let ret = await axios(httpDefault);

  return ret;
}
