import React, { useState, useEffect, useRef } from "react";
import { LeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { Breadcrumb, Carousel, Space, Pagination, Row, Col } from "antd";
import ConsultMode from "../../models/news/consult";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/consult.css";
function Consult(props) {
  const {
    carouselsText,
    carouselsIndex,
    page,
    setPage,
    pageSize,
    carousels,
    carouMode,
    govList,
    cookies,
    setCookie,
    totalCount,
    getGovList,
    init,
    onChangeCarousels,
    abstract, // 摘要
  } = ConsultMode();
  const listIndexs = [0, 2, 4];
  const [isAutoplay, setIsAutoplay] = useState(true);
  const myRef = useRef()

  useEffect(() => {
    init(page);
    let loc = localStorage.getItem("loc");
    let pre = localStorage.getItem("pre");
    if (loc && loc != window.location.href) {
      pre = loc;
    }
    loc = window.location.href;
    localStorage.setItem("pre", pre);
    localStorage.setItem("loc", loc);

    // window.TEA("jkzgxd_pageview", {
    //   url: window.location.href,
    //   title: "官方咨询",
    //   url_path: window.location.pathname,
    //   referrer: pre,
    //   referrer_host: window.location.origin,
    // });
  }, []);
  const goPre = ()=>{
    myRef.current.prev();
  }
  const goNext = ()=>{
    myRef.current.next();
  }
  return (
    <div className="consult-main comm-main">
      <div className="comm-head">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <Space>
              <LeftOutlined />
              返回首页
            </Space>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="con-content">
        <div className="con-content-banner">
          <div className="go-pre" onClick={()=>goPre()} ></div>
          <div className="go-next" onClick={()=>goNext()} ></div>
          <div className="text" >
            <span className="text-num">{carouselsIndex}/{carousels.length}</span>
            <span className="text-content">{carouselsText}</span>
          </div>
          <Carousel
            ref={myRef}
            afterChange={(e) => {
              onChangeCarousels(e);
            }}
            dots={false}
            autoplay={true}
            className="con-content-banner-pics"
          >
            {carousels.map((item, index) => {
              return (
                <div key={"car_" + index} className="banner-pics-item">
                  <img className="banner-pics-item-bg" src={item.imgUrl} />
                </div>
              );
            })}
          </Carousel>
          <div className="con-content-banner-text">
            <div className="banner-text-head">
              <span className="banner-text-head-title">内容摘要</span>
              <span
                className="banner-text-head-more"
                onClick={() => {
                  let goUrl = "/article/" + carouMode.id;
                  props.history.push(goUrl);
                }}
              >
                更多 <DoubleRightOutlined />
              </span>
            </div>
            <div className="banner-text-content">
              {abstract}
            </div>
          </div>
        </div>
        <div className="con-content-list">
          <Row>
            {govList.map((item, index) => {
              return (
                <Col
                  key={"gov_" + index}
                  className="con-content-list-item"
                  onClick={() => {
                    let goUrl = "/article/" + item.id;
                    props.history.push(goUrl);
                  }}
                >
                  <div className="con-content-list-item-title">
                    {item.title}
                  </div>
                  <div className="con-content-list-item-content">
                  {item.abstract}
                  </div>
                  {/* <div className="con-content-list-item-footer">
                  <span>李医生</span>
                  <span>北京医科大附属医院</span>
                </div> */}
                </Col>
              );
            })}
          </Row>
        </div>
        {totalCount > 0 && (
          <div className="consult-pages">
            <Pagination
              current={page}
              total={totalCount}
              onChange={(e, pageSize) => {
                getGovList(e, pageSize);
                setPage(e);
                setCookie("consult_page", e.toString(), { maxAge: 600 });
                setCookie("consult_pageSize", pageSize.toString(), { maxAge: 600 });
              }}
              pageSize={pageSize}
              pageSizeOptions={[6, 10, 20]}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Consult;
