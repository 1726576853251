import logo1 from "../../static/images/all_room.png"
import logo2 from "../../static/images/Internal_Medicine.png"
import logo3 from "../../static/images/surgical.png"
import logo4 from "../../static/images/dermatology.png"
import logo5 from "../../static/images/obstetric.png"

export const departments = [
    {
        logo: logo1,
        title: "全部科室",
        id: 0,
        row:[
            {
                label:"肝癌",
                id: "822",
            },
            {
                label:"甲状腺癌",
                id: "1568",
            },
            {
                label:"结直肠癌",
                id: "70000000002975",
            },
            {
                label:"肺癌",
                id: "1103",
            },
            {
                label:"脑梗死",
                id: "5413",
            },
        ]
    },
    {
        logo: logo2,
        title: "内科",
        id: 1,
        row:[
            {
                label:"获得性免疫缺陷综合征",
                id: "70000000002945",
            },
            {
                label:"梅毒",
                id: "5789",
            },
            {
                label:"脑卒中",
                id: "5474",
            },
            {
                label:"痛风",
                id: "1048",
            },
        ]
    },
    {
        logo: logo3,
        title: "外科",
        id: 2,
        row:[
            {
                label:"痔",
                id: "1147",
            },
            {
                label:"阑尾炎",
                id: "7420557701889",
            },
            {
                label:"肛瘘",
                id: "21866499770113",
            },
            {
                label:"肛周脓肿",
                id: "1154",
            },
        ]
    },
    {
        logo: logo4,
        title: "皮肤科",
        id: 165,
        row:[
            {
                label:"胆碱能性荨麻疹",
                id: "3409",
            },
            {
                label:"带状疱疹",
                id: "3295",
            },
            {
                label:"玫瑰糠疹",
                id: "6894",
            },
            {
                label:"白癜风",
                id: "1517",
            },
        ]
    },
    {
        logo: logo5,
        title: "妇产科",
        id: 3,
        row:[
            {
                label:"阴道炎",
                id: "3191",
            },
            {
                label:"盆腔炎",
                id: "15697305023489",
            },
            {
                label:"宫颈息肉",
                id: "1674",
            },
            {
                label:"异位妊娠",
                id: "1020",
            },
        ]
    }
]