import React, { useState, useEffect, useRef } from "react";
import {Breadcrumb, Pagination, Space, Spin} from "antd";
import {LeftOutlined} from "@ant-design/icons";


import "./list.scss"
import ListModal from "../../../models/entry"

function List(props) {
    const PAGESIZE = 20;
    const {
        loading,
        totalCount,
        entryList,
        tagList,
        getTagList,
        getEntryList
    } = ListModal()

    const [currId, setCurrId] = useState(0);
    const [page, setPage] = useState(1);

    const domEvents = {
        changeTag: (id) => {
            setCurrId(id);
            setPage(1);
            getList(1, id);
        },
        toDetail: (id) => {
            localStorage.setItem("dep_page", page.toString());
            localStorage.setItem("dep_case", currId.toString());
            // props.history.push(`/entrydetail/${id}`);
            window.open(`/entrydetail/${id}`, '_blank');
        }
    }

    useEffect(() => {
        getTagList();
    }, [])

    useEffect(() => {
        if(tagList.length){
            if(props.location.state && props.location.state.id){
                domEvents.changeTag(props.location.state.id)
            }else if(localStorage.getItem("dep_page")){
                const id = parseInt(localStorage.getItem("dep_case"));
                const page = parseInt(localStorage.getItem("dep_page"));
                setCurrId(id);
                setPage(page);
                getList(page, id)
            }else{
                getList(page, currId);
            }
            localStorage.removeItem("dep_case");
            localStorage.removeItem("dep_page");
        }
    },[tagList])

    function getList(page, id) {
        const params = {
            page
        };
        if(id){
            params.department_id = id;
        }
        getEntryList(params)
    }

    return (
        <div className="entry-container">
            {tagList.length > 0 && <div className="department-container">
                <span className="tag">按科室</span>
                <div className="tags-container">
                    {
                        tagList.map((item, index) => {
                            return (
                                <div key={index} className={`tag-item ${item.id === currId ? "active-tag" : ""}`} onClick={() => {domEvents.changeTag(item.id)}}>
                                    {item.tag}
                                </div>
                            )
                        })
                    }
                </div>
            </div>}
            {tagList.length > 0 && <Breadcrumb style={{
                marginBottom: 15
            }}>
                <Breadcrumb.Item href="/encyclopedia">
                    <Space>
                        <LeftOutlined/>
                        返回上一页
                    </Space>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Space>
                        全部词条
                    </Space>
                </Breadcrumb.Item>
            </Breadcrumb>}
            <Spin spinning={loading}>
                <div className="entries">
                    {
                        entryList.map((entry, index) => {
                            return (
                                <div key={index} className="entry-info" onClick={() => {
                                    domEvents.toDetail(entry.uid)
                                }}>
                                    <div className="title">{entry.dise_name}</div>
                                    <div className="department-info">就诊科室：{entry.department.split(",").join('、')}</div>
                                    <div className="abstract">{entry.abstract}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </Spin>
            <div>
                {totalCount > PAGESIZE && <Pagination
                    current={page}
                    total={totalCount}
                    onChange={(page) => {
                        setPage(page);
                        getList(page, currId);
                    }}
                    defaultPageSize={PAGESIZE}
                    pageSizeOptions={[20]}
                />}
            </div>
        </div>
    )
}

export default List